import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Carousel, Col, Image, Row, Typography } from "antd";
import {
  Neighborhoods1,
  Neighborhoods2,
  Neighborhoods3,
  Neighborhoods4,
  Neighborhoods5,
  Neighborhoods6,
  Neighborhoods7,
} from "../../assets/images";
import { SearchRoutesConstant } from "../../common/router/components/SearchRoutes";
import "./NeighborHoods.style.scss";

const { Title } = Typography;

const useNeighborhoodData = () => {
  return useMemo(
    () => [
      {
        name: "New York",
        image: Neighborhoods1,
      },
      {
        name: "Washington DC",
        image: Neighborhoods2,
      },
      {
        name: "Boston",
        image: Neighborhoods5,
      },
      {
        name: "San Francisco",
        image: Neighborhoods3,
      },
      {
        name: "Philadelphia",
        image: Neighborhoods4,
      },
      {
        name: "Chicago",
        image: Neighborhoods6,
      },
      {
        name: "Atlanta",
        image: Neighborhoods7,
      },
    ],
    []
  );
};

const NeighborHoods: FC = () => {
  const neighborhoodData = useNeighborhoodData();

  return (
    <div className="neighbor-hoods">
      <div className="content-wrapper">
        <Carousel dots={false} effect="fade" autoplay autoplaySpeed={3000}>
          {neighborhoodData.map((item, i) => (
            <div key={`image_${i}`} className="image-wrapper">
              <Image src={item.image} alt={item.name} preview={false} />
              <Link
                to={`${SearchRoutesConstant.Search.Space.path}?location=${item.name}`}
                className="city-name"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </Carousel>
        <div className="content">
          <Container>
            <Row className="w-100">
              <Col xs={24} className="text-center">
                <Title level={1} className="title">
                  Neighborhoods
                </Title>
              </Col>
              {neighborhoodData.map((item, i) => (
                <Col xs={24} md={8} lg={6} key={`cities_${i}`}>
                  <Link
                    to={`${SearchRoutesConstant.Search.Space.path}?location=${item.name}`}
                    className="cities"
                  >
                    {item.name}
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default NeighborHoods;
