import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const HomeAwayHome: FC = () => {
  return (
    <>
      <Helmet>
        <title>A Home Away From Home</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                A Home Away From Home
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Whether you are on a long vacation with your family or moving
                into a different part of the country for a short term, your
                accommodation options can restrict your freedom and the
                flexibility especially if you opt to go to a hotel. Unless you
                are willing to spend a lot of money in a large luxury suite of a
                hotel, you will not be able to get much out of your stay and
                continue with your usual work. If you have toddlers or someone
                who needs extra help with you, it becomes even more difficult to
                be confined to a small room in a hotel or a motel. This is when
                the renting a house for a short term becomes far more
                beneficial. This way you can get a full sized home with all the
                facilities and you have the freedom to go about your work and
                make sure your children are having a home away from experience
                as well.
              </Paragraph>
              <Paragraph>
                However, there are a few things which are essential that you
                know, especially for a first time short time renter of a house.
                First of all, renting a house – even for short term – can be a
                bit pricey. You probably have to put a small down payment and
                also pay your current and other bills during your stay. If you
                are ready for those things financially, it is time for you to
                inspect a house. If you are not familiar with the real estate
                prices in the particular area you are going for the short term
                rental, do not hesitate to use the internet or the help of your
                realtor to make sure you are not paying an unnecessary price as
                well.
              </Paragraph>
              <Paragraph>
                Make sure you are clear with the house owner on what essential
                electrical items they offer free of charge. Usually they include
                an electric kettle, an oven, washing machine, refrigerator and
                sometimes a dishwasher as well. On your first visit to the house
                before you pay the down payment, make sure you keep your eyes
                open for any bad conditions. Try the electrical switches to see
                if they are working, look closely on the window sills to see if
                they are properly cleaned, flush the toilets to see if they are
                working without any problem etc. If they are not providing all
                the furniture you need during your stay, the smartest thing to
                do is to try and borrow them from family and friends since your
                stay there is temporary.
              </Paragraph>
              <Paragraph>
                Before you make the payments for rent house short term, always
                make sure both you and the home owner are clear on the
                specifics. Let them know beforehand if you have infants,
                toddlers, pets, etc. since some homeowners may have specific
                rules regarding them. If you need any particular service from
                the home owner, do not hesitate to communicate as well. Finally,
                be responsible during your stay in the house, making sure you
                keep the property clean and hand over in the similar state that
                you got it.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default HomeAwayHome;
