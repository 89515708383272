export const currencyData = [
  { symbol: "$", currency: "USD" },
  { symbol: "£", currency: "GBP" },
  { symbol: "€", currency: "EUR" },
  { symbol: "$", currency: "AUD" },
  { symbol: "$", currency: "SGD" },
  { symbol: "k", currency: "SEK" },
  { symbol: "k", currency: "DKK" },
  { symbol: "$", currency: "MXN" },
  { symbol: "R$", currency: "BRL" },
  { symbol: "RM", currency: "MYR" },
  { symbol: "P", currency: "PHP" },
  { symbol: "€", currency: "CHF" },
  { symbol: "₹", currency: "INR" },
  { symbol: "$", currency: "ARS" },
  { symbol: "$", currency: "CAD" },
  { symbol: "¥", currency: "CNY" },
  { symbol: "Kč", currency: "CZK" },
  { symbol: "$", currency: "HKD" },
  { symbol: "Ft", currency: "HUF" },
  { symbol: "Rp", currency: "IDR" },
  { symbol: "₪", currency: "ILS" },
  { symbol: "¥", currency: "JPY" },
  { symbol: "₩", currency: "KRW" },
  { symbol: "kr", currency: "NOK" },
  { symbol: "$", currency: "NZD" },
  { symbol: "zł", currency: "PLN" },
  { symbol: "p", currency: "RUB" },
  { symbol: "฿", currency: "THB" },
  { symbol: "₺", currency: "TRY" },
  { symbol: "$", currency: "TWD" },
  { symbol: "₫", currency: "VND" },
  { symbol: "R", currency: "ZAR" },
];
