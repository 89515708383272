// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .welcome-msg-box .ant-card-body {
  background-color: #fcf49c;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/dashboard/DashBoard.style.scss","webpack://./src/assets/styles/_colors.scss"],"names":[],"mappings":"AAII;EACE,yBCFG;ADDT","sourcesContent":["@import \"../../../assets/styles/hourlyspaces-theme\";\r\n\r\n.dashboard {\r\n  .welcome-msg-box {\r\n    .ant-card-body {\r\n      background-color: $yellow;\r\n    }\r\n  }\r\n}\r\n","$red: #eb3c44;\r\n$green: #49bd59;\r\n$orange: #ffb400;\r\n$yellow: #fcf49c;\r\n\r\n$pink-3: #ff99a1;\r\n$pink-2: #ff5a5f;\r\n$pink-1: #f3626a;\r\n\r\n$white-2: #f9f9f9;\r\n$white-1: #ffffff;\r\n\r\n$bg-dark-white: #f0f0f0;\r\n$bg-light-white-2: rgba(255, 255, 255, 0.3);\r\n$bg-light-white-1: rgba(255, 255, 255, 0.7);\r\n$bg-light-white: rgba(255, 255, 255, 0.92);\r\n\r\n$gray-10: #e4e4e4;\r\n$gray-9: #bfc2c4;\r\n$gray-8: #bfc2c4;\r\n$gray-7: #bbbbbb;\r\n$gray-6: #979797;\r\n$gray-5: #939495;\r\n$gray-4: #565a5c;\r\n$gray-3: #434d51;\r\n$gray-2: #393c3d;\r\n$gray-1: #333333;\r\n\r\n$bg-gray: rgba(60, 63, 64, 0.898);\r\n\r\n$bg-gray-shadow-5: rgba(0, 0, 0, 0.1);\r\n$bg-gray-shadow-4: rgba(0, 0, 0, 0.2);\r\n$bg-gray-shadow-3: rgba(0, 0, 0, 0.3);\r\n$bg-gray-shadow-2: rgba(0, 0, 0, 0.5);\r\n$bg-gray-shadow-1: rgba(0, 0, 0, 0.6);\r\n\r\n$red-shadow: rgba(235, 60, 68, 0.1);\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
