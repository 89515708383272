import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Divider,
  Form,
  Image,
  Modal,
  Rate,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CheckOutlined,
  ExclamationOutlined,
  HeartFilled,
  LikeOutlined,
} from "@ant-design/icons";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  FormatDisplayDate,
  FormatDisplayTime,
} from "../../common/utilities/format";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";
import { useAuth } from "../../common/components/authContext/AuthContext";
import CardLayout from "../../common/components/card-layout/CardLayout";
import "./SpaceDetails.style.scss";

const { Title, Paragraph, Text } = Typography;

const SpaceDetails: FC = () => {
  const { state } = useLocation();

  useEffect(() => {
    //console.log("getting Space id", state);
  }, [state]);

  return (
    <CardLayout containerClassName="space-details">
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={20} xl={21}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <Title level={1}>
                Daily Deluxe Large Executive Office Close to Logan Airport
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph className="mb-0">
                Personal Office - Private Room
                <Text className="mb-0">{" · "}</Text>
                <Text className="mb-0">
                  Chelsea, Massachusetts, United States
                </Text>
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={4} xl={3}>
          <Row gutter={[8, 8]}>
            <Col xs={8} lg={24}>
              <Row gutter={[12, 12]}>
                <Col xs={12} className="text-center">
                  <Badge count={8824} overflowCount={999}>
                    <Avatar
                      shape="circle"
                      size="large"
                      children={<LikeOutlined />}
                    />
                    <div className="mt-1">Views</div>
                  </Badge>
                </Col>
                <Col xs={12} className="text-center">
                  <Badge count={0} overflowCount={999}>
                    <Avatar
                      shape="circle"
                      size="large"
                      children={<LikeOutlined />}
                    />
                    <div className="mt-1">Reviews</div>
                  </Badge>
                </Col>
              </Row>
            </Col>
            <Col xs={24} className="text-end text-lg-center">
              <Button type="link" className="link">
                Report as spam
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="mt-3">
        <Col xs={24} lg={16}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <SpaceView />
            </Col>
            <Col xs={24}>
              <SpacesContent />
            </Col>
            <Col xs={24}>
              <SpacesReputations />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <SpacesPricing />
            </Col>
            <Col xs={24}>
              <SpaceWishList />
            </Col>
            <Col xs={24}>
              <SpaceOwnerInfo />
            </Col>
            <Col xs={24}>
              <SpaceListing />
            </Col>
          </Row>
        </Col>
      </Row>
    </CardLayout>
  );
};

const SpaceView: FC = () => {
  const [activeTab, setActiveTab] = useState<string>("photos");

  const tabList = [
    {
      key: "photos",
      label: "Photos",
      destroyInactiveTabPane: true,
    },
    {
      key: "videos",
      label: "Videos",
      destroyInactiveTabPane: true,
    },
    {
      key: "maps",
      label: "Maps",
    },
    {
      key: "calendar",
      label: "Calendar",
      destroyInactiveTabPane: true,
    },
  ];

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  const spaceViewContentList: Record<string, React.ReactNode> = {
    photos: <Photos />,
    videos: <Videos />,
    maps: <Maps />,
    calendar: <Calendars />,
  };

  return (
    <CardLayout
      cardProps={{
        tabList: tabList,
        activeTabKey: activeTab,
        onTabChange: onTabChange,
      }}
      containerClassName="space-view p-0"
    >
      {spaceViewContentList[activeTab]}
    </CardLayout>
  );
};

const Photos: FC = () => {
  const images = [
    {
      original:
        "https://images.pexels.com/photos/245240/pexels-photo-245240.jpeg?cs=srgb&dl=pexels-atbo-66986-245240.jpg&fm=jpg",
      thumbnail:
        "https://images.pexels.com/photos/245240/pexels-photo-245240.jpeg?cs=srgb&dl=pexels-atbo-66986-245240.jpg&fm=jpg",
    },
    {
      original: "https://picsum.photos/id/1010/1000/600/",
      thumbnail: "https://picsum.photos/id/1010/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];
  return (
    <ImageGallery
      items={images}
      showFullscreenButton={false}
      showPlayButton={false}
    />
  );
};

const Videos: FC = () => {
  return (
    <iframe
      title="Spaces video"
      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
      src={`https://youtube.com/embed/o-dMpmmQ6oI?autoplay=0`}
      height={500}
      width={"100%"}
    />
  );
};

const Maps: FC = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, // Replace with your API key
  });

  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isLoaded && mapRef.current && window.google) {
      const mapOptions = {
        zoom: 13, // Adjust zoom level for better visibility of circles
        center: new google.maps.LatLng(40.753685, -73.999161), // Centered on first location
        mapTypeControl: false,
        streetViewControl: false,
      };

      const map = new google.maps.Map(mapRef.current, mapOptions);
      // Create a circle for each location
      const circle = new google.maps.Circle({
        strokeColor: "#ff99a1",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#ff99a1",
        fillOpacity: 0.35,
        map: map,
        center: new google.maps.LatLng(40.753685, -73.999161),
        radius: 3000,
      });
    }
  }, [isLoaded]);

  return isLoaded ? (
    <div ref={mapRef} className="space-map"></div>
  ) : (
    <div>Loading map...</div>
  );
};

const Calendars: FC = () => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin]}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      initialView="dayGridMonth"
      buttonText={{ today: "Today", month: "Month", week: "Week", day: "Day" }}
    ></FullCalendar>
  );
};

const SpacesContent: FC = () => {
  const [activeTab, setActiveTab] = useState<string>("description");

  const tabList = [
    {
      key: "description",
      label: " Description ",
      destroyInactiveTabPane: true,
    },
    {
      key: "amenities",
      label: " Amenities ",
      destroyInactiveTabPane: true,
    },
    {
      key: "houseRules",
      label: "House Rules",
      destroyInactiveTabPane: true,
    },
  ];

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  const spaceViewContentList: Record<string, React.ReactNode> = {
    description: <Description />,
    amenities: <Amenities />,
    houseRules: <HouseRules />,
  };

  return (
    <CardLayout
      cardProps={{
        tabList: tabList,
        activeTabKey: activeTab,
        onTabChange: onTabChange,
      }}
      containerClassName="p-0"
    >
      {spaceViewContentList[activeTab]}
    </CardLayout>
  );
};

const Description: FC = () => {
  return (
    <Row>
      <Col xs={24} lg={12}>
        <Paragraph>Available at a daily rate of $50!</Paragraph>
        <Paragraph>
          Our deluxe executive office is complete with all amenities. Open the
          door to your smartly-styled furnished office – just plug in your
          computer and go! Perfect location right near Downtown Boston and Logan
          Airport!
        </Paragraph>
        <Text> Services Include:</Text>
        <ul>
          <li>24-hour office access</li>
          <li>24-hour voice mail access with auto attendant</li>
          <li> Building lobby signage</li>
          <li>Furnished and decorated Reception Area</li>
          <li>Use of kitchen/beverage & snack services</li>
          <li>Use of Conference room</li>
          <li>Utilities</li>
          <li>Daily cleaning of offices</li>
          <li>Plenty of FREE parking</li>
          <li>Digital VOIP telephone with two phone lines*</li>
          <li>Local and long distance telephone service*</li>
          <li>High speed cable internet connection, including secure WiFi</li>
          <li>Copy Print Scan and Fax center</li>
          <li>Mail/Postage services</li>
        </ul>
        <Paragraph>*small additional fees may apply</Paragraph>
        <Paragraph>
          Chelsea is a city in Suffolk County, Massachusetts, directly across
          from the city of Boston.
        </Paragraph>
      </Col>
      <Col xs={0} lg={1} className="text-center">
        <Divider type="vertical" className="h-100" />
      </Col>
      <Col xs={24} lg={11}>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Space Type:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>Private Room</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>Furniture Type:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>Office Furniture</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Accommodates:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>1</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>Rooms:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>1</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Guests:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>1</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>Bathrooms:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>1</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Cleaning Fee:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>€0</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>Security Fee:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>€0</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Weekly Price:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>€227.31</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>Monthly Price:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>€1363.8</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>City:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>Chelsea</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>State:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>Massachusetts</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Country:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>United States</Text>
          </Col>
        </Row>
        <Row justify="space-between" className="p-2">
          <Col xs={12}>
            <Text>Address:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Text strong>Chelsea, Massachusetts United States-02150</Text>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="p-2 bg-light">
          <Col xs={12}>
            <Text>Cancellation:</Text>
          </Col>
          <Col xs={12} className="text-end">
            <Link
              to={PageRoutesConstant.Page.CancellationPolicies.path.replace(
                ":cancellation",
                "moderate"
              )}
              target="_blank"
              className="link"
            >
              Moderate
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Amenities: FC = () => {
  return (
    <Row>
      <Col xs={24} lg={12}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Smoking Allowed</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Cable TV</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Wireless Internet</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Air Conditioning</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Elevator in Building</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Handicap Accessible</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Pool</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Kitchen</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Print/Scan/Copy</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>IP Video Conference</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Video Conference</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Phone Conference</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>LCD Projector</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Big Screen TV</Text>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={12}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Parking Included (additional cost may apply)</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Washer / Dryer</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Doorman/Receptionist</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Fun room</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Hot Tub</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Business Furniture</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<CheckOutlined />}
                className="bg-success"
              />
              <Text>Conference Room Furniture</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="center">
              <Avatar
                size={16}
                icon={<ExclamationOutlined />}
                className="bg-danger"
              />
              <Text>Beverages (Additional costs may apply)</Text>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const HouseRules: FC = () => {
  return <div>This host has not specified any house rules.</div>;
};

const SpacesReputations: FC = () => {
  const [activeTab, setActiveTab] = useState<string>("review");

  const tabList = [
    {
      key: "review",
      label: "Review ",
      destroyInactiveTabPane: true,
    },
    {
      key: "comments",
      label: "Comments ",
      destroyInactiveTabPane: true,
    },
    {
      key: "friends",
      label: "Friends",
      destroyInactiveTabPane: true,
    },
  ];

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  const spaceViewContentList: Record<string, React.ReactNode> = {
    review: <Review />,
    comments: <Comments />,
    friends: <Friends />,
  };

  return (
    <CardLayout
      cardProps={{
        tabList: tabList,
        activeTabKey: activeTab,
        onTabChange: onTabChange,
      }}
      containerClassName="p-0"
    >
      {spaceViewContentList[activeTab]}
    </CardLayout>
  );
};

const Review: FC = () => {
  const ReviewData = [
    {
      profileImg:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCCLiyqsvcD1oXZq7IaSzwMTaU6j31ND-yCTyxVsd2HHGLCLm7GrIj-56I7u9G9ht5cLA&usqp=CAU",
      userName: "Globex Enterprises",
      comment:
        "This space exceeded all of my expectations. From the moment I arrived, I was impressed by how clean and well-maintained everything was. The host was incredibly accommodating, making sure I had everything I needed for my stay. The location was perfect, with easy access to local amenities and transportation. The space itself was beautifully decorated, offering a comfortable and inviting atmosphere. I particularly appreciated the attention to detail in the amenities provided, such as high-quality linens, a fully stocked kitchen, and fast, reliable Wi-Fi. I was able to work remotely without any issues and still enjoy all the comforts of home. I will definitely be returning and highly recommend this space to anyone looking for a premium experience.",
      rate: 5,
      date: "October, 2021",
    },
    {
      profileImg:
        "https://t4.ftcdn.net/jpg/06/45/77/79/360_F_645777959_fNnaNoeVO4qxCNPW9MWr3gQlPFSGA9yL.jpg",
      userName: "Omega Solutions",
      comment:
        "Fantastic experience! The space was clean, well-organized, and exactly as described. Highly recommend!",
      rate: 2,
      date: "November, 2016",
    },
    {
      profileImg:
        "https://media.istockphoto.com/id/1437816897/photo/business-woman-manager-or-human-resources-portrait-for-career-success-company-we-are-hiring.jpg?s=612x612&w=0&k=20&c=tyLvtzutRh22j9GqSGI33Z4HpIwv9vL_MZw_xOE19NQ=",
      userName: "Alpha Partners",
      comment:
        "Currently interning at NGIN. Love the staff, love the area and love the office at self. After getting to know the community as a whole, I come to work 5 days a week with a smile on my face. Both Olga and Joan (higher ups) doing an excellent job at accommodating new clients, as well as making the current clients feel cared for too. Events, events & events. They happen if not every week, but every other week. No one is ever left out. Love this place. I'm even trying to promote it to my university so that new alums can rent an office space here.",
      rate: 4,
      date: "March, 2012",
    },
  ];

  return (
    <Row>
      <Col xs={24} className="rating-content">
        <Row>
          <Col xs={24} md={24} lg={24}>
            <Row justify="space-between">
              <Col>
                <Text strong>Overall Guest Satisfaction</Text>
              </Col>
              <Col>
                <Rate disabled defaultValue={5} />
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[{ xs: 0, md: 24 }, 0]}>
              <Col xs={24} md={12} lg={12}>
                <Row justify="space-between">
                  <Col>
                    <Text strong className="text-uppercase">
                      Accuracy
                    </Text>
                  </Col>
                  <Col>
                    <Rate disabled defaultValue={5} />
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong className="text-uppercase">
                      Cleanliness
                    </Text>
                  </Col>
                  <Col>
                    <Rate disabled defaultValue={5} />
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong className="text-uppercase">
                      Checkin
                    </Text>
                  </Col>
                  <Col>
                    <Rate disabled defaultValue={5} />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Row justify="space-between">
                  <Col>
                    <Text strong className="text-uppercase">
                      Communication
                    </Text>
                  </Col>
                  <Col>
                    <Rate disabled defaultValue={5} />
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong className="text-uppercase">
                      Location
                    </Text>
                  </Col>
                  <Col>
                    <Rate disabled defaultValue={5} />
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong className="text-uppercase">
                      Value
                    </Text>
                  </Col>
                  <Col>
                    <Rate disabled defaultValue={5} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Divider />
      <Col xs={24} className="comment-content">
        <Row gutter={[12, 12]}>
          {ReviewData.map((item, i) => (
            <Col xs={24} key={`review_list_${i}`}>
              <Row gutter={[12, 8]}>
                <Col xs={24}>
                  <div className="d-flex align-items-start">
                    <div className="image-content">
                      <Avatar
                        shape="square"
                        size={50}
                        icon={<Image src={item.profileImg} preview={false} />}
                      />
                    </div>
                    <div className="w-100 mx-2">
                      <Row gutter={[12, 2]}>
                        <Col xs={24}>
                          <Row justify="space-between" wrap>
                            <Col>
                              <Title level={5} className="mb-0 text-red">
                                {item.userName}
                              </Title>
                            </Col>
                            <Col>
                              <Text className="text-red">{item.date}</Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Rate disabled defaultValue={item.rate} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col xs={24}>
                  <Paragraph className="mb-0 me-2">{item.comment}</Paragraph>
                </Col>
              </Row>
              <Divider />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const Comments: FC = () => {
  return <div>No comments found.</div>;
};

const Friends: FC = () => {
  return <div>No friends found.</div>;
};

const SpacesPricing: FC = () => {
  const [selectedPeriodPrice, setSelectedPeriodPrice] =
    useState<string>("per_night");
  const [form] = Form.useForm();

  const guestsOptions = useMemo(() => {
    return Array.from({ length: 16 }, (_, i) => i + 1).map((guest) => ({
      value: guest,
      label: guest < 16 ? guest : "16+",
    }));
  }, []);

  const onFinish = (values: any) => {
    values = {
      ...values,
      checkin: FormatDisplayDate(values.checkin),
      checkout: FormatDisplayDate(values.checkout),
      hours: FormatDisplayTime(values.hours),
      hours1: FormatDisplayTime(values.hours1),
    };
    console.log(values);
  };

  const handleChange = (event: any) => {
    setSelectedPeriodPrice(event);
  };

  const PeriodPrice = [
    { key: "per_night", value: 45.46 },
    { key: "per_hour", value: 45.46 },
    { key: "per_week", value: 227.31 },
    { key: "per_month", value: 1363.88 },
  ];
  const currentPriceObject = PeriodPrice.find(
    (item) => item.key === selectedPeriodPrice
  );

  return (
    <CardLayout containerClassName="p-0">
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Row>
              <Col xs={12}>
                <Text strong>From</Text>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="payment_period"
                  className="mb-0 w-100"
                  initialValue={selectedPeriodPrice}
                >
                  <Select
                    onChange={handleChange}
                    options={[
                      { value: "per_night", label: "Per Day" },
                      { value: "per_hour", label: "Per Hour" },
                      { value: "per_week", label: "Per Week" },
                      { value: "per_month", label: "Per Month" },
                    ]}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Title level={1} className="mb-0">
                  € {currentPriceObject?.value}
                </Title>
              </Col>
            </Row>
            <Divider type="horizontal" className="my-1" />
          </Col>
          <Col xs={24}>
            <Row gutter={[8, 0]}>
              <Col xs={24} className="mb-2">
                <Text strong>Check in</Text>
              </Col>
              <Col xs={14}>
                <Form.Item
                  name="checkin"
                  className="mb-0"
                  rules={[{ required: true, message: "Check-in date" }]}
                >
                  <DatePicker
                    placeholder="mm/dd/yy"
                    size="large"
                    className="w-100"
                    format="MM/DD/YYYY"
                  />
                </Form.Item>
              </Col>
              <Col xs={10}>
                <Form.Item
                  name="hours"
                  className="mb-0"
                  rules={[{ required: true, message: "Check-in time" }]}
                >
                  <TimePicker
                    format="hh:mm A"
                    use12Hours
                    size="large"
                    minuteStep={30}
                    className="w-100"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[8, 0]}>
              <Col xs={24} className="mb-2">
                <Text strong>Check out</Text>
              </Col>
              <Col xs={14}>
                <Form.Item
                  name="checkout"
                  className="mb-0"
                  rules={[{ required: true, message: "Check-out date" }]}
                >
                  <DatePicker
                    placeholder="mm/dd/yy"
                    size="large"
                    className="w-100"
                    format="MM/DD/YYYY"
                    disabledDate={(current) =>
                      current.isBefore(form.getFieldValue("checkin"), "day")
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={10}>
                <Form.Item
                  name="hours1"
                  className="mb-0"
                  rules={[{ required: true, message: "Check-out time" }]}
                >
                  <TimePicker
                    format="hh:mm A"
                    use12Hours
                    size="large"
                    minuteStep={30}
                    className="w-100"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={24} className="mb-2">
                <Text strong>Guests</Text>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="number_of_guests"
                  className="mb-0"
                  rules={[
                    { required: true, message: "Please Select Guest(s)" },
                  ]}
                >
                  <Select
                    placeholder={"Select Guest(s)"}
                    size="large"
                    options={guestsOptions}
                    suffixIcon={<i className="bi bi-people-fill"></i>}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-100 my-1 text-uppercase"
            >
              Book it!
            </Button>
          </Col>
        </Row>
      </Form>
    </CardLayout>
  );
};

const SpaceWishList: FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  return (
    <CardLayout
      cardProps={{ className: "wish-list" }}
      title={
        <Button
          type="default"
          size="large"
          icon={<HeartFilled />}
          className="text-uppercase w-100"
          onClick={() => {
            if (isAuthenticated) {
              navigate("/");
            } else {
              navigate(PageRoutesConstant.Page.SignIn.path);
            }
          }}
        >
          Add to wish list
        </Button>
      }
      containerClassName="p-0"
    >
      <Paragraph className="save-time mb-0">Saved 0 times</Paragraph>
    </CardLayout>
  );
};

const SpaceOwnerInfo: FC = () => {
  const [isShowMore, setIsShowMore] = useState<string | string[]>([]);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label:
        isShowMore.length > 0 ? (
          <span>
            Show Less <CaretDownOutlined />
          </span>
        ) : (
          <span>
            Show More <CaretRightOutlined />
          </span>
        ),
      children: (
        <Row gutter={[12, 12]}>
          <Col xs={24}>
            <Space split=":">
              <Text>First Name</Text>
              <Text>Tracey</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space split=":">
              <Text>Last Name</Text>
              <Text>ODonnell</Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space split=":">
              <Text>Working in</Text>
              <Text></Text>
            </Space>
          </Col>
          <Col xs={24}>
            <Space split=":">
              <Text>About Me</Text>
              <Text></Text>
            </Space>
          </Col>
        </Row>
      ),
      showArrow: false,
    },
  ];

  const onCollapse = (key: string | string[]) => {
    setIsShowMore(key);
  };

  const sendContactMessage = () => {
    if (isAuthenticated) {
    } else {
      setLoginModal(!loginModal);
    }
  };

  return (
    <CardLayout
      cardProps={{ className: "owner-info" }}
      containerClassName="p-0"
    >
      <Row gutter={[8, 8]}>
        <Col xs={24} className="text-center">
          <Image
            src="https://www.hourlyspaces.com/images/users/280/userpic_profile.jpg"
            alt="image not found"
            preview={false}
            className="profile-image"
          />
        </Col>
        <Col xs={24} className="text-center">
          <Button type="link" size="large" className="link">
            Admirals_Hill_Offices
          </Button>
        </Col>
        <Col xs={24}>
          <Button
            type="primary"
            size="large"
            className="text-uppercase w-100"
            onClick={sendContactMessage}
          >
            Contact me
          </Button>
        </Col>
        <Col xs={24}>
          <Collapse
            size="small"
            accordion
            items={items}
            onChange={onCollapse}
          />
          {loginModal && (
            <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} />
          )}
        </Col>
      </Row>
    </CardLayout>
  );
};

type loginModalProps = {
  loginModal: boolean;
  setLoginModal(value: boolean): void;
};
const LoginModal: FC<loginModalProps> = ({ loginModal, setLoginModal }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={loginModal}
      onCancel={() => setLoginModal(!loginModal)}
      width={1000}
      footer={false}
    >
      <Row>
        <Col xs={24}>
          <Title level={2} className="mb-0 text-center">
            Sign up to send your message
          </Title>
        </Col>
        <Divider />
        <Col xs={24} className="text-center">
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title
                level={4}
                onClick={() => navigate(PageRoutesConstant.Page.SignIn.path)}
                className="link d-inline"
              >
                Already an member?
              </Title>
            </Col>
            <Col xs={24}>
              <Title level={4} className="mb-0">or</Title>
            </Col>
            <Col xs={24}>
              <Title
                level={4}
                onClick={() => navigate(PageRoutesConstant.Page.SignUp.path)}
                className="link d-inline"
              >
                Create an account with your mail address
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

const SpaceListing: FC = () => {
  const spaceList = [
    {
      id: "1",
      image:
        "https://www.hourlyspaces.com/images/619/fcc6658d763b040eb73dd383796fa1ff.jpg",
      distance: "2.08 Miles",
      title: "Daily Private Workstation Close to Logan Airport",
      price: "€618.29/day",
      type: "Private Room",
    },
    {
      id: "2",
      image:
        "https://www.hourlyspaces.com/images/624/04a33fac9317ff7b33cc07d8c67a67d0.jpg",
      distance: "0 Miles",
      title: "Hourly Large Conference Room near Logan Airport",
      price: "€272.78/day ",
      type: "Entire Space",
    },
    {
      id: "3",
      image:
        "https://www.hourlyspaces.com/images/620/af3f8972e5662220c5c0e77c5a5210b4.jpg",
      distance: "2.83 Miles",
      title: "Hourly Large Meeting Space for 8",
      price: "€232.45/day",
      type: "Private Room",
    },
    {
      id: "4",
      image:
        "https://www.hourlyspaces.com/images/623/ec3d03ddd16af6552654547fad5d16aa.jpg",
      distance: "8.03 Miles",
      title: "Daily Private Workstation Close to Logan Airport",
      price: "€32.24/day",
      type: "Private Room",
    },
    {
      id: "5",
      image:
        "https://www.hourlyspaces.com/images/619/fcc6658d763b040eb73dd383796fa1ff.jpg",
      distance: "2.08 Miles",
      title: "Daily Private Workstation Close to Logan Airport",
      price: "€618.29/day",
      type: "Private Room",
    },
    {
      id: "6",
      image:
        "https://www.hourlyspaces.com/images/624/04a33fac9317ff7b33cc07d8c67a67d0.jpg",
      distance: "0 Miles",
      title: "Hourly Large Conference Room near Logan Airport",
      price: "€272.78/day ",
      type: "Entire Space",
    },
    {
      id: "7",
      image:
        "https://www.hourlyspaces.com/images/620/af3f8972e5662220c5c0e77c5a5210b4.jpg",
      distance: "2.83 Miles",
      title: "Hourly Large Meeting Space for 8",
      price: "€232.45/day",
      type: "Private Room",
    },
    {
      id: "8",
      image:
        "https://www.hourlyspaces.com/images/623/ec3d03ddd16af6552654547fad5d16aa.jpg",
      distance: "8.03 Miles",
      title: "Daily Private Workstation Close to Logan Airport",
      price: "€32.24/day",
      type: "Private Room",
    },
  ];

  return (
    <CardLayout
      title="Similar Listings"
      cardProps={{ className: "similar-list" }}
      containerClassName="p-0"
    >
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <Title level={5} className="mb-2 text-center">
            18 Listings
          </Title>
          <Divider className="my-2" />
        </Col>
        <Col className="list-wrapper">
          <Row>
            {spaceList.map((item, i) => (
              <Col xs={24} key={`list_item_${i}`}>
                <Row>
                  <Col xs={24}>
                    <Space align="start">
                      <Image
                        src={item.image}
                        alt={item.title}
                        preview={false}
                        className="list-image"
                      />
                      <div>
                        <Paragraph className="mb-0">{item.distance}</Paragraph>
                        <Paragraph className="mb-0">{item.price}</Paragraph>
                        <Paragraph className="mb-0">{item.type}</Paragraph>
                      </div>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Link
                      to={PageRoutesConstant.Page.SpaceDetails.path}
                      state={{ spaceId: item.id }}
                      className="link"
                    >
                      <Title level={5} ellipsis className="link mb-0">
                        {item.title}
                      </Title>
                    </Link>
                  </Col>
                </Row>
                <Divider className="my-2" />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default SpaceDetails;
