import { FC } from "react";
import { useTranslation } from "react-i18next";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { Col, Row, Typography } from "antd";
import "./HourlyConferenceRooms.style.scss";

const { Paragraph, Title } = Typography;

const HourlyConferenceRooms: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyConferenceRooms")} titleTextColor="red">
      <Row className="hourly-conference-room">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            Fully equipped professional conference rooms for rent by the hour
          </Title>
          <Paragraph>
            When you consider the overall planning of any organized office
            space, the conference room is given the best and often the largest
            location of the whole property. This is because conference rooms are
            where all the most important decisions are made, ideas are shared,
            and meetings are happening. Due to the importance of all the
            activities that happen within a conference room, it is essential
            that you design it in a way that is comfortable, not cluttered and
            easy on the eye, especially since you should expect groups of people
            to gather in the room for an extended period of times.
          </Paragraph>
          <Paragraph>
            One of the most important features that you should focus in a
            conference room is making sure its a place where you can have a
            decently long meeting without any interruptions. For this reason, it
            is good if you can keep your conference room away from the common
            offices and the rest of the hassle in a crowded working area. You
            can notify the rest of the employees about an upcoming meeting so
            they know not to intrude the conference room while a meeting is
            underway.
          </Paragraph>
          <Paragraph>
            With the arrival of high tech communication devices, conference
            rooms were upgraded to include devices such as video conferencing
            tools and high quality audio and video systems. While these are not
            entirely essential, especially if you are a small business or a
            startup, a good system of communication tool does add an extra value
            to your meetings. However, if you cannot still afford a fancy
            conference room in the first few stages of your business, you can
            always hire a fully equipped conference room for an extremely low
            price, compared to what you have to spend if you are to build one
            from scratch. The places that rent out these conference rooms are
            made for commercial purposes and therefore, the tools they use are a
            much better quality than what you may have had if you built it on
            your own.
          </Paragraph>
          <Paragraph>
            Finally, a good conference room demands good maintenance. You should
            check if all your devices are functioning well at least once a
            month, because if issues occur during a meeting, it is going to cost
            you more than money, but also the reputation of your company. A
            conference room must be simple, yet well equipped, less cluttered
            and tastefully decorated and not too fancy, but offers comfort and
            enough space for the mind to wander and come up with good ideas
            during the meetings. If you wish to rent a conference room for a
            short period of time, go to a provider that gives you enough options
            and high quality.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyConferenceRooms;
