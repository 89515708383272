import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Col, Divider, Image, Row, Typography } from "antd";
import CardLayout from "../../../common/components/card-layout/CardLayout";
import { useAuth } from "../../../common/components/authContext/AuthContext";
import { ProfileRoutesConstant } from "../../../common/router/components/UserRoutes";
import { ListingRoutesConstant } from "../../../common/router/components/ListingRoute";
import { MessageRoutesConstant } from "../../../common/router/components/MessageRoute";
import "./DashBoard.style.scss";

const { Title, Paragraph } = Typography;

const DashBoard: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Row gutter={[12, 12]} className="dashboard">
        <Col xs={24} md={8} lg={6} className="upload-content">
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <CardLayout containerClassName="p-0">
                <Row gutter={[12, 0]}>
                  <Col xs={24} className="text-center">
                    <Image
                      src="https://www.hourlyspaces.com/images/no_avatar-xlarge.jpg"
                      alt="image"
                      preview={false}
                      className="image-box"
                    />
                  </Col>
                  <Col xs={24} className="text-center">
                    <Title level={3} className="mt-2">
                      {user?.sub}
                    </Title>
                  </Col>
                  <Col xs={24} className="text-center">
                    <Link
                      to={ProfileRoutesConstant.Users.Profile.path.replace(
                        ":name",
                        user?.sub
                      )}
                      className="link"
                    >
                      View Profile
                    </Link>
                  </Col>
                  <Col xs={24} className="text-center ">
                    <Link
                      to={ProfileRoutesConstant.Users.EditProfile.path}
                      className="link"
                    >
                      Edit Profile
                    </Link>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout title="Quick Links" containerClassName="p-0">
                <Row>
                  <Col xs={24}>
                    <Link
                      to={ListingRoutesConstant.Listing.Listing.path}
                      className="link"
                    >
                      View/Edit Listings
                    </Link>
                  </Col>
                  <Col xs={24}>
                    <Link
                      to={
                        ListingRoutesConstant.Listing.IncomingReservations.path
                      }
                      className="link"
                    >
                      Reservations
                    </Link>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={16} lg={18}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <CardLayout
                containerClassName="p-0"
                cardProps={{ className: "welcome-msg-box" }}
              >
                <Title level={4}>Welcome to Hourly Spaces on Demand!</Title>
                <Paragraph className="mb-0">
                  This is your Dashboard, the place to manage your rental.
                  Update all your personal information from here..
                </Paragraph>
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout title="Messages (0 new)" containerClassName="p-0">
                <Row>
                  <Col xs={24}>
                    <Paragraph>Nothing to show you.</Paragraph>
                  </Col>
                  <Divider />
                  <Col xs={24} className="text-end">
                    <Button
                      type="primary"
                      size="large"
                      className="text-uppercase"
                      onClick={() =>
                        navigate(MessageRoutesConstant.Message.Inbox.path)
                      }
                    >
                      Go to all message
                    </Button>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default DashBoard;
