import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import SearchLayout from "../../components/search-layout/SearchLayout";
import SearchSpace from "../../../pages/search/SearchSpace";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Search: RouteKey;
}

const SearchBaseRoutesConstant: IRoute = {
  Search: {
    Base: {
      path: "/search",
    },
  },
};

export const SearchRoutesConstant: IRoute = {
  Search: {
    Space: {
      path: SearchBaseRoutesConstant.Search.Base.path,
      element: <SearchSpace />,
    },
  },
};

const SearchRoutes = () => {
  return (
    <Routes>
      {Object.keys(SearchRoutesConstant.Search).map((key) => {
        const route = SearchRoutesConstant.Search[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              SearchBaseRoutesConstant.Search.Base.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const SearchFlowRoutes: FC = () => {
  return (
    <SearchLayout>
      <SearchRoutes />
    </SearchLayout>
  );
};

export default SearchFlowRoutes;
