import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Col,
  Divider,
  Row,
  Typography,
  Image,
  Upload,
  Button,
  UploadProps,
  message,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../../../common/router/components/PageRoutes";
import {
  alphabetsPatternRegex,
  phonePatternRegex,
} from "../../../../common/utilities/validator";
import "./EditProfile.style.scss";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const EditProfile: FC = () => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [emergencyContact, setEmergencyContact] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs.utc());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const props: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
  };

  const timezones = useMemo(
    () => [
      { value: "UM12", label: "(UTC -12:00) Baker/Howland Island" },
      { value: "UM11", label: "(UTC -11:00) Samoa Time Zone, Niue" },
      {
        value: "UM10",
        label:
          "(UTC -10:00) Hawaii-Aleutian Standard Time, Cook Islands, Tahiti",
      },
      { value: "UM95", label: "(UTC -9:30) Marquesas Islands" },
      {
        value: "UM9",
        label: "(UTC -9:00) Alaska Standard Time, Gambier Islands",
      },
      {
        value: "UM8",
        label: "(UTC -8:00) Pacific Standard Time, Clipperton Island",
      },
      { value: "UM7", label: "(UTC -7:00) Mountain Standard Time" },
      { value: "UM6", label: "(UTC -6:00) Central Standard Time" },
      {
        value: "UM5",
        label:
          "(UTC -5:00) Eastern Standard Time, Western Caribbean Standard Time",
      },
      { value: "UM45", label: "(UTC -4:30) Venezuelan Standard Time" },
      {
        value: "UM4",
        label:
          "(UTC -4:00) Atlantic Standard Time, Eastern Caribbean Standard Time",
      },
      { value: "UM35", label: "(UTC -3:30) Newfoundland Standard Time" },
      {
        value: "UM3",
        label: "(UTC -3:00) Argentina, Brazil, French Guiana, Uruguay",
      },
      {
        value: "UM2",
        label: "(UTC -2:00) South Georgia/South Sandwich Islands",
      },
      { value: "UM1", label: "(UTC -1:00) Azores, Cape Verde Islands" },
      {
        value: "UTC",
        label: "(UTC) Greenwich Mean Time, Western European Time",
      },
      {
        value: "UP1",
        label: "(UTC +1:00) Central European Time, West Africa Time",
      },
      {
        value: "UP2",
        label:
          "(UTC +2:00) Central Africa Time, Eastern European Time, Kaliningrad Time",
      },
      { value: "UP3", label: "(UTC +3:00) Moscow Time, East Africa Time" },
      { value: "UP35", label: "(UTC +3:30) Iran Standard Time" },
      {
        value: "UP4",
        label: "(UTC +4:00) Azerbaijan Standard Time, Samara Time",
      },
      { value: "UP45", label: "(UTC +4:30) Afghanistan" },
      {
        value: "UP5",
        label: "(UTC +5:00) Pakistan Standard Time, Yekaterinburg Time",
      },
      {
        value: "UP55",
        label: "(UTC +5:30) Indian Standard Time, Sri Lanka Time",
      },
      { value: "UP575", label: "(UTC +5:45) Nepal Time" },
      {
        value: "UP6",
        label: "(UTC +6:00) Bangladesh Standard Time, Bhutan Time, Omsk Time",
      },
      { value: "UP65", label: "(UTC +6:30) Cocos Islands, Myanmar" },
      {
        value: "UP7",
        label:
          "(UTC +7:00) Krasnoyarsk Time, Cambodia, Laos, Thailand, Vietnam",
      },
      {
        value: "UP8",
        label:
          "(UTC +8:00) Australian Western Standard Time, Beijing Time, Irkutsk Time",
      },
      {
        value: "UP875",
        label: "(UTC +8:45) Australian Central Western Standard Time",
      },
      {
        value: "UP9",
        label:
          "(UTC +9:00) Japan Standard Time, Korea Standard Time, Yakutsk Time",
      },
      { value: "UP95", label: "(UTC +9:30) Australian Central Standard Time" },
      {
        value: "UP10",
        label:
          "(UTC +10:00) Australian Eastern Standard Time, Vladivostok Time",
      },
      { value: "UP105", label: "(UTC +10:30) Lord Howe Island" },
      {
        value: "UP11",
        label: "(UTC +11:00) Magadan Time, Solomon Islands, Vanuatu",
      },
      { value: "UP115", label: "(UTC +11:30) Norfolk Island" },
      {
        value: "UP12",
        label:
          "(UTC +12:00) Fiji, Gilbert Islands, Kamchatka Time, New Zealand Standard Time",
      },
      { value: "UP1275", label: "(UTC +12:45) Chatham Islands Standard Time" },
      { value: "UP13", label: "(UTC +13:00) Phoenix Islands Time, Tonga" },
      { value: "UP14", label: "(UTC +14:00) Line Islands" },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>Edit your Profile</title>
      </Helmet>
      <CardLayout
        title={
          <Row justify="space-between">
            <Col>
              <Title level={4} className="mb-0">
                About You
              </Title>
            </Col>
            <Col className="text-start text-lg-center">
              <Title level={4} className="mb-0">
                {currentTime.format("MMMM D, YYYY HH:mm:ss")}
              </Title>
            </Col>
          </Row>
        }
        containerClassName="edit-profile p-0"
      >
        <Row gutter={[0, 24]}>
          <Col xs={24} md={8} lg={6} className="upload-content">
            <Row gutter={[24, 12]}>
              <Col xs={24} className="content-title py-1">
                <Title level={4} className="text-center mb-0">
                  Upload photo
                </Title>
              </Col>
              <Col xs={24} className="text-center">
                <Image
                  src="https://www.hourlyspaces.com/images/no_avatar-xlarge.jpg"
                  alt="image"
                  preview={false}
                  className="image-box"
                />
              </Col>
              <Col xs={24} className="text-center mt-3">
                <Upload {...props}>
                  <Button
                    type="primary"
                    size="large"
                    className="text-uppercase"
                  >
                    Upload Photo
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={2} className="text-center">
            <Divider type="vertical" className="h-100" />
          </Col>
          <Col xs={24} md={14} lg={16}>
            <Form form={form} layout="horizontal" onFinish={onFinish}>
              <Row gutter={[24, 24]}>
                <Col xs={24} className="content-title py-1">
                  <Title level={4} className="mb-0">
                    Required
                  </Title>
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col xs={24} md={6}>
                      <Text>First Name:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="Fname"
                        rules={[
                          {
                            required: true,
                            message: t("firstName.requireMessage"),
                          },
                          {
                            pattern: alphabetsPatternRegex,
                            message: t("firstName.patternMessage"),
                            validateTrigger: ["onBlur", "onClick"],
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("firstName.placeholder")}
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Last Name:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="Lname"
                        rules={[
                          {
                            required: true,
                            message: t("lastName.requireMessage"),
                          },
                          {
                            pattern: alphabetsPatternRegex,
                            message: t("lastName.patternMessage"),
                            validateTrigger: ["onBlur", "onClick"],
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("lastName.placeholder")}
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>I am:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select your gender",
                          },
                        ]}
                      >
                        <Select size="large" placeholder="Select your gender">
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="other">Other</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Birth Date:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="dob"
                        rules={[
                          {
                            required: true,
                            message: "Please select your birth date",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="Select birth date"
                          format="YYYY-MM-DD"
                          size="large"
                          className="w-100"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Email:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("email.requireMessage"),
                          },
                          {
                            type: "email",
                            message: t("email.validMessage"),
                            validateTrigger: ["onBlur", "onClick"],
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("email.placeholder")}
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Phone Number:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number field is required",
                          },
                          {
                            pattern: phonePatternRegex,
                            message: "Please enter valid phone number",
                            validateTrigger: ["onBlur", "onClick"],
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your phone number"
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Where You Live:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="live"
                        rules={[
                          {
                            required: true,
                            message: "Where you live field is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter your location" size="large" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Describe Yourself:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item
                        name="desc"
                        rules={[
                          {
                            required: true,
                            message: "Describe yourself field is required",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Tell us something about yourself"
                          size="large"
                          rows={4}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} className="content-title py-1">
                  <Title level={4} className="mb-0">
                    Optional
                  </Title>
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col xs={24} md={6}>
                      <Text>School:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item name="school">
                        <Input placeholder="Enter your school" size="large" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Work:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item name="work">
                        <Input
                          placeholder="Enter your workplace"
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Time Zone:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item name="time_zone">
                        <Select
                          size="large"
                          placeholder="Select your time zone"
                        >
                          {timezones.map((timezone) => (
                            <Option key={timezone.value} value={timezone.value}>
                              {timezone.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Languages:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Form.Item name="languages">
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Add languages you speak."
                          size="large"
                        >
                          <Option value="English">English</Option>
                          <Option value="French">French</Option>
                          <Option value="German">German</Option>
                          <Option value="Italian">Italian</Option>
                          <Option value="Spanish">Spanish</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Text>Emergency contact:</Text>
                    </Col>
                    <Col xs={24} md={18}>
                      <Button
                        icon={<PlusOutlined />}
                        type="link"
                        className="link"
                        onClick={() => setEmergencyContact(!emergencyContact)}
                      >
                        Add Contact
                      </Button>
                      <Paragraph>
                        Give our Customer Experience team a trusted contact we
                        can alert in an urgent situation.
                      </Paragraph>
                    </Col>

                    {emergencyContact && (
                      <>
                        <Col xs={24} md={6}>
                          <Text>Name:</Text>
                        </Col>
                        <Col xs={24} md={18}>
                          <Form.Item name="emergency_name">
                            <Input
                              placeholder="Enter emergency contact name"
                              size="large"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={6}>
                          <Text>Phone:</Text>
                        </Col>
                        <Col xs={24} md={18}>
                          <Form.Item name="emergency_phone">
                            <Input
                              placeholder="Enter emergency contact phone number"
                              size="large"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={6}>
                          <Text>Email:</Text>
                        </Col>
                        <Col xs={24} md={18}>
                          <Form.Item name="emergency_email">
                            <Input
                              placeholder="Enter emergency contact email"
                              size="large"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={6}>
                          <Text>Relationship:</Text>
                        </Col>
                        <Col xs={24} md={18}>
                          <Form.Item name="emergency_relation">
                            <Input
                              placeholder="Enter your relationship with the contact"
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col xs={0} md={6}></Col>
                    <Col>
                      <Space split="or">
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          className="text-uppercase"
                        >
                          Save Changes
                        </Button>
                        <Link
                          to={PageRoutesConstant.Page.Home.path}
                          className="link"
                        >
                          Cancel
                        </Link>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardLayout>
    </>
  );
};
export default EditProfile;
