import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../../components/profile-layout/ProfileLayout";
import DashBoard from "../../../pages/user/dashboard/DashBoard";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Home: RouteKey;
}

const DashboardBaseRoutesConstant: IRoute = {
  Home: {
    Base: {
      path: "/home",
    },
  },
};

export const DashboardRoutesConstant: IRoute = {
  Home: {
    Dashboard: {
      path: `${DashboardBaseRoutesConstant.Home.Base.path}/dashboard`,
      element: <DashBoard />,
    },
  },
};

const DashboardRoutes = () => {
  return (
    <Routes>
      {Object.keys(DashboardRoutesConstant.Home).map((key) => {
        const route = DashboardRoutesConstant.Home[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              DashboardBaseRoutesConstant.Home.Base.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const DashboardFlowRoutes: FC = () => {
  return (
    <ProfileLayout>
      <DashboardRoutes />
    </ProfileLayout>
  );
};

export default DashboardFlowRoutes;
