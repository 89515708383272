import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Image, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { Hotels1, Hotels2 } from "../../assets/images";
import { Link } from "react-router-dom";

const { Paragraph } = Typography;

const HourlyHotels: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyHotels")} titleTextColor="red">
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Paragraph>
            Renting hotel rooms by the hour has become increasingly popular in
            recent years. The result of this influx is due to the amount of time
            that people actually spend in a hotel room vs. the unused rooms that
            hotels offer. Renting a hotel by the hour will allow you to relax
            and freshen up before continuing the rest of your day. When you’re
            in a new city, it’s hard to know exactly where you are in proximity
            to your hotel. That’s why renting a hotel room by the hour allows
            you to choose the hotel that you want to spend a few hours in and
            then have the freedom to travel to your next hotel destination.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Image src={Hotels1} alt={Hotels1} preview={false} />
        </Col>
        <Col xs={24}>
          <Paragraph>
            When you rent a hotel room by the hour, you’re allowed full guest
            access to all of the amenities and services of the hotel. Whether
            the hotel offers a day spa, a pool, a restaurant or a fitness
            center, when you rent your hotel room by the hour you are treated as
            a full-time guest. Hourly spaces is a unique website that lists the
            best possible hotel rooms for rent by the hour that are specific to
            your needs. Hourly hotel rentals make sense for the frequent
            traveler, and we want to make sure that you’re getting the best deal
            possible.
          </Paragraph>
          <Paragraph>
            Our website is easy to navigate and offers you a list of cities from
            around the United States to choose your hourly hotels from. If you
            want to rent hourly hotels in
            <Link to={""} rel="noreferrer" className="link mx-1">
              Washington DC,
            </Link>
            Hourly spaces has got you covered. We provide you a description of
            the hotel, the amenities, and services that it has to offer. We want
            to make your travel experience simpler, which is why we provide
            excellent customer service and can assist with your hourly hotel
            rental booking. The next time you travel and are looking for the
            perfect hotel, consider renting a hotel by the hour to stay within
            your budget and continue your adventure in a new city. What a better
            adventure than San Francisco? Hourly spaces offers hourly hotel
            rentals in
            <Link to={""} rel="noreferrer" className="link mx-1">
              San Francisco
            </Link>
            (SF), so that you can enjoy and experience the city without the
            stress of paying for unused hotel hours.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Image src={Hotels2} alt={Hotels2} preview={false} />
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyHotels;
