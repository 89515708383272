import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";

const { Title, Paragraph } = Typography;

const TermsOfService: FC = () => {
  const { t } = useTranslation();
  return (
    <CardLayout title={t("terms_of_service")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            The terms and conditions stated herein (collectively, the Agreement)
            constitute a legal agreement between you and Hourly Properties Inc.,
            a United States corporation (the Company or We or Our or Us or
            Hourly Spaces) having its registered office in Virginia, US. In
            order to use the Hourly Spaces (defined below) and the associated
            Application and Website (each as defined below) you must agree to
            the terms and conditions that are set out below, including without
            limitation (for Worldwide users) your agreement in Section 21 to
            arbitrate certain claims and not to participate in any class actions
            for claims subject to arbitration. By using the Company’s Hourly
            Spaces and downloading, installing or using the application supplied
            by the Company whose purpose is to enable you to use the Hourly
            Spaces (the Application), you hereby expressly acknowledge and agree
            to be bound by the terms and conditions of the Agreement, and any
            future Changes to this Agreement (as set forth below). You further
            acknowledge and agree that you have read and understand the
            Company’s
            <Link
              to={PageRoutesConstant.Page.PrivacyPolicy.path}
              className="link ms-1"
            >
              Privacy Policy
            </Link>
            . The Privacy Policy, which applies to your use of the Website,
            Application and Hourly Spaces, is hereby incorporated into this
            Agreement.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            The Company may make modifications, deletions and/or additions to
            this Agreement (Changes) at any time. Changes will be effective: (i)
            thirty (30) days after the Company provides notice of the Changes,
            whether such notice is provided through the Application or Website
            user interface, is sent to the email address associated with your
            account or otherwise; or (ii) when you opt-in or otherwise expressly
            agree to the Changes or a version of this Agreement incorporating
            the Changes, whichever comes first.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Key Content-related Terms</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - "Hourly Spaces” means the Company or its client's physical
                locations, including entry or access areas, and any common
                areas, as applicable, which are accessed through the
                Application, the Website or any other means that Hourly Spaces
                implements.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - "Content" means Paragraph, graphics, images, software
                (excluding the Application), video, information or other
                materials.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - "Company Content" means Content that Company makes available
                through the Application and/or Website, including any Content
                licensed from a third party, but excluding User Content.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - "User" means a person who accesses or uses the Hourly Spaces,
                Application or Website.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - "User Content" means Content that a User posts, uploads,
                publishes, submits or transmits to be made available through the
                Website or Application.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - "Website” means
                <Link to="https://www.hourlyspaces.com" className="link mx-1">
                  www.hourlyspaces.com
                </Link>
                or any other website through which the Company makes available
                the ability to book Hourly Spaces and posts this Agreement.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Use</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                In order to be able to use the Website, Application or the
                Hourly Spaces, you first need to sign up with the Company, which
                you can do through the Website or through the Application. When
                signing up, you are obligated to provide the Company with your
                personal information, mobile telephone number and credit card
                data (which will be held and processed by our third party
                payment provider, further details of which are set out in the
                paragraph below and in our Privacy Policy). Upon successful
                completion of your sign-up with the Company, You will be
                provided with a personal account (an “Account”), accessible to
                you by a password of your choice. You agree to maintain
                accurate, complete and up-to-date information in your Account.
                Your failure to maintain accurate, complete, and up-to-date
                account information, including having an invalid or expired
                payment method on file, may result in your inability to access
                and use the Website, Application or the Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                As further described in the Privacy Policy, the Company uses a
                third-party payment processor (the Payment Processor) to link
                your credit card account to the Website, Application and Hourly
                Spaces. The processing of payments or credits, as applicable, in
                connection with your use of the Website, Application and Hourly
                Spaces will be subject to the terms, conditions and privacy
                policies of the Payment Processor and your credit card issuer in
                addition to this Agreement. The Company is not responsible for
                any errors by the Payment Processor.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You agree to be bound by and comply with any additional terms,
                conditions and policies provided by the owner, tenant, and
                property manager of the Hourly Spaces (collectively, the
                Building Owners) relating to the use of a specific Hourly
                Space(s), including compliance with building security
                procedures, IT access and use procedures provided by the
                Building Owner (Venue Policies). The Venue Policies may be
                provided in electronic format through the Application, Website
                or in hardcopy format.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You are responsible for all activity that occurs with respect to
                your Account, including, without limitation, use of the Website,
                Application, and Hourly Spaces. Without limiting the foregoing,
                for any reservation made through your Account, if other persons
                are present in the Hourly Spaces, you do hereby agree to be
                fully responsible and to indemnify the Company for any violation
                of this Agreement or applicable laws, by-laws or regulations,
                even if such violation was caused by such other persons.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Representations and Warranties</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                By using the Website, Application or Hourly Spaces, you
                expressly represent and warrant that you are legally entitled to
                enter into this Agreement. If you reside in a jurisdiction which
                restricts the use of the Hourly Spaces because of age, or
                restricts the ability to enter into agreements such as this one
                due to age, you must abide by such age limits and you must not
                use the Website, Application and Hourly Spaces. Without limiting
                the foregoing, the Website, Hourly Spaces and Application are
                not available to persons under the age of 18. By using the
                Website, Application or Hourly Spaces, you represent and warrant
                that you are at least 18 years old. By using the Website,
                Application or the Hourly Spaces, you represent and warrant that
                you have the right, authority and capacity to enter into this
                Agreement and to abide by the terms and conditions of this
                Agreement. Your participation in using the Website, Hourly
                Spaces and/or Application is for your sole, personal use. You
                may not authorize others to use your user status and/or
                password, and you may not assign or otherwise transfer your user
                account and/or password to any other person or entity. When
                using the Website, Application or Hourly Spaces, you agree to
                comply with all applicable laws of the nation, the country,
                state, province and city in which you are present while using
                the Website, Application or Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You may only access the Hourly Spaces using authorized means. It
                is your responsibility to check to ensure you download the
                correct Application for your device. The Company is not liable
                if you do not have a compatible handset or if you have
                downloaded the wrong version of the Application for your
                handset. The Company reserves the right to terminate this
                Agreement should you be using the Application with an
                incompatible or unauthorized device.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                By using the Website, Application or the Hourly Spaces, you
                agree that:
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will only use the Website, Hourly Spaces or Application
                for lawful purposes.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not use the Website, Hourly Spaces or Application for
                any illegal or inappropriate purposes, including but not limited
                to skateboarding, roller-skating, roller blading, jumping rope,
                spitting, smoking, drug use, alcohol abuse, gambling or
                prostitution, pornography, sexual activity, violent or
                threatening behavior, or any other purpose reasonably likely to
                reflect negatively on Hourly Spaces or any Building Owner.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>- You will not smoke in the Hourly Spaces.</Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not light or allow any candles, incense sticks or
                naked flames in the Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not bring or keep any animals in the Hourly Spaces,
                except for service or assistance animals, which shall at all
                times be harnessed, leashed, or tethered, or controlled through
                voice, signal, or other effective controls.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not make excessive noise in the Hourly Spaces, nor
                create any noise nor conduct any other activity which would in
                Hourly Spaces’s or any Building Owner’s judgment disturb other
                Hourly Spaces clients, guests, or other tenants in any building
                containing an Hourly Space.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will follow all additional regulations regarding the
                Hourly Spaces, as may be communicated through the Application,
                Website, posted signs, or otherwise.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not use the Website, Application or Hourly Spaces for
                sending or storing any unlawful material or for fraudulent
                purposes.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not install, remove or modify any fixtures,
                equipment, machinery or appliances in the Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You are responsible for leaving the Hourly Spaces in a clean
                and tidy condition.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - Neither the Company nor any Building Owner is responsible for
                any property you may leave behind in a Hourly Space. It is your
                responsibility to ensure that you have retrieved all of your
                personal items prior to leaving a Hourly Space.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You may be held liable (and do hereby authorize the Company to
                charge you) for the repair cost for all damage to the Hourly
                Spaces and items therein during your use thereof.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You may be held liable (and do hereby authorize the Company to
                charge you) for any items which are taken from the Hourly
                Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not use the Website, Hourly Spaces or Application to
                cause nuisance, harassment, annoyance or inconvenience.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not impair the proper operation of the network.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not try to harm the Website, Hourly Spaces or
                Application in any way whatsoever.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will not copy, or distribute the Website, Application or
                other content without written permission from the Company.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will only use your password for the Website, Application
                and Hourly Spaces for your own use and will not resell or
                transfer it to a third party.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will keep secure and confidential your account password or
                any identification we provide you which allows access to the
                Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will provide the Company and/or the Building Owner with
                whatever proof of identity we/they may reasonably request.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will only use an access point or wireless internet
                connection which you are authorized to use. Additionally, when
                using the internet within the Hourly Spaces, you shall only use
                it for lawful purposes, and without restricting the foregoing,
                you shall not use it for purposes of criminal acts, consumer
                fraud, etc.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You will wear clothing appropriate to the building's situation
                while using the Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You do hereby acknowledge that there is a capacity limit as to
                the number of persons permitted in a Hourly Space at any given
                time, and that such limit shall be posted on the Application or
                Website. You do hereby agree to adhere to such capacity limit.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You hereby consent to the use of sensors by the Company to track
                the location of various items in the Hourly Spaces, which
                (without limitation) shall be used by the Company to optimize
                the Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You do further acknowledge that your use of the Hourly Spaces
                does not constitute the Company or the Building Owner granting
                you a lease, but is rather a limited, revocable, non-exclusive,
                non-transferable contractual license on the terms of this
                Agreement. In its sole discretion and without limiting any of
                the Company’s other rights hereunder, the Company may restrict
                your access to the Hourly Spaces in the event of fraud,
                trespassing, or violation of this Agreement.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={5}>Repair or Cleaning Fees</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You are responsible for the cost of repair for damage to, or
                necessary cleaning of, Hourly Spaces resulting from your
                violation of this Agreement or your use of the Website,
                Application, or Hourly Spaces in excess of normal “wear and
                tear.” In the event that the Company, in its reasonable
                discretion, determines that excessive repair or cleaning is
                required, the Company reserves the right to charge the payment
                method designated in your Account for the reasonable cost of
                such repair and/or cleaning, as well as an additional service
                fee which shall not exceed in Canada $500 Canadian Dollars, in
                the United States of America $500 US Dollars and in the UK £500
                GBP per occurrence. Any such amounts are non-refundable and at
                the reasonable discretion of the Company.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>
                License Grant, Restrictions and Copyright Policy
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Subject to your compliance with the terms and conditions of this
                Agreement, Company grants you a limited, revocable,
                non-exclusive, non-transferable license to view any User Content
                to which you are permitted access solely for your personal and
                non-commercial purposes. You have no right to sublicense or
                assign the license rights granted in this section.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You will not use, copy, adapt, modify, decompile, reverse
                engineer, prepare derivative works based upon, distribute,
                license, sell, transfer, publicly display, publicly perform,
                transmit, stream, broadcast or otherwise exploit the Website,
                Hourly Spaces, Application or Collective Content, except as
                expressly permitted in this Agreement. No licenses or rights are
                granted to you by implication or otherwise under any
                intellectual property rights owned or controlled by Company or
                its licensors, except for the licenses and rights expressly
                granted in this Agreement or expressly agreed in writing by the
                Company.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>License granted by User</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The Company may, in our sole discretion, permit Users to post,
                upload, publish, submit or transmit User Content. By making
                available any User Content on or through the Website, Hourly
                Spaces or Application, you hereby grant to Company a worldwide,
                irrevocable, perpetual, non-exclusive, transferable,
                royalty-free license, with the right to sublicense, to use,
                view, copy, adapt, modify, distribute, license, sell, transfer,
                publicly display, publicly perform, transmit, stream, broadcast
                and otherwise exploit such User Content including but without
                limitation on, through or by means of the Website, Hourly Spaces
                or Application. In connection herewith, you hereby renounce and
                waive in favor of Company any moral rights you have or might
                have, now or in the future, with respect to User Content.
                Nothing in this Agreement will be deemed to restrict any rights
                that you may have to use and exploit any User Content.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You acknowledge and agree that you are solely responsible for
                all User Content that you make available through the Website,
                Hourly Spaces or Application. Accordingly, you represent and
                warrant that: (i) you either are the sole and exclusive owner of
                all User Content that you make available through the Website,
                Hourly Spaces or Application or you have all rights, licenses,
                consents and releases that are necessary to grant to Company the
                rights in such User Content, as contemplated under this
                Agreement; and (ii) neither the User Content nor your posting,
                uploading, publication, submission or transmittal of the User
                Content or Company’s use of the User Content (or any portion
                thereof) will infringe, misappropriate or violate a third
                party’s patent, copyright, trademark, trade secret, moral rights
                or other intellectual property rights, or rights of publicity or
                privacy, or result in the violation of any applicable law or
                regulation.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Application License</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Subject to your compliance with this Agreement, Company grants
                you a limited non-exclusive, non-transferable license to
                download and install a copy of the Application on any mobile
                device or computer that you own or control and to run such copy
                of the Application solely for your own personal use.
                Furthermore, with respect to any Application accessed through or
                downloaded from the Apple App Store (App Store Sourced
                Application), you will use the App Store Sourced Application
                only: (i) on an Apple-branded product that runs iOS (Apple’s
                proprietary operating system software); and (ii) as permitted by
                the "Usage Rules" set forth in the Apple App Store Terms and
                Conditions. Company reserves all rights in and to the
                Application not expressly granted to you under this Agreement.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>
                Accessing and Downloading the Application from iTunes
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The following applies to any App Store Sourced Application:
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You acknowledge and agree that (i) this Agreement is concluded
                between you and Company only, and not Apple, and (ii) Company,
                not Apple, is solely responsible for the App Store Sourced
                Application and content thereof. Your use of the App Store
                Sourced Application must comply with the Apple App Store Terms
                and Conditions, which you are responsible to review from time to
                time.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You acknowledge that Apple has no obligation whatsoever to
                furnish any maintenance and support services with respect to the
                App Store Sourced Application.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - In the event of any failure of the App Store Sourced
                Application to conform to any applicable warranty, you may
                notify Apple, and Apple will refund the purchase price for the
                App Store Sourced Application to you and to the maximum extent
                permitted by applicable law, Apple will have no other warranty
                obligation whatsoever with respect to the App Store Sourced
                Application. As between Company and Apple, any other claims,
                losses, liabilities, damages, costs or expenses attributable to
                any failure to conform to any warranty will be the sole
                responsibility of Company.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You and Company acknowledge that, as between Company and
                Apple, Apple is not responsible for addressing any claims you
                have or any claims of any third party relating to the App Store
                Sourced Application or your possession and use of the App Store
                Sourced Application, including, but not limited to: (i) product
                liability claims; (ii) any claim that the App Store Sourced
                Application fails to conform to any applicable legal or
                regulatory requirement; and (iii) claims arising under consumer
                protection or similar legislation.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - You and Company acknowledge and agree that Apple, and Apple’s
                subsidiaries, are third party beneficiaries of this Agreement as
                related to your license of the App Store Sourced Application,
                and that, upon your acceptance of the terms and conditions of
                this Agreement, Apple will have the right (and will be deemed to
                have accepted the right) to enforce this Agreement as related to
                your license of the App Store Sourced Application against you as
                a third party beneficiary thereof.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                - Without limiting any other terms of this Agreement, you must
                comply with all applicable third party terms of agreement when
                using the App Store Sourced Application.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You shall not (i) license, sublicense, sell, resell, transfer,
                assign, distribute or otherwise commercially exploit or make
                available to any third party the Website, Hourly Spaces or the
                Application in any way; (ii) modify or make derivative works
                based upon the Website, Hourly Spaces or the Application; (iii)
                create Internet "links" to, or "frame" or "mirror" the Website
                or Application on any other server or wireless or Internet-based
                device; (iv) reverse engineer the Website or Application, or
                access the Website or Application in order to (a) build a
                competitive product or service, (b) build a product using
                similar ideas, features, functions or graphics of the Website,
                Hourly Spaces or Application, or (c) copy any ideas, features,
                functions or graphics of the Website, Hourly Spaces or
                Application, or (v) launch an automated program or script,
                including, but not limited to, web spiders, web crawlers, web
                robots, web ants, web indexers, bots, viruses or worms, or any
                program which may make multiple server requests per second, or
                unduly burdens or hinders the operation and/or performance of
                the Website, Hourly Spaces or Application.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You shall not: (i) send spam or otherwise duplicative or
                unsolicited messages in violation of applicable laws; (ii) send
                or store infringing, obscene, threatening, libelous, or
                otherwise unlawful or tortuous material, including material
                harmful to children or violative of third party privacy rights
                and material that could be considered as sexual or psychological
                harassment or of a discriminatory nature; (iii) send or store
                material containing software viruses, worms, Trojan horses or
                other harmful computer code, files, scripts, agents or programs;
                (iv) interfere with or disrupt the integrity or performance of
                the Website, Application or Hourly Spaces or the data contained
                therein; or (v) attempt to gain unauthorized access to the
                Website, Application or Hourly Spaces or its related systems or
                networks.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Company will have the right to investigate and prosecute
                violations of any of the above to the fullest extent of the law.
                Company may involve and cooperate with law enforcement
                authorities in prosecuting users who violate this Agreement. You
                acknowledge that Company has no obligation to monitor your
                access to or use of the Website, Hourly Spaces, Application or
                Collective Content or to review or edit any Collective Content,
                but has the right to do so for the purpose of operating the
                Website, Hourly Spaces and Application, to ensure your
                compliance with this Agreement, or to comply with applicable law
                or the order or requirement of a court, administrative agency or
                other governmental body. Company reserves the right, at any time
                and without prior notice, to remove or disable access to any
                Collective Content that Company, at its sole discretion,
                considers to be in violation of this Agreement or otherwise
                harmful to the Website, Hourly Spaces or Application.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Copyright Policy</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The Company respects copyright law and expects its Users to do
                the same. It is Company’s policy to terminate in appropriate
                circumstances Users or other account holders who repeatedly
                infringe or are believed to be repeatedly infringing the rights
                of copyright holders.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Payment Terms</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Any fees which the Company may charge you for the use of the
                Website, Application or Hourly Spaces, will be as set out on the
                Website or Application at the time of purchase, are inclusive of
                any taxes payable, are due immediately and are non-refundable.
                This no refund policy shall apply at all times regardless of
                your decision to terminate your usage, the Company's decision to
                terminate your usage, disruption caused to our Website,
                Application or Hourly Spaces either planned, accidental or
                intentional, or any reason whatsoever. The Company reserves the
                right to determine final prevailing pricing.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You do hereby agree that you shall be charged fees based on the
                length of time of your booking of the Hourly Spaces (regardless
                if you only make use of the Hourly Spaces for less than your
                booked time). If you exceed the amount of time for which you
                booked a Hourly Space, then you shall be charged for such excess
                time, as well as an overage charge if such excess time conflicts
                with another User’s booked time. You do hereby consent to such
                excess time charges and to such overage charge.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The Company, at its sole discretion, may make promotional offers
                with different features and different rates to any of our
                customers. These promotional offers, unless made to you, shall
                have no bearing whatsoever on your offer or contract. The
                Company may change the fees for our Website, Hourly Spaces or
                Application as we deem necessary for our business. We encourage
                you to check back at our Website periodically to find out about
                how we charge for the Website, Hourly Spaces or Application.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>SMS Messaging</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                If you have provided your consent, Company may send you SMS
                messages in relation to your bookings and use of Hourly Spaces
                and for promotional purposes. Message and data rates may apply.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                If you change your mobile phone service provider, you might have
                to re-enroll with the Company in order to receive future SMS
                messages. Company reserves the right to stop sending SMS
                messages at any time; you may at all times opt-out by replying
                from your mobile phone to any Paragraph from the Company with
                the word STOP , which opt-out will become effective within seven
                (7) days.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                It is important to note that opting out of SMS messaging may
                prevent you from receiving information necessary to properly use
                the Hourly Spaces service, such as room access codes and other
                important information.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Intellectual Property Ownership</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The Company alone (and its licensors, where applicable) shall
                own all right, title and interest, including all related
                intellectual property rights, in and to the Website, Application
                and the Hourly Spaces and any suggestions, ideas, enhancement
                requests, feedback, recommendations or other information
                provided by you or any other party relating to the Website,
                Application or the Hourly Spaces (collectively, “Feedback”). You
                are not required to provide any Feedback to the Company. To the
                extent you do provide any Feedback to the Company, you agree to
                assign and hereby do assign to the Company all right, title and
                interest in and to such Feedback and agree that the Company may
                freely utilize such Feedback without compensation to you. This
                Agreement is not a sale and does not convey to you any rights of
                ownership in or related to the Website, Application or the
                Hourly Spaces, or any intellectual property rights owned by the
                Company. The Company name, the Company logo, and the product
                names associated with the Website, Application and Hourly Spaces
                are trademarks of the Company or third parties, and no right or
                license is granted to use them.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Third Party Interactions</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                During use of the Website, Application and Hourly Spaces, you
                may enter into correspondence with, purchase goods and/or
                services from, or participate in promotions of third party
                service providers, advertisers or sponsors showing their goods
                and/or services through the Website, Application or Hourly
                Spaces. Any such activity, and any terms, conditions, warranties
                or representations associated with such activity, is solely
                between you and the applicable third party. The Company and its
                licensors shall have no liability, obligation or responsibility
                for any such correspondence, purchase, transaction, services or
                promotion between you and any such third party. The Company does
                not endorse any sites on the Internet that are linked through
                the Website, Hourly Spaces or Application, and in no event shall
                the Company or its licensors be responsible for any content,
                products, services or other materials on or available from such
                sites or third party providers. The Company provides the
                Website, Application and Hourly Spaces to you pursuant to the
                terms and conditions of this Agreement. You recognize, however,
                that certain third party providers of goods and/or services may
                require your agreement to additional or different terms and
                conditions prior to your use of or access to such goods or
                services, and the Company disclaims any and all responsibility
                or liability arising from such agreements between you and the
                third party providers.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The Company may rely on third party advertising and marketing
                supplied through the Website, Application or Hourly Spaces and
                other mechanisms to subsidize the Website, Application or Hourly
                Spaces. By agreeing to these terms and conditions you agree to
                receive such advertising and marketing. If you do not want to
                receive such advertising, you must notify us in writing. The
                Company reserves the right to charge you a higher fee for the
                Website, Hourly Spaces or Application, as the case may be,
                should you choose not to receive these advertising services.
                This higher fee, if applicable, will be posted on the Company's
                website located at
                <Link
                  to={PageRoutesConstant.Page.Home.path}
                  className="link ms-1"
                >
                  www.hourlyspaces.com
                </Link>
                . The Company may compile, release and disclose non-nominative
                information regarding you and your use of the Website,
                Application or Hourly Spaces as part of a customer profile or
                similar report or analysis, including, without limitation to
                third party service providers. You agree that it is your
                responsibility to take reasonable precautions in all actions and
                interactions with any third party you interact with through the
                Hourly Spaces.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Indemnification</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                By entering into this Agreement and using the Website,
                Application or Hourly Spaces, you agree that you shall defend,
                indemnify and hold the Company, its licensors, applicable
                Building Owners and each of their parent organizations,
                subsidiaries, affiliates, officers, directors, Users, employees,
                attorneys and agents harmless from and against any and all
                claims, costs, damages, losses, liabilities and expenses
                (including attorneys' fees and costs) arising out of or in
                connection with: (a) your violation or breach of any term of
                this Agreement or any applicable law or regulation, whether or
                not referenced herein; (b) your violation of any rights of any
                third party, or (c) your use or misuse of the Website,
                Application or Hourly Spaces, except in each case solely to the
                extent any of the foregoing arises directly from the gross
                negligence or willful misconduct of the Company or a Building
                Owner.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Disclaimer of Warranties</Title>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                THE COMPANY AND BUILDING OWNERS MAKE NO REPRESENTATION,
                WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS,
                QUALITY, SUITABILITY, AVAILABILITY, SAFETY, ACCURACY OR
                COMPLETENESS OF THE WEBSITE, HOURLY SPACES OR APPLICATION. THE
                COMPANY AND BUILDING OWNERS DO NOT REPRESENT OR WARRANT THAT (A)
                THE USE OF THE WEBSITE, HOURLY SPACES OR APPLICATION WILL BE
                SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN
                COMBINATION WITH ANY OTHER HARDWARE, APPLICATION, SYSTEM OR
                DATA, (B) THE WEBSITE, HOURLY SPACES OR APPLICATION WILL MEET
                YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE
                ACCURATE OR RELIABLE, (D) THE QUALITY OF THE HOURLY SPACES, AS
                WELL AS ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
                PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE OR APPLICATION
                WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR
                DEFECTS IN THE WEBSITE, HOURLY SPACES OR APPLICATION WILL BE
                CORRECTED, OR (F) THE WEBSITE, APPLICATION OR THE SERVER(S) THAT
                MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS. THE WEBSITE, HOURLY SPACES AND APPLICATION IS
                PROVIDED TO YOU STRICTLY ON AN "AS IS" BASIS. ALL CONDITIONS,
                REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED,
                STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY
                IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, CONFORMANCE WITH DESCRIPTION OR NON-INFRINGEMENT OF
                THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW BY THE COMPANY AND BUILDING OWNERS.
                THE COMPANY AND BUILDING OWNERS MAKE NO REPRESENTATION,
                WARRANTY, OR GUARANTY AS TO THE RELIABILITY, SAFETY, TIMELINESS,
                QUALITY, SUITABILITY OR AVAILABILITY OF THE HOURLY SPACES, AS
                WELL AS ANY SERVICES, PRODUCTS OR GOODS OBTAINED BY THIRD
                PARTIES THROUGH THE USE OF THE WEBSITE, HOURLY SPACES OR
                APPLICATION. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK
                ARISING OUT OF YOUR USE OF THE WEBSITE, APPLICATION AND HOURLY
                SPACES, AND ANY THIRD PARTY SERVICES OR PRODUCTS REMAINS SOLELY
                WITH YOU, TO THE MAXIMUM EXTENT PERMITTED BY LAW.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                FOR GREATER CERTAINTY: (A) THE LOCKING MECHANISM USED ON THE
                HOURLY SPACES IS PROVIDED BY A THIRD PARTY, AND THE COMPANY AND
                BUILDING OWNERS SHALL HAVE NO LIABILITY WHATSOEVER WITH RESPECT
                TO ANY FAILURE OF THE LOCKING SYSTEM TO WORK IN ITS INTENDED
                MANNER, INCLUDING WITHOUT LIMITATION ANY LOSS OR THEFT OF YOUR
                PROPERTY; (B) THE HOURLY SPACES ARE LOCATED IN BUILDINGS WHICH
                ARE NOT OWNED OR MANAGED BY THE COMPANY, AND THE COMPANY MAKES
                NO REPRESENTATIONS OR WARRANTIES RELATING THERETO.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                ADDITIONALLY, THE COMPANY AND BUILDING OWNERS MAKE NO
                REPRESENTATION OR WARRANTY WITH RESPECT TO THE SUITABILITY OF
                ANY HOURLY SPACE FOR ANY PARTICULAR ACTIVITY NOR ANY ACTIVITIES
                THAT ARE CARRIED ON IN THE HOURLY SPACES, AND SHALL NOT BE
                LIABLE IN ANYWAY FOR SUCH ACTIVITIES.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                THE COMPANY AND BUILDING OWNERS MAKES NO REPRESENTATION
                REGARDING THE WIFI ACCESSIBLE IN THE HOURLY SPACES, INCLUDING
                WITH REGARD TO ACCESS THERETO.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
                AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Internet Delays</Title>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                THE WEBSITE AND APPLICATION MAY BE SUBJECT TO LIMITATIONS,
                DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET
                AND ELECTRONIC COMMUNICATIONS. THE COMPANY IS NOT RESPONSIBLE
                FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGES OF
                WHATSOEVER NATURE RESULTING FROM SUCH PROBLEMS.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Limitation of Liability</Title>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                IN NO EVENT SHALL THE COMPANY'S, ITS LICENSORS’ OR ANY BUILDING
                OWNER’S AGGREGATE LIABILITY ARISING OUT OF OR RELATING TO THIS
                AGREEMENT OR YOUR USE OF (OR INABILITY TO USE) A HOURLY SPACE,
                THE WEBSITE OR APPLICATION EXCEED THE AMOUNTS ACTUALLY PAID BY
                YOU TO THE COMPANY IN THE SIX (6) MONTH PERIOD IMMEDIATELY
                PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM OR THE AMOUNT OF
                FIFTY CANADIAN DOLLARS (50$) (IF THE LIABILITY ARISES IN
                CANADA), FIFTY US DOLLARS (IF THE LIABILITY ARISES IN THE US) OR
                FIFTY GB POUNDS (IF THE LIABILITY ARISES IN ENGLAND), WHICHEVER
                IS GREATER. IN NO EVENT SHALL THE COMPANY, ITS LICENSORS AND/OR
                ANY BUILDING OWNER BE LIABLE TO YOU FOR ANY INDIRECT, PUNITIVE,
                SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES
                OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, LOSS OF DATA,
                REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE). THE COMPANY
                AND/OR ITS LICENSORS SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR
                INJURY WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED
                TO LOSS, DAMAGE OR INJURY ARISING OUT OF, OR IN ANY WAY
                CONNECTED WITH THE WEBSITE, HOURLY SPACES OR APPLICATION,
                INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE
                WEBSITE, HOURLY SPACES OR APPLICATION, ANY RELIANCE PLACED BY
                YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY
                ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION
                BETWEEN YOU AND ANY THIRD PARTY SERVICE PROVIDER, ADVERTISER OR
                SPONSOR WHOSE ADVERTISING APPEARS ON OR WITHIN THE WEBSITE,
                APPLICATION OR HOURLY SPACES. THE FOREGOING EXCLUSIONS SHALL
                APPLY EVEN IF THE COMPANY AND/OR ITS LICENSORS OR ANY BUILDING
                OWNER HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                RESPONSIBILITY FOR THE DECISIONS YOU MAKE REGARDING HOURLY
                SPACES OFFERED VIA THE WEBSITE OR APPLICATION (WITH ALL ITS
                IMPLICATIONS) RESTS SOLELY WITH YOU. THE COMPANY WILL NOT ASSESS
                THE SUITABILITY, LEGALITY OR ABILITY OF ANY SUCH BUILDING OWNERS
                OR OTHER THIRD PARTIES AND YOU EXPRESSLY WAIVE AND RELEASE THE
                COMPANY AND BUILDING OWNERS, TO THE MAXIMUM EXTENT ALLOWABLE
                UNDER APPLICABLE LAW, FROM ANY AND ALL LIABILITY, CLAIMS, CAUSES
                OF ACTION, OR DAMAGES ARISING FROM YOUR USE OF THE WEBSITE,
                APPLICATION OR HOURLY SPACES, OR IN ANY WAY RELATED TO THE THIRD
                PARTIES INTRODUCED TO YOU BY THE WEBSITE, APPLICATION OR HOURLY
                SPACES. YOU EXPRESSLY WAIVE AND RELEASE ANY AND ALL RIGHTS AND
                BENEFITS UNDER SECTION 1542 OF THE CIVIL CODE OF THE STATE OF
                CALIFORNIA (OR ANY ANALOGOUS LAW OF ANY OTHER STATE), WHICH
                READS AS FOLLOWS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
                WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
                FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY
                HIM, MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE
                DEBTOR".
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                THE QUALITY OF THE BUILDINGS IN WHICH THE HOURLY SPACES ARE
                LOCATED ARE ENTIRELY THE RESPONSIBILITY OF THE BUILDING OWNERS.
                YOU UNDERSTAND THAT BY USING THE WEBSITE, APPLICATION AND THE
                HOURLY SPACES, YOU MAY BE EXPOSED TO LOCATIONS THAT ARE
                POTENTIALLY DANGEROUS, OFFENSIVE, HARMFUL TO MINORS, UNSAFE OR
                OTHERWISE OBJECTIONABLE, AND THAT YOU USE THE WEBSITE,
                APPLICATION AND THE HOURLY SPACES AT YOUR OWN RISK.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                TO THE EXTENT APPLICABLE UNDER LOCAL LAW, NOTHING IN THIS
                AGREEMENT SHALL LIMIT THE COMPANY'S LIABILITY FOR (A) DEATH OR
                PERSONAL INJURY CAUSED BY THE COMPANY'S NEGLIGENCE OR THE
                NEGLIGENCE OF ITS EMPLOYEES, OR (B) FOR FRAUD OR FRAUDULENT
                MISREPRESENTATION.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph className="Paragraph-uppercase">
                APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
                LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, THE
                COMPANY’S LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT
                PERMITTED BY LAW.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Rating System</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You may be required to rate certain aspects of your overall
                experience of the Hourly Spaces, which rating will be prompted
                through the Website or Application. If you do not participate in
                such rating when requested, the Company reserves the right
                (without limiting any of Company’s other rights hereunder) to
                restrict your access to the Hourly Spaces.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Additionally, if the Company, or if other Users, rate your usage
                of the Hourly Spaces as being below a threshold acceptable to
                the Company, in its sole discretion, then (without limiting any
                of Company’s other rights hereunder) the Company may restrict
                your access to the Hourly Spaces.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Notice</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The Company may give notice by means of email to your email
                address on record in the Company's account information, or by
                written communication sent by first class mail or pre-paid post
                to your address on record in the Company's account information.
                Such notice shall be deemed to have been given upon the
                expiration of 48 hours after mailing (if sent by first class
                mail or pre-paid post) or 12 hours after sending (if sent by
                email). You may give notice, and address any complaint or claim
                to the Company (such notice, complaint or claim shall be deemed
                given when received by the Company) at any time by means of
                email to
                <Link
                  to="mailto:support@hourlyspaces.com"
                  className="link ms-1"
                >
                  support@hourlyspaces.com
                </Link>
                .
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Assignment</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                This Agreement may not be assigned by you (whether in whole or
                in part) without the prior written approval of the Company. This
                Agreement may be assigned without your consent (in whole or in
                part) by the Company, including to (i) a parent or subsidiary,
                (ii) an acquirer of assets, or (iii) a successor by merger. Any
                purported assignment in violation of this section shall be void.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>
                Controlling Law and Jurisdiction – Canadian Users
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                If you are a resident of Canada, this Section 20 applies to you.
                If you are a resident of the U.S.A., Section 21 applies to you
                instead of this Section 20. If you are a resident of the United
                Kingdom, Section 22 applies to you instead of this Section 20.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                General - This Agreement and any action related thereto will be
                governed by the laws of the Province of Quebec without regard to
                its conflict of laws provisions. Subject to the rest of this
                Section 20, the exclusive jurisdiction and venue of any action
                in relation to this Agreement will be the District of Montreal
                and each of the parties hereto waives any objection to
                jurisdiction and venue in such courts. However, in the event of
                the actual or threatened infringement, misappropriation or
                violation of Company’s copyrights, trademarks, trade secrets,
                patents or other intellectual property rights, Company may, at
                its discretion, institute legal proceedings in any jurisdiction
                (s) which is (are) deemed necessary or advisable.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Arbitration - You and Company agree that any dispute, claim or
                controversy arising out of or relating to this Agreement or the
                breach, termination, enforcement, interpretation or validity
                thereof or the use of the Website, Hourly Spaces or Application
                (collectively, "Disputes") will be settled by binding
                arbitration, except that each party retains the right to bring
                an individual action in small claims court and the right to seek
                injunctive or other equitable relief in a court of competent
                jurisdiction to prevent the actual or threatened infringement,
                misappropriation or violation of a party’s copyrights,
                trademarks, trade secrets, patents or other intellectual
                property rights. You acknowledge and agree that you are waiving
                the right to participate as a plaintiff in any purported class
                action or representative proceeding. Further, unless both you
                and Company otherwise agree in writing, the arbitrators may not
                consolidate more than one person's claims, and may not otherwise
                preside over any form of any class or representative proceeding.
                If this specific paragraph is held unenforceable, then the
                entirety of this "Dispute Resolution" section will be deemed
                void. Except as provided in the preceding sentence, this
                "Dispute Resolution" section will survive any termination of
                this Agreement.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Arbitration Rules - The arbitration will be subject to article
                940 and following of the Code of civil Procedure (Quebec).
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Decision - The arbitrators will render an award within the time
                frame specified in the Code of civil Procedure (Quebec). Such
                decision will include the essential findings and conclusions
                upon which the arbitrators based the award. Judgment on the
                arbitration award may be entered in any court having
                jurisdiction thereof. Any damages granted by the arbitrators
                must be consistent with the terms of the "Limitation of
                Liability" section above as to the types and the amounts of
                damages for which a party may be held liable.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Fees - The arbitrators shall determine who is responsible to pay
                the fees associated with the arbitration.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>
                Controlling Law and Jurisdiction – United States Users
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                If you are a resident of the United States of America, this
                Section 21 applies to you. If you are a resident of Canada,
                Section 20 applies to you instead of this Section 21. If you are
                a resident of the United Kingdom, Section 22 applies to you
                instead of this Section 21.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                General - This Agreement and any action related thereto will be
                governed by the laws of the state of New York without regard to
                its conflict of laws provisions. Subject to the rest of this
                Section 21, the exclusive jurisdiction and venue of any action
                in relation to this Agreement will be the state and federal
                courts located in the Southern District of New York and each of
                the parties hereto waives any objection to jurisdiction and
                venue in such courts. However, in the event of the actual or
                threatened infringement, misappropriation or violation of
                Company’s copyrights, trademarks, trade secrets, patents or
                other intellectual property rights, Company may, at its
                discretion, institute legal proceedings in any jurisdiction (s)
                which is (are) deemed necessary or advisable.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Arbitration - Any claim (excluding claims for injunctive or
                other equitable relief) where the total amount of the award
                sought by either party is less than $10,000 shall be resolved
                via binding non-appearance-based arbitration initiated through
                the American Arbitration Association (“AAA”). The AAA Rules are
                available online at
                <Link to="http://www.adr.org/" className="link mx-1">
                  http://www.adr.org
                </Link>
                or by calling the AAA at 1-800-778-7879. In any such
                arbitration, the parties and AAA must comply with the following
                rules: (a) the arbitration shall be conducted by telephone,
                online and/or be solely based on written submissions, the
                specific manner shall be chosen by the party initiating the
                arbitration; (b) the arbitration shall not involve any personal
                appearance by the parties or witnesses unless otherwise mutually
                agreed by the parties; (c) the arbitrator may award injunctive
                or declaratory relief only in favor of the individual party
                seeking relief and only to the extent necessary to provide
                relief warranted by that party's individual claim; and (d) any
                judgment on the award rendered by the arbitrator may be entered
                in any court of competent jurisdiction. Nothing in this
                Agreement shall prevent either party from seeking remedies in
                small claims court of competent jurisdiction.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Class Action Waiver - YOU AGREE THAT ANY CLAIMS SUBJECT TO
                ARBITRATION UNDER THIS SECTION 21 MUST BE MADE IN YOUR
                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Time Limits - YOU AND THE COMPANY AGREE THAT ANY CAUSE OF ACTION
                ARISING OUT OF OR RELATED TO THIS AGREEMENT, THE WEBSITE,
                APPLICATION OR HOURLY SPACES MUST COMMENCE WITHIN ONE (1) YEAR
                AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
                ACTION IS PERMANENTLY BARRED.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>
                Controlling Law and Jurisdiction – English Users
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                If you are a resident of England and Wales, this Section 22
                applies to you. If you are a resident of Canada Section 20
                applies to you and if you are a resident of the U.S.A., Section
                21 applies to you.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                General - This Agreement and any action related thereto will be
                governed by the laws of England and Wales. Subject to the rest
                of this Section 22, the exclusive jurisdiction and venue of any
                action in relation to this Agreement will be the courts of
                England and Wales, save where you are a consumer and you live in
                a part of the UK other than England and Wales, the applicable
                law of that part of the UK will govern and any Dispute will only
                be dealt with by the courts there. In the event of the actual or
                threatened infringement, misappropriation or violation of
                Company’s copyrights, trademarks, trade secrets, patents or
                other intellectual property rights, Company may, at its
                discretion, institute legal proceedings in any jurisdiction
                which is deemed necessary or advisable.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Arbitration - You and Company agree that any dispute, claim or
                controversy arising out of or relating to this Agreement or the
                breach, termination, enforcement, interpretation or validity
                thereof or the use of the Website, Hourly Spaces or Application
                (collectively, "Disputes") will be referred to and finally
                resolved by arbitration under the rules of the London Court of
                Arbitration (LCIA), which rules are deemed incorporated by
                reference to this clause. The number of arbitrators shall be,
                unless determined by the LCIA otherwise, one and the seat of
                arbitration shall be London, England. The language use in the
                proceedings shall be English. If you are a consumer, you may in
                your discretion decide whether to resolve any Dispute by
                arbitration or whether to exercise any other legal remedy which
                may be available to you.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Export Control</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                By using the Website or Application, you represent and warrant
                that: (i) you are not located in a country that is subject to a
                U.S. Government embargo, or that has been designated by the U.S.
                Government as a "terrorist supporting" country; and (ii) you are
                not listed on any U.S. Government list of prohibited or
                restricted parties.
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>Termination</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You agree that the Company, in its sole discretion and for any
                or no reason, may terminate any member or customer account (or
                any part thereof) you may have with the Company or your use of
                Hourly Space, and remove and discard all or any part of your
                account or any of your User Content, at any time. The Company
                may also in its sole discretion and at any time discontinue
                providing access to the Website, Application and Hourly Spaces,
                or any part thereof, with or without notice. You agree that any
                termination of your access to the Website, Hourly Space,
                Application or any account you may have or portion thereof may
                be effected without prior notice, and you agree that the Company
                shall not be liable to you. These remedies are in addition to
                any other remedies Company may have at law or in equity.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You may terminate this Agreement at any time by deleting the
                Application from your device, ceasing all use of the Website and
                Hourly Spaces and requesting the Company to cancel your account
                via email sent to
                <Link
                  to="mailto:support@hourlyspaces.com"
                  className="link ms-1"
                >
                  support@hourlyspaces.com
                </Link>
                .
              </Paragraph>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={3}>General</Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                No joint venture, partnership, employment, or agency
                relationship exists between you, the Company or any third party
                provider as a result of this Agreement or use of the Website,
                Hourly Spaces or Application. If any provision of the Agreement
                is held to be invalid or unenforceable, such provision shall be
                struck and the remaining provisions shall be enforced to the
                fullest extent under law. The failure of the Company to enforce
                any right or provision in this Agreement shall not constitute a
                waiver of such right or provision unless acknowledged and agreed
                to by the Company in writing. This Agreement comprises the
                entire agreement between you and the Company and supersedes all
                prior or contemporaneous negotiations, discussions or
                agreements, whether written or oral, between the parties
                regarding the subject matter contained herein. Nothing in this
                Agreement allows any party who is not a party to this Agreement
                to have any rights under this Agreement nor be able to enforce
                this Agreement.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default TermsOfService;
