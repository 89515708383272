import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";
import { PageRoutesConstant } from "./PageRoutes";

export const PrivateRoute: FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? (
    <Outlet /> // If authenticated, render the requested route
  ) : (
    // If not authenticated, redirect to signin page with the "from" location
    <Navigate
      to={PageRoutesConstant.Page.SignIn.path}
      state={{ from: location }}
    />
  );
};
