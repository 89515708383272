import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Divider, Layout, Row, Space, Typography } from "antd";
import { Container } from "react-bootstrap";
import { currentCitiesData, socialIconsData } from "./MainFooter.constant";
import LanguageDropdown from "../language/LanguageDropdown";
import CurrencyDropdown from "../currency/CurrencyDropdown";
import { PageRoutesConstant } from "../../router/components/PageRoutes";
import { BlogsRoutesConstant } from "../../router/components/BlogsRoute";
import "./MainFooter.style.scss";

const { Footer } = Layout;
const { Title } = Typography;

const MainFooter: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const discoverData = [
    {
      name: "footer.discover.howItWorks",
      path: PageRoutesConstant.Page.HowItWork.path,
    },
    {
      name: "footer.discover.whyHost",
      path: PageRoutesConstant.Page.WhyHost.path,
    },
    {
      name: "footer.discover.shortTermRental",
      path: PageRoutesConstant.Page.ShortTermRental.path,
    },
    {
      name: "footer.discover.whyRent",
      path: PageRoutesConstant.Page.WhyRent.path,
    },
    {
      name: "footer.discover.hourlyMeetingRoom",
      path: PageRoutesConstant.Page.HourlyMeetingRoom.path,
    },
    {
      name: "footer.discover.hourlyConferenceRooms",
      path: PageRoutesConstant.Page.HourlyConferenceRooms.path,
    },
    {
      name: "footer.discover.eventSpaceRental",
      path: PageRoutesConstant.Page.HourlyEventSpaceRental.path,
    },
    {
      name: "footer.discover.hourlyOfficeSpaceRental",
      path: PageRoutesConstant.Page.HourlyOfficeSpaceRental.path,
    },
  ];

  const companyData = [
    { name: "footer.company.blog", path: BlogsRoutesConstant.Blogs.Home.path },
    {
      name: "footer.company.contactUs",
      path: PageRoutesConstant.Page.ContactUs.path,
    },
    { name: "footer.company.faq", path: PageRoutesConstant.Page.Faq.path },
    {
      name: "footer.company.termsOfService",
      path: PageRoutesConstant.Page.TermsOfService.path,
    },
    {
      name: "footer.company.privacyPolicy",
      path: PageRoutesConstant.Page.PrivacyPolicy.path,
    },
    {
      name: "footer.company.careers",
      path: PageRoutesConstant.Page.Careers.path,
    },
    { name: "footer.company.investorRelations", path: "/" },
  ];

  const serviceSectorData = [
    {
      service: "footer.services.hourlyOfficeRental",
      path: PageRoutesConstant.Page.HourlyOfficeSpaceRental.path,
    },
    {
      service: "footer.services.conferenceRoomRental",
      path: PageRoutesConstant.Page.HourlyConferenceRooms.path,
    },
    {
      service: "footer.services.weddingVenueRental",
      path: PageRoutesConstant.Page.WeddingVenueRental.path,
    },
    {
      service: "footer.services.eventSpaceRental",
      path: PageRoutesConstant.Page.HourlyEventSpaceRental.path,
    },
    {
      service: "footer.services.partyVenueRental",
      path: PageRoutesConstant.Page.PartyVenueRental.path,
    },
    {
      service: "footer.services.hourlyMeetingRoomRental",
      path: PageRoutesConstant.Page.HourlyMeetingRoom.path,
    },
    {
      service: "footer.services.hourlyHotels",
      path: PageRoutesConstant.Page.HourlyHotels.path,
    },
    {
      service: "footer.services.rentPentHouse",
      path: PageRoutesConstant.Page.RentPentHouse.path,
    },
    {
      service: "footer.services.rentPracticeRooms",
      path: PageRoutesConstant.Page.RentPracticeRooms.path,
    },

    {
      service: "footer.services.rentTheaters",
      path: PageRoutesConstant.Page.RentTheater.path,
    },
    {
      service: "footer.services.hourlyApartments",
      path: PageRoutesConstant.Page.HourlyApartments.path,
    },
    {
      service: "footer.services.hourlyHouseRental",
      path: PageRoutesConstant.Page.HourlyHouseRental.path,
    },
  ];

  return (
    <Footer className="footer">
      <Container>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={8} className="mb-3">
            <Title level={5} className="footer-title">
              {t("footer.titles.language_settings")}
            </Title>
            <Divider className="my-2 border-white" />
            <Space direction="vertical">
              <LanguageDropdown />
              <CurrencyDropdown />
            </Space>
          </Col>

          <Col xs={24} md={8}>
            <Title level={5} className="footer-title">
              {t("footer.titles.discover")}
            </Title>
            <Divider className="my-2 border-white" />
            <Space
              direction="vertical"
              size="small"
              className="footer-vertical-list"
            >
              {discoverData.map((discover, i) => (
                <div
                  key={`discover_${i}`}
                  className="footer-vertical-list-item"
                  onClick={() => navigate(discover.path)}
                >
                  {t(discover.name)}
                </div>
              ))}
            </Space>
          </Col>

          <Col xs={24} md={8}>
            <Title level={5} className="footer-title">
              {t("footer.titles.company")}
            </Title>
            <Divider className="my-2 border-white" />
            <Space
              direction="vertical"
              size="small"
              className="footer-vertical-list"
            >
              {companyData.map((company, i) => (
                <div
                  key={`company_${i}`}
                  className="footer-vertical-list-item"
                  onClick={() => navigate(company.path)}
                >
                  {t(company.name)}
                </div>
              ))}
            </Space>
          </Col>
        </Row>
        <Divider className="my-2 border-white" />
        <Row>
          <Col xs={24}>
            <Title level={5} className="footer-title my-2 text-center">
              {t("footer.titles.join_us_on")}
            </Title>

            <Row gutter={[10, 0]} className="justify-content-center mb-5">
              {socialIconsData.map((icon, i) => (
                <Col key={`icon_${i}`}>
                  <a href={icon.href} target="_blank" rel="noopener noreferrer">
                    <div className="social-icon-wrapper">
                      <i className={icon.icon}></i>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Title level={5} className=" footer-title text-center">
              {t("footer.titles.ourCurrentCities")}
            </Title>
            <Divider className="my-2 border-white" />
            <Space
              direction="horizontal"
              size="small"
              split="|"
              className="footer-horizontal-list"
            >
              {currentCitiesData.map((city, i) => (
                <Link
                  key={`$city_${i}`}
                  className="footer-horizontal-list-item"
                  to={PageRoutesConstant.Page.SpaceCity.path.replace(
                    ":city",
                    city.path
                  )}
                >
                  {city.city}
                </Link>
              ))}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Title level={5} className=" footer-title text-center">
              {t("footer.titles.ourServiceSectors")}
            </Title>
            <Divider className="my-2 border-white" />
            <Space
              direction="horizontal"
              size="small"
              split="|"
              className="footer-horizontal-list mb-3"
            >
              {serviceSectorData.map((service, i) => (
                <Link
                  key={`service_${i}`}
                  to={service.path}
                  rel="noreferrer"
                  className="footer-horizontal-list-item"
                >
                  {t(service.service)}
                </Link>
              ))}
            </Space>
          </Col>
        </Row>

        <div className="footer-copy-rights">
          Developed by
          <Link
            to={PageRoutesConstant.Page.Home.path}
            target="_blank"
            rel="noreferrer"
            className="ms-1"
          >
            HourlySpaces.com
          </Link>
        </div>
      </Container>
    </Footer>
  );
};
export default MainFooter;
