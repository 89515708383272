import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const RentTheater: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("rentTheaters")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Theater rentals are available all across the country. Whether you’re
            interested in renting a theater for a business meeting or a party,
            there are rental properties readily accessible for any occasion.
            Renting a theater, however, has been known to be expensive. Hourly
            spaces is a website that can be used to search for theater rentals
            for a fraction of the cost of others. Why? Because we offer theater
            rental prices based on the hour, day, week or month. So, if you’re
            only interested in renting a theater for 3 hours, why should you
            have to pay for the whole day? At Hourly spaces, we believe that 21
            hours of unused space doesn’t make sense—therefore, we work to save
            you time and stay within your budget while still getting the best
            possible rental property.
          </Paragraph>
          <Paragraph>
            When you go to certain theaters, they often project on the screen
            the availability to rent the space. At Hourly spaces, you can rent
            this space without directly going to the theater company. When you
            search our website, we offer specific criteria that can help you
            discover the possibilities of finding the perfect theater rental in
            the area you desire.
          </Paragraph>
          <Paragraph>
            Companies offering grand proposals often don’t have the space that a
            theater offers. And what better way to showcase your project than on
            the grand screen? That’s why renting theaters makes sense for large
            presentations—and much more.
          </Paragraph>
          <Paragraph>
            Hourly spaces will work to find you the best theater rental in your
            area. We want to make sure that you’re getting the best rental
            property in the area, that’s why we list the best that are available
            to us to get them directly to you! So, the next time you need to
            rent a theater, consider using our easy to navigate website in order
            to display your project on the big screen and capture a large
            audience. We want you to succeed, and your successful business
            starts with the best rental property.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default RentTheater;
