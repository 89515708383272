import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const ShortTermApartmentRentals: FC = () => {
  return (
    <>
      <Helmet>
        <title>Short Term Apartment rentals</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Short Term Apartment rentals
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                For many traveling families, renting an entire house as opposed
                to staying in a hotel for a short period of time is more
                practical in regards to budgeting and feeling in an “at home”
                environment. Having a full sized hotel suite with all the
                amenities that you are used to gives you the freedom to go about
                your trip and make sure your entire family is having a home away
                from home experience. Our hotel suites give the feeling of your
                own home comforts while you’re away from your home!
              </Paragraph>
              <Paragraph>
                Likewise, we believe that there is something more magical when
                you can do a short-term apartment rental. Why? Because it places
                all the same at home benefits into a more tight-knit community.
                Staying in an apartment temporarily is a great way to get to
                know the people from the neighborhood and meet new friends,
                giving you the opportunity to learn about the culture and
                lifestyle of all the new people around you. This is the reason
                we exist, to offer you the best short-term apartment rentals
                around the Washington DC and New York areas, so that you can
                enjoy your travel to the fullest.
              </Paragraph>
              <Paragraph>
                Our goal is to give you the finest facility that matches your
                needs and budget. Our services are fast, reliable, and timely.
                It is our greatest pleasure to assist you in finding an
                accommodation that you are 100% confident in. We consistently
                address any requirements or demands that you may have, as we are
                keen to providing the best information possible. Our website has
                complete features such as virtual tours which can guide you to
                picking the perfect apartment based on your needs.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ShortTermApartmentRentals;
