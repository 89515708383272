import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const ConferenceRoomsMeetingRooms: FC = () => {
  return (
    <>
      <Helmet>
        <title>Conference Rooms and Meeting Rooms</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Conference Rooms and Meeting Rooms
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                You can always count on us for your meeting space needs! Our
                on-site facilities and services are perfectly suited for your
                company. We understand that today’s system of work has evolved.
                There is now freedom to work anywhere and at any time.
                Certainly, technology has enabled people to do business online
                with others who are miles away.
              </Paragraph>
              <Paragraph>
                Nonetheless, we know that once in awhile collaboration in person
                has to happen. We understand that there is a need to connect and
                join forces outside the home and office to produce efficient and
                effective outcomes. This is where we come in, by providing the
                best meeting space for you. We have a selection of spaces that
                come in all sizes. From small group meeting consisting of less
                than five individuals up to large spacious meeting spaces that
                can accommodate more than fifty people.
              </Paragraph>
              <Paragraph>
                We always ensure that we are updated with the newest trends when
                it comes to our office spaces and conference rooms. We keep our
                facilities fresh, modern, and that our buildings follow the
                current state of the art technology.
              </Paragraph>
              <Paragraph>
                We accommodate meetings according to your specific needs and
                financial plan. You can rent our meeting space or conference
                rooms by the hour, half-day, full day or even longer. We have
                complete services that are perfect for your corporations,
                establishments. Government and non-government assemblies, and
                networking groups who want a convenient, affordable meeting
                place to rent.
              </Paragraph>
              <Paragraph>
                Our meeting spaces and conference rooms feature top-notch
                services that let your company experience the finer things in
                life. Here you can effectively conduct meetings, group
                discussions, and breakout sessions, as our spaces offer not just
                space, but flexibility as well.
              </Paragraph>
              <Paragraph>
                We will make sure that your meeting requirements are well
                covered with a range of facilities such as White boards, LCD
                projectors, TV screens, Projector clicker, Basic meeting Wi-Fi,
                Flip chart & markers, Overflowing coffee/tea.
              </Paragraph>
              <Paragraph>
                On site staff is available to assist and discuss with you
                anything you need to know during your meeting. We can help you
                with your LCD projector, monitors, and more.
              </Paragraph>
              <Paragraph>
                Our company has a strong track record of handling small and
                large meetings. We take pride of our award winning staff members
                who help ensure that your group or company can conduct your
                meeting smoothly and stress free. We are proud that we are
                founded on the principle of providing quality service to
                everyone. Undoubtedly our commitment to quality service is in
                our bloodline and it is the main reason why our business
                continues to progress. Certainly, with any conference spaces you
                find on HourlySpaces in the Washington DC and NYC areas will
                meet your standards and help you reach the objectives of your
                meeting relaxed and in a stress free environment.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ConferenceRoomsMeetingRooms;
