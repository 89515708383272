import { FC } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Form, Row, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import GMPAutocomplete from "../../../../common/components/autocomplete/google-maps-place-autocomplete/GMPAutocomplete";
import { useNavigate } from "react-router-dom";
import { SearchRoutesConstant } from "../../../../common/router/components/SearchRoutes";

const { Paragraph } = Typography;

const YourTrips: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      form.setFieldsValue({ lat: lat });
      form.setFieldsValue({ lng: lng });
    }
    form.setFieldsValue({ location: place.formatted_address || place.name });
  };

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
    navigate(
      `${SearchRoutesConstant.Search.Space.path}?location=${values.location}`
    );
  };

  return (
    <>
      <Helmet>
        <title>Your Trips</title>
      </Helmet>
      <CardLayout title="Your Trips" containerClassName="p-0">
        <Row gutter={[12, 12]}>
          <Col xs={24}>
            <Paragraph>You have no upcoming trips.</Paragraph>
          </Col>
          <Col xs={24} md={18}>
            <Form form={form} onFinish={onFinish}>
              <Row gutter={[12, 12]}>
                <Col xs={24} md={18} lg={12}>
                  <Form.Item
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Please set location",
                      },
                    ]}
                    className="mb-0"
                  >
                    <GMPAutocomplete
                      onPlaceSelected={handlePlaceSelected}
                      placeholder="Where are you going?"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6} lg={12}>
                  <Button type="primary" size="large" htmlType="submit">
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardLayout>
    </>
  );
};
export default YourTrips;
