import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Language } from "../enums/Language";

// Import translation files
import enTranslation from "../locales/english.json";
import frTranslation from "../locales/french.json";
import grTranslation from "../locales/german.json";
import itTranslation from "../locales/italian.json";
import poTranslation from "../locales/portuguese.json";
import spTranslation from "../locales/spanish.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      gr: {
        translation: grTranslation,
      },
      it: {
        translation: itTranslation,
      },
      po: {
        translation: poTranslation,
      },
      sp: {
        translation: spTranslation,
      },
    },
    fallbackLng: Language.English, // fallback language if the current language doesn't have a translation
    supportedLngs: [
      Language.English,
      Language.French,
      Language.Italian,
      Language.German,
      Language.Portuguese,
      Language.Spanish,
    ], // Supported languages
    detection: {
      order: [
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
      ], // Detection order
      caches: ["cookie"], // Use cookies to cache the language setting
      cookieOptions: { path: "/", sameSite: "strict" }, // Cookie settings
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
