import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const ShortTermRoomRentals: FC = () => {
  return (
    <>
      <Helmet>
        <title>Short Term Room Rentals</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Short Term Room Rentals
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                In today’s economy people are able to travel now more easily
                than ever before. The growing economy and cheaper airline
                tickets offer endless possibilities to most people. This is
                where we see our company providing the best in quality and
                prices for short-term rentals to everyone.
              </Paragraph>
              <Paragraph>
                We no longer only cater to businessmen and women running between
                flights and meetings, but we are catering to the all the
                travelers seeking out adventures as well. Families, couples, and
                individuals look for meaningful experiences and escapades on
                budgeted trips. This is why we strive to have our
                accommodations, whether short or long-term rentals, affordable.
                We equally value your trip so we ensure that you get what you
                deserve at the most reasonable price for your accommodation.
              </Paragraph>
              <Paragraph>
                We offer spacious, fully furnished spaces for your short stay.
                Here you have all the space you need, flexibility within the
                rental times, quality furniture, full-size kitchens completely
                stocked with all the needed kitchenware. We also include
                Internet access, local phones, and basic cable television.
                Certainly, every guest in our facilities is able to feel at
                home!
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ShortTermRoomRentals;
