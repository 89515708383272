import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { spaceCityData } from "./SpaceCity.constant";
import { SearchRoutesConstant } from "../../common/router/components/SearchRoutes";
import "./SpaceCity.style.scss";

const { Title, Text } = Typography;

const SpaceCity: FC = () => {
  const [cityData, setCityData] = useState<any>();
  const { city } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const findCity = spaceCityData.find((item) => item.city === city);
    setCityData(findCity);
  }, [city]);

  return (
    <div className="space-city">
      <div
        className="city-banner"
        style={{ backgroundImage: `url(${cityData?.bannerImage})` }}
      >
        <Container>
          <Row justify="center" align="middle" className="w-100">
            <Col lg={18} className="text-center">
              <Title level={1} className="text-wrapper">
                {cityData?.city}
              </Title>
              <Title level={5} className="text-wrapper">
                {cityData?.description}
              </Title>
              <Button
                type="primary"
                size="large"
                className="mt-3"
                onClick={() =>
                  navigate(
                    `${SearchRoutesConstant.Search.Space.path}?location=${cityData?.city}`
                  )
                }
              >
                {cityData?.searchFor}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col xs={24} className="py-4 city-section">
          <Container>
            <Row gutter={[12, 12]}>
              <Col xs={24} md={8}>
                <Space direction="vertical">
                  <Title level={5} className="mb-0">
                    Get around with:
                  </Title>
                  <Space split="," size={2}>
                    {cityData &&
                      cityData?.getAroundWith.map((item: string, i: number) => (
                        <Text key={`get_around_with${i}`}>{item}</Text>
                      ))}
                  </Space>
                </Space>
              </Col>
              <Col xs={24} md={8}>
                <Space direction="vertical">
                  <Title level={5} className="mb-0">
                    Awesome places to rent!
                  </Title>
                  <Link
                    to={`${SearchRoutesConstant.Search.Space.path}?location=${cityData?.city}`}
                    className="link"
                    target="_blank"
                  >
                    {cityData?.awesomePlacesToRent} <DoubleRightOutlined />
                  </Link>
                </Space>
              </Col>
              <Col xs={24} md={8}>
                <Space direction="vertical">
                  <Title level={5} className="mb-0">
                    Known For:
                  </Title>
                  <Text>{cityData?.knownFor}</Text>
                </Space>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs={24} className="text-center my-5">
          <Container>
            <Space direction="vertical">
              <Title level={3}>Services available in San Francisco</Title>
              <Text>{cityData?.knownFor}</Text>
            </Space>
          </Container>
        </Col>
        <Divider />
        <Col xs={24}>
          <Container>{/* TODO: Space List */}</Container>
        </Col>
        <Divider />
        <Col xs={24} className="text-center mb-5">
          <Container>
            <Button
              type="primary"
              size="large"
              onClick={() =>
                navigate(
                  `${SearchRoutesConstant.Search.Space.path}?location=${cityData?.city}`
                )
              }
            >
              {cityData?.seeAll} <DoubleRightOutlined />
            </Button>
          </Container>
        </Col>
      </Row>
    </div>
  );
};
export default SpaceCity;
