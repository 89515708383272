import { FC } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Row, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const IncomingReservations: FC = () => {
  return (
    <>
      <Helmet>
        <title>My Reservations</title>
      </Helmet>
      <CardLayout title="My Reservations" containerClassName="p-0">
        <Row gutter={[12, 12]}>
          <Col xs={24}>
            <Paragraph className="mb-0">You have no reservations.</Paragraph>
          </Col>
          <Col xs={24}>
            <Button type="primary" size="large">
              Create a new listing
            </Button>
          </Col>
        </Row>
      </CardLayout>
    </>
  );
};
export default IncomingReservations;
