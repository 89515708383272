import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import countryData from "../../../../common/constant/CountryName.json";

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;
type Country = {
  value: string;
  label: string;
};

const PayoutPreferences: FC = () => {
  const [addPayoutMethod, setAddPayoutMethod] = useState<boolean>(false);
  const [changeDefault, setChangeDefault] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<string>("none");
  const [payoutPreferenceValue, setPayoutPreferenceValue] = useState<
    any | undefined
  >(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const steps = [
    {
      title: "Step 1",
      content: <SelectCountry form={form} />,
    },
    {
      title: "Step 2",
      content: <PaymentMethod form={form} />,
    },
    {
      title: "Step 3",
      content: <PaymentInformation form={form} />,
    },
  ];

  const defaultOption = useMemo(
    () => [
      { value: "none", label: "None" },
      { value: "Paypal", label: "Paypal" },
      { value: "Paypal", label: "Paypal" },
      { value: "Paypal", label: "Paypal" },
    ],
    []
  );

  useEffect(() => {
    console.log(">>>>>>", payoutPreferenceValue);
  }, [payoutPreferenceValue]);

  const next = () => {
    setCurrentStep((prev) => prev + 1);
    if (currentStep === 0) {
      setPayoutPreferenceValue({ country: form.getFieldValue("country") });
    } else {
      setPayoutPreferenceValue({
        ...payoutPreferenceValue,
        paymentMethod: form.getFieldValue("paymentMethod"),
      });
    }
  };

  const onFinish = (values: any) => {
    if (values) {
      setPayoutPreferenceValue({
        ...payoutPreferenceValue,
        currency: values.currency,
        paypalEmail: values.paypalEmail,
      });
      setAddPayoutMethod(false);
      setCurrentStep(0);
    }
  };

  const handleResetField = () => {
    setAddPayoutMethod(false);
    setCurrentStep(0);
    setPayoutPreferenceValue(undefined);
  };

  const setDefault = () => {
    console.log(defaultValue);
  };

  return (
    <>
      <Helmet>
        <title>Your Payment Method Details</title>
      </Helmet>
      <CardLayout
        title={t("account.payout.payoutMethod")}
        containerClassName="p-0"
      >
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <Paragraph>{t("account.payout.noPayoutMessage")}</Paragraph>
            </Col>
            <Col xs={24} className="mt-2">
              {!addPayoutMethod ? (
                <Space>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      setAddPayoutMethod(true);
                      setChangeDefault(false);
                    }}
                    className="text-uppercase"
                  >
                    {t("account.payout.addPayoutMethod")}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => setChangeDefault(!changeDefault)}
                    className="text-uppercase"
                  >
                    {changeDefault
                      ? t("account.payout.don'tChangeDefault")
                      : t("account.payout.changeDefault")}
                  </Button>
                </Space>
              ) : (
                <Row gutter={[12, 12]}>
                  <Col xs={24}>{steps[currentStep].content}</Col>
                  <Col xs={24}>
                    <Space split="or">
                      {currentStep < steps.length - 1 && (
                        <Button type="primary" onClick={next} size="large">
                          Next
                        </Button>
                      )}
                      {currentStep === steps.length - 1 && (
                        <Button type="primary" htmlType="submit" size="large">
                          Save
                        </Button>
                      )}
                      <Link to="" onClick={handleResetField} className="link">
                        Cancel
                      </Link>
                    </Space>
                  </Col>
                </Row>
              )}
            </Col>

            {changeDefault && (
              <Col xs={24} md={12}>
                <Row gutter={[12, 12]}>
                  <Col xs={14}>
                    <Select
                      value={defaultValue}
                      size="large"
                      onChange={(event) => setDefaultValue(event)}
                      className="w-100"
                    >
                      {defaultOption.map((option) => (
                        <Option key={option.label} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={10}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={setDefault}
                      className="text-uppercase"
                    >
                      Set Default
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Form>
      </CardLayout>
    </>
  );
};

const SelectCountry: FC<{ form: any }> = ({ form }) => {
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    setCountries(countryData);
    form.setFieldsValue({ country: countryData[0]?.value });
  }, [form]);

  return (
    <Row>
      <Col xs={24}>
        <Paragraph>
          Our ability to pay you depends on your country of residence:
        </Paragraph>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item name="country">
          <Select showSearch size="large" className="w-100">
            {countries.map((item, i) => (
              <Option key={i} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const PaymentMethod: FC<{ form: any }> = ({ form }) => {
  const findSelectCountry = countryData.find(
    (item, i) => item.value === form.getFieldValue("country")
  );

  useEffect(() => form.setFieldsValue({ paymentMethod: 2 }), [form]);

  const dataSource = [
    {
      key: "1",
      method: "Paypal",
      arrivesOn: "Instant",
      fees: "None",
      notes: `${findSelectCountry?.label} You can withdraw money from PayPal.. to your local bank account. via paper check.`,
    },
    {
      key: "2",
      method: "CreditCard",
      arrivesOn: "Instant",
      fees: "None",
      notes: `${findSelectCountry?.label} Contact Administrator of your local bank account via paper check.`,
    },
    {
      key: "3",
      method: "By check",
      arrivesOn: "Instant",
      fees: "None",
      notes: `${findSelectCountry?.label} You can withdraw money from Check to your local bank account or via paper check.`,
    },
  ];

  const columns = [
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Arrives On",
      dataIndex: "arrivesOn",
      key: "arrivesOn",
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  const paymentOptions = useMemo(
    () => [
      { value: 2, label: "Paypal" },
      { value: 1, label: "CreditCard" },
      { value: 4, label: "By check" },
    ],
    []
  );

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <Paragraph className="mb-0">
          We can send money to users in
          <Text strong> {findSelectCountry?.label}</Text> as follows:
        </Paragraph>
      </Col>
      <Col xs={24}>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowHoverable={false}
          pagination={false}
          scroll={{ x: "auto" }}
        />
        <Paragraph>
          <small>
            Money is always released the day after check in but may take longer
            to arrive to you.
          </small>
        </Paragraph>
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={[12, 12]}>
          <Col xs={12}>
            <Paragraph className="mb-0">
              What method would you prefer?
            </Paragraph>
          </Col>
          <Col xs={12}>
            <Form.Item name="paymentMethod">
              <Select size="large" className="w-100">
                {paymentOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const PaymentInformation: FC<{ form: any }> = ({ form }) => {
  const currencyOptions = useMemo(
    () => [
      { value: "USD", label: "USD" },
      { value: "GBP", label: "GBP" },
      { value: "EUR", label: "EUR" },
      { value: "AUD", label: "AUD" },
      { value: "SGD", label: "SGD" },
      { value: "SEK", label: "SEK" },
      { value: "DKK", label: "DKK" },
      { value: "MXN", label: "MXN" },
      { value: "BRL", label: "BRL" },
      { value: "MYR", label: "MYR" },
      { value: "PHP", label: "PHP" },
      { value: "CHF", label: "CHF" },
    ],
    []
  );

  useEffect(() => {
    form.setFieldsValue({ currency: currencyOptions[0].value });
  });
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <Title level={5}>
          {form.getFieldValue("paymentMethod") === 2
            ? "Paypal "
            : form.getFieldValue("paymentMethod") === 1
            ? "CreditCard "
            : "By check "}
          Information
        </Title>
        <Divider className="my-0" />
      </Col>
      {form.getFieldValue("paymentMethod") === 2 ? (
        <>
          <Col xs={24} md={14}>
            <Row>
              <Col xs={12}>
                <Paragraph>To what e-mail should we send the money?</Paragraph>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="paypalEmail"
                  rules={[
                    { required: true, message: "You must enter the email-id" },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Paragraph className="mb-0">
                  <small>
                    This email address must be associated with a valid Paypal
                    account.
                  </small>
                </Paragraph>
                <Link
                  to="https://www.paypal.com/us/welcome/signup/#/country_selection"
                  target="_blank"
                  className="link"
                >
                  Don't have a paypal account?
                </Link>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={14}>
            <Row>
              <Col xs={12}>
                <Paragraph>
                  In what currency would you like to be paid?
                </Paragraph>
              </Col>
              <Col xs={12}>
                <Form.Item name="currency">
                  <Select size="large" className="w-100">
                    {currencyOptions.map((currency) => (
                      <Option key={currency.value} value={currency.value}>
                        {currency.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      ) : (
        <Paragraph>
          We should send the Payout money using
          {form.getFieldValue("paymentMethod") === 1
            ? " CreditCard "
            : " By check "}
          options
        </Paragraph>
      )}
    </Row>
  );
};

export default PayoutPreferences;
