import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const HourlySpaces: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hourly Spaces</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Hourly Spaces
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Hourly space rentals give you the quality and amenities needed
                for even shorter stays. Our objective is to cater to travelers,
                business people or anyone who is looking to take a hot shower,
                change clothes, and freshen up before running to their next
                meeting, flight, etc.
              </Paragraph>
              <Paragraph>
                Previously, the stereotypes for hourly space rentals are
                negative, as some people believe that this business concept is
                only used for unsavory activities. However, hourly space rentals
                have been thriving off of traveling businessmen and women for
                years. With the number of jetsetters rapidly growing over the
                past few years, hourly rental services have boomed.
              </Paragraph>
              <Paragraph>
                Washington DC and New York have the top two highest prices for
                lodging during a business trip in the US, averaging a bit under
                $200 per night (main street). Hourly space rentals can save you
                cash fast, at HourlySpaces.com our spaces start at $15 per hour,
                with a 7-hour booking, you could save almost half the cost of
                booking a full night in a hotel. We know that we have to act
                fast to catch up with the growing economy and address the needs
                of the people. Many of us are always looking for a way to ease
                our expenses while travelling. Why should we pay for a one-day
                accommodation at a hotel when we can have access to hourly room
                rentals? Hourly room rentals are successful in that they are
                very practical and budget friendly.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default HourlySpaces;
