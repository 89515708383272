import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../../components/profile-layout/ProfileLayout";
import Inbox from "../../../pages/user/message/Inbox";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Message: RouteKey;
}

const MessageBaseRoutesConstant: IRoute = {
  Message: {
    Base: {
      path: "/message",
    },
  },
};

export const MessageRoutesConstant: IRoute = {
  Message: {
    Inbox: {
      path: `${MessageBaseRoutesConstant.Message.Base.path}/inbox`,
      element: <Inbox />,
    },
  },
};

const MessageRoutes = () => {
  return (
    <Routes>
      {Object.keys(MessageRoutesConstant.Message).map((key) => {
        const route = MessageRoutesConstant.Message[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              MessageBaseRoutesConstant.Message.Base.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const MessageFlowRoutes: FC = () => {
  return (
    <ProfileLayout>
      <MessageRoutes />
    </ProfileLayout>
  );
};

export default MessageFlowRoutes;
