import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import CommonLayout from "../../components/common-layout/CommonLayout";
import Home from "../../../pages/home/Home";
import SignUp from "../../../pages/sign-up/SignUp";
import SignIn from "../../../pages/sign-in/SignIn";
import HowItWork from "../../../pages/how-it-work/HowItWork";
import WhyHost from "../../../pages/why-host/WhyHost";
import ContactUs from "../../../pages/contact-us/ContactUs";
import FAQ from "../../../pages/faq/FAQ";
import TermsOfService from "../../../pages/terms-of-service/TermsOfService";
import PrivacyPolicy from "../../../pages/privacy-policy/PrivacyPolicy";
import ShortTermRental from "../../../pages/short-term-rental/ShortTermRental";
import WhyRent from "../../../pages/why-rent/WhyRent";
import HourlyMeetingRoom from "../../../pages/hourly-meeting-room/HourlyMeetingRoom";
import HourlyConferenceRooms from "../../../pages/hourly-conferance-rooms/HourlyConferenceRooms";
import HourlyEventSpaceRental from "../../../pages/hourly-event-space-rental/HourlyEventSpaceRental";
import HourlyOfficeSpaceRental from "../../../pages/hourly-office-space-rental/HourlyOfficeSpaceRental";
import Careers from "../../../pages/careers/Careers";
import WeddingVenueRental from "../../../pages/wedding-venue-rental/WeddingVenueRental";
import PartyVenueRental from "../../../pages/party-venue-rental/PartyVenueRental";
import HourlyHotels from "../../../pages/hourly-hotels/HourlyHotels";
import RentPentHouse from "../../../pages/rent-pent-house/RentPentHouse";
import RentPracticeRooms from "../../../pages/rent-practice-rooms/RentPracticeRooms";
import RentTheater from "../../../pages/rent-theater/RentTheater";
import HourlyApartments from "../../../pages/hourly-apartments/HourlyApartments";
import HourlyHouseRental from "../../../pages/hourly-house-rental/HourlyHouseRental";
import NeighborHoods from "../../../pages/neighbor-hoods/NeighborHoods";
import CancellationPolicies from "../../../pages/cancellation-policies/CancellationPolicies";
import SpaceDetails from "../../../pages/space-details/SpaceDetails";
import SpaceCity from "../../../pages/space-city/SpaceCity";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Page: RouteKey;
}

export const PageRoutesConstant: IRoute = {
  Page: {
    Home: {
      path: "/",
      element: <Home />,
    },
    SignUp: {
      path: "/signup",
      element: <SignUp />,
    },
    SignIn: {
      path: "/signin",
      element: <SignIn />,
    },
    HowItWork: {
      path: "/how-it-works",
      element: <HowItWork />,
    },
    WhyHost: {
      path: "/why-host-with-hourly-spaces",
      element: <WhyHost />,
    },
    ShortTermRental: {
      path: "/short-term-rental",
      element: <ShortTermRental />,
    },
    WhyRent: {
      path: "/why-rent-with-hourly-spaces",
      element: <WhyRent />,
    },
    HourlyMeetingRoom: {
      path: "/rent-hourly-meeting-room",
      element: <HourlyMeetingRoom />,
    },
    HourlyConferenceRooms: {
      path: "/rent-hourly-conference-room",
      element: <HourlyConferenceRooms />,
    },
    HourlyEventSpaceRental: {
      path: "/rent-hourly-event-space",
      element: <HourlyEventSpaceRental />,
    },
    HourlyOfficeSpaceRental: {
      path: "/hourly-office-space-rental",
      element: <HourlyOfficeSpaceRental />,
    },
    WeddingVenueRental: {
      path: "/hourly-wedding-venue-rental",
      element: <WeddingVenueRental />,
    },
    PartyVenueRental: {
      path: "/rent-party-venue-by-the-hour",
      element: <PartyVenueRental />,
    },
    HourlyHotels: {
      path: "/hourly-hotel-rooms",
      element: <HourlyHotels />,
    },
    RentPentHouse: {
      path: "/hourly-pent-house-luxury-rental",
      element: <RentPentHouse />,
    },
    RentPracticeRooms: {
      path: "/rent-practice-rooms-by-the-hour",
      element: <RentPracticeRooms />,
    },
    RentTheater: {
      path: "/private-theater-hourly-rental",
      element: <RentTheater />,
    },
    HourlyApartments: {
      path: "/hourly-apartment-rental",
      element: <HourlyApartments />,
    },
    HourlyHouseRental: {
      path: "/hourly-house-rental",
      element: <HourlyHouseRental />,
    },
    NeighborHoods: {
      path: "/neighborhoods",
      element: <NeighborHoods />,
    },
    Blog: {
      path: "/blog",
    },
    ContactUs: {
      path: "/contact",
      element: <ContactUs />,
    },
    Faq: {
      path: "/faq",
      element: <FAQ />,
    },
    TermsOfService: {
      path: "/tos",
      element: <TermsOfService />,
    },
    PrivacyPolicy: {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    Careers: {
      path: "/careers",
      element: <Careers />,
    },
    CancellationPolicies: {
      path: "/cancellation-policy/:cancellation?",
      element: <CancellationPolicies />,
    },
    SpaceDetails: {
      path: "/space-details",
      element: <SpaceDetails />,
    },
    SpaceCity: {
      path: "/:city",
      element: <SpaceCity />,
    },
  },
};

const PageRoutes: FC = () => {
  return (
    <Routes>
      {Object.keys(PageRoutesConstant.Page).map((key) => {
        const pageItem = PageRoutesConstant.Page[key];
        return (
          <Route key={key} path={pageItem.path} element={pageItem.element} />
        );
      })}
    </Routes>
  );
};

const CommonFlowRoutes: FC = () => {
  return (
    <CommonLayout>
      <PageRoutes />
    </CommonLayout>
  );
};

export default CommonFlowRoutes;
