import { Language } from "../../enums/Language";

export const languageData = [
  { code: Language.English, name: "English" },
  { code: Language.French, name: "French" },
  { code: Language.Italian, name: "Italian" },
  { code: Language.German, name: "German" },
  { code: Language.Portuguese, name: "Portuguese" },
  { code: Language.Spanish, name: "Spanish" },
];
