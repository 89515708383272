import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Image,
  Rate,
  Row,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { MailOutlined } from "@ant-design/icons";
import useResponsive from "../../../hooks/useResponsive";
import CardLayout from "../../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../../common/router/components/PageRoutes";
import { ProfileRoutesConstant } from "../../../common/router/components/UserRoutes";
import { useAuth } from "../../../common/components/authContext/AuthContext";
import "./Profile.style.scss";

const { Title, Paragraph, Text } = Typography;

const Profile: FC = () => {
  const { user } = useAuth();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { name } = useParams();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs.utc());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const spaceList = [
    {
      id: "1",
      name: "Day Pass - Coworking Gallery Desks",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/567/7244b962f1f2ceb224bb5da9a3a57a7e.jpg",
    },
    {
      id: "2",
      name: "Hourly Day Office for 2",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/563/9221b3732e60f45572a010695159f60e.jpg",
    },
    {
      id: "3",
      name: "Hourly Hudson Conference Room",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/568/a85b0da757da24ab46f0e19870d90c25.jpg",
    },
    {
      id: "4",
      name: "Hourly Sullivan Conference Room",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/572/72235bb6d8e54f5ff626d7f927f3857d.jpg",
    },
    {
      id: "5",
      name: "Hourly Thompson Conference Room",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/573/bbc76ae46483d46cf0e37a33b8fca8dc.jpg",
    },
    {
      id: "6",
      name: "Private Office for 3",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/563/9221b3732e60f45572a010695159f60e.jpg",
    },
    {
      id: "7",
      name: "Private Office for 2 - Monthly Minimum",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/568/a85b0da757da24ab46f0e19870d90c25.jpg",
    },
    {
      id: "8",
      name: "Personal Workspace in shared office",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/572/72235bb6d8e54f5ff626d7f927f3857d.jpg",
    },
    {
      id: "9",
      name: "Private Office for 3 with Window",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/573/bbc76ae46483d46cf0e37a33b8fca8dc.jpg",
    },
    {
      id: "10",
      name: "Private Team Office for 5",
      address: "Broadway New York NY USA 10001",
      image:
        "https://www.hourlyspaces.com/images/563/9221b3732e60f45572a010695159f60e.jpg",
    },
  ];

  const ReviewData = [
    {
      profileImg:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCCLiyqsvcD1oXZq7IaSzwMTaU6j31ND-yCTyxVsd2HHGLCLm7GrIj-56I7u9G9ht5cLA&usqp=CAU",
      userName: "Globex Enterprises",
      space: { name: "Hourly Small Conference Room C", id: "3" },
      comment:
        "This space exceeded all of my expectations. From the moment I arrived, I was impressed by how clean and well-maintained everything was. The host was incredibly accommodating, making sure I had everything I needed for my stay. The location was perfect, with easy access to local amenities and transportation. The space itself was beautifully decorated, offering a comfortable and inviting atmosphere. I particularly appreciated the attention to detail in the amenities provided, such as high-quality linens, a fully stocked kitchen, and fast, reliable Wi-Fi. I was able to work remotely without any issues and still enjoy all the comforts of home. I will definitely be returning and highly recommend this space to anyone looking for a premium experience.",
      rate: 5,
      date: "October, 2021",
    },
    {
      profileImg:
        "https://t4.ftcdn.net/jpg/06/45/77/79/360_F_645777959_fNnaNoeVO4qxCNPW9MWr3gQlPFSGA9yL.jpg",
      userName: "Omega Solutions",
      space: { name: "Hourly Large Conference Room", id: "1" },
      comment:
        "Fantastic experience! The space was clean, well-organized, and exactly as described. Highly recommend!",
      rate: 2,
      date: "November, 2016",
    },
    {
      profileImg:
        "https://media.istockphoto.com/id/1437816897/photo/business-woman-manager-or-human-resources-portrait-for-career-success-company-we-are-hiring.jpg?s=612x612&w=0&k=20&c=tyLvtzutRh22j9GqSGI33Z4HpIwv9vL_MZw_xOE19NQ=",
      userName: "Alpha Partners",
      space: { name: "Hourly Small Conference Room B", id: "2" },
      comment:
        "Currently interning at NGIN. Love the staff, love the area and love the office at self. After getting to know the community as a whole, I come to work 5 days a week with a smile on my face. Both Olga and Joan (higher ups) doing an excellent job at accommodating new clients, as well as making the current clients feel cared for too. Events, events & events. They happen if not every week, but every other week. No one is ever left out. Love this place. I'm even trying to promote it to my university so that new alums can rent an office space here.",
      rate: 4,
      date: "March, 2012",
    },
  ];

  return (
    <div className="profile">
      <Helmet>
        <title>{user ? user.sub : name} | Hourly Spaces on Demand</title>
      </Helmet>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8} lg={6}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <CardLayout containerClassName="p-0">
                <Image
                  src="https://www.hourlyspaces.com/images/users/289/userpic_profile.jpg"
                  alt="image not found"
                  preview={false}
                />
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout
                containerClassName="p-0"
                title={
                  <Row justify="space-between">
                    <Col>
                      <Title level={4} className="mb-0">
                        Verifications
                      </Title>
                    </Col>
                    <Col>
                      <Button type="link" className="link">
                        Add More
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Row justify="space-between">
                  <Col>
                    <Space direction="vertical" size={1}>
                      <Title level={5} className="mb-0">
                        Email
                      </Title>
                      <Title level={5} className="mb-0">
                        Verified
                      </Title>
                    </Space>
                  </Col>
                  <Col>
                    <MailOutlined />
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout
                containerClassName="p-0"
                title={
                  <Title level={4} className="mb-0">
                    About Me
                  </Title>
                }
              >
                <Paragraph>
                  Whether you're looking for an executive office suite, meeting
                  room, phone answering service, or virtual office solution, we
                  can help you. Intelligent Office provides a new, smarter, and
                  more cost effective way of working and doing business.
                  Thousands of our clients have grown their business and reaped
                  benefits, including higher sales, better customer service,
                  reduced overhead costs, and improved work/life balance. Much
                  more than an executive or virtual office, we become an
                  extension of your business, helping you with the routine so
                  you can focus on the important. All of our a la carte services
                  are customized to your business needs - you pay only for what
                  you need! Intelligent Office of Boston offers 14 offices, all
                  with expansive windows, and some with views of Boston Harbor
                  from our 17th floor suite. We also offer three conference
                  rooms that can be configured in many ways to meet your meeting
                  or training needs. Our building has many amenities, including
                  24-hour secure access, high speed Internet, underground
                  parking, an on-site restaurant, and a prestigious address.
                </Paragraph>
              </CardLayout>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={16} lg={18}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <CardLayout containerClassName="p-0">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Title level={4}>
                      Hey, I'm Intelligentofficephiladelphia!
                    </Title>
                  </Col>

                  <Col xs={24} lg={10} xl={6} className="text-start text-lg-center">
                    <Title level={5}>
                      {currentTime.format("MMMM D, YYYY HH:mm:ss")}
                    </Title>
                  </Col>
                </Row>
                <Paragraph>- Member since June 2016</Paragraph>
                <Link
                  to={ProfileRoutesConstant.Users.EditProfile.path}
                  className="link"
                >
                  Edit Profile
                </Link>
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout title="My Listings" containerClassName="p-0">
                <Row>
                  {spaceList.map((item, i) => (
                    <Col
                      xs={24}
                      key={`my_list_item_${i}`}
                      className="listing-item"
                    >
                      <Space align="start">
                        <Image
                          src={item.image}
                          alt={item.name}
                          preview={false}
                          className="listing-image"
                          onClick={() =>
                            navigate(
                              PageRoutesConstant.Page.SpaceDetails.path,
                              {
                                state: { spaceId: item.id },
                              }
                            )
                          }
                        />
                        <div>
                          <Link
                            to={PageRoutesConstant.Page.SpaceDetails.path}
                            state={{ spaceId: item.id }}
                            className="link"
                          >
                            <Title level={5} className="link mb-0">
                              {item.name}
                            </Title>
                          </Link>
                          <Paragraph className="mb-0">{item.address}</Paragraph>
                        </div>
                      </Space>
                    </Col>
                  ))}
                </Row>
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout title="Reviews About You" containerClassName="p-0">
                {ReviewData.map((item, i) => (
                  <div className="review-wrapper" key={`review_list_${i}`}>
                    <Row gutter={[12, 8]}>
                      <Col xs={24}>
                        <Flex gap="small">
                          <div className="image-content">
                            <Avatar
                              shape="square"
                              size={50}
                              icon={
                                <Image src={item.profileImg} preview={false} />
                              }
                            />
                          </div>
                          <Row gutter={[12, 0]} className="w-100">
                            <Col xs={24}>
                              <Flex justify="space-between" wrap>
                                <Col>
                                  <Title
                                    level={5}
                                    className="mb-0 link"
                                    ellipsis={isMobile}
                                    onClick={() =>
                                      navigate(
                                        PageRoutesConstant.Page.SpaceDetails
                                          .path,
                                        {
                                          state: { spaceId: item.space.id },
                                        }
                                      )
                                    }
                                  >
                                    {item.space.name}
                                  </Title>
                                </Col>
                                <Col>
                                  <Text className="text-red">{item.date}</Text>
                                </Col>
                              </Flex>
                            </Col>
                            <Col xs={24}>
                              <Flex justify="space-between" wrap>
                                <Col>
                                  <Text className="text-red">
                                    {item.userName}
                                  </Text>
                                </Col>
                                <Col>
                                  <Rate disabled defaultValue={item.rate} />
                                </Col>
                              </Flex>
                            </Col>
                          </Row>
                        </Flex>
                      </Col>
                      <Col xs={24}>
                        <Paragraph className="mb-0 me-2">
                          {item.comment}
                        </Paragraph>
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                ))}
              </CardLayout>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={24}>
          <CardLayout title="References" containerClassName="p-0">
            <Paragraph>There is no Reference</Paragraph>
          </CardLayout>
        </Col>
      </Row>
    </div>
  );
};
export default Profile;
