import { FC } from "react";
import { Container, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Divider, Image, Row, Space, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { howItWorksData } from "./Home.constant";
import VideoAutoplay from "./video/VideoAutoPlay";
import SearchForm from "./search/SearchForm";
import CitiesSlider from "./slider/cities-slider/CitiesSlider";
import SpacesSlider from "./slider/spaces-slider/SpacesSlider";
import useResponsive from "../../hooks/useResponsive";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";
import "./Home.style.scss";

const { Title, Paragraph } = Typography;

const Home: FC = () => {
  return (
    <Stack direction="vertical" className="home">
      <div className="main-content">
        <VideoAutoplay />
        <div className="search-area">
          <SearchForm />
        </div>
      </div>
      <div className="how-it-work">
        <HowItWork />
      </div>
      <Divider />
      <CitiesSlider />
      <Divider />
      <SpacesSlider />
      <Divider />
      <SignUpBanner />
      <LearnMoreContact />
      <Divider />
      <HomeList />
    </Stack>
  );
};

const HowItWork: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row gutter={[24, 24]}>
        {howItWorksData.map((item, index) => (
          <Col xs={24} md={8} key={`hiw_img_${index}`}>
            <Space
              direction="vertical"
              size="large"
              align="center"
              className="w-100"
            >
              <div>
                <Image src={item.imageSrc} preview={false} className="image" />
              </div>
              <Space direction="vertical" align="center" className="w-100">
                <Title level={3} className="title">
                  {t(item.title)}
                </Title>
                <Paragraph className="description">
                  {t(item.description)}
                </Paragraph>
              </Space>
            </Space>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const SignUpBanner: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="signup-banner">
      <div className="content">
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Title level={1} className="title">
              {t("home.signUpBanner.title")}
            </Title>
          </Col>
          <Col xs={24}>
            <Button
              size="large"
              type="primary"
              className="text-uppercase sign-up"
              onClick={() => navigate(PageRoutesConstant.Page.SignUp.path)}
            >
              {t("home.signUpBanner.buttonText")}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const LearnMoreContact: FC = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container className="py-2">
      <Row align={isMobile ? "middle" : "stretch"} justify="space-between">
        <Col xs={24} md={10}>
          <Space
            direction="vertical"
            align="center"
            size="small"
            className="d-flex p-4"
          >
            <Title level={4} className="mb-0 text-center">
              {t("home.extra_space.title")}
            </Title>
            <Paragraph className="mb-2 text-center">
              {t("home.extra_space.description")}
            </Paragraph>
            <Button
              size="large"
              className="text-uppercase"
              onClick={() => navigate(PageRoutesConstant.Page.Faq.path)}
            >
              {t("home.extra_space.button_text")}
            </Button>
          </Space>
        </Col>
        <Col xs={24} md={4} className="text-center">
          <Divider
            type={isMobile ? "horizontal" : "vertical"}
            orientation="center"
            className="h-100 border-black"
          ></Divider>
        </Col>
        <Col xs={24} md={10}>
          <Space
            direction="vertical"
            align="center"
            size="small"
            className="d-flex p-4"
          >
            <Title level={4} className="mb-0 text-center">
              {t("home.get_in_touch.title")}
            </Title>
            <Paragraph className="mb-2 text-center">
              {t("home.get_in_touch.description")}
            </Paragraph>
            <Button
              size="large"
              className="text-uppercase"
              onClick={() => navigate(PageRoutesConstant.Page.ContactUs.path)}
            >
              {t("home.get_in_touch.button_text")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Container>
  );
};

const HomeList: FC = () => {
  const { t } = useTranslation();

  const homeListData = [
    {
      title: "home.homeList.travel.title",
      description: "home.homeList.travel.description",
      link: "home.homeList.travel.link",
      path: "/",
    },
    {
      title: "home.homeList.host.title",
      description: "home.homeList.host.description",
      link: "home.homeList.host.link",
      path: PageRoutesConstant.Page.WhyHost.path,
    },
    {
      title: "home.homeList.howItWorks.title",
      description: "home.homeList.howItWorks.description",
      link: "home.homeList.howItWorks.link",
      path: PageRoutesConstant.Page.HowItWork.path,
    },
  ];

  return (
    <Container className="home-list">
      <Row gutter={[24, 24]}>
        {homeListData.map((item, i) => (
          <Col xs={24} md={8} key={`list_${i}`} className="py-3 px-2 mb-3">
            <Title level={3}>{t(item.title)}</Title>
            <Paragraph>{t(item.description)}</Paragraph>
            <Link to={item.path} rel="noreferrer" className="link">
              {t(item.link)} {<ArrowRightOutlined />}
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
export default Home;
