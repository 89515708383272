import { FC, useEffect, useRef } from "react";
import "./VideoAutoPlay.style.scss";

const VideoAutoplay: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Set autoplay, loop, and controls attributes
      videoElement.autoplay = true;
      videoElement.loop = true;
      videoElement.controls = false;

      // Ensure video plays muted
      videoElement.muted = true;

      // Handle potential errors during playback
      videoElement.addEventListener("error", (error) => {
        console.error("Error playing video:", error);
      });

      return () => {
        // Clean up event listener
        videoElement.removeEventListener("error", (error) =>
          console.error(error)
        );
      };
    }
  }, []);

  return (
    <div className="video-container">
      {/* <video ref={videoRef}>
        <source
          src="C:/Users/Joe-PowerSpec/OneDrive/Desktop/JAVA/node-workspace/hourlyspaces-webapp/src/assets/videos/SanFrancisco-P1-1.mp4"
          type="video/mp4"
        />
        <source
          src="C:/Users/Joe-PowerSpec/OneDrive/Desktop/JAVA/node-workspace/hourlyspaces-webapp/src/assets/videos/SanFrancisco-P1-0.webm"
          type="video/webm"
        />
        Your browser does not support the video tag.
      </video> */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ width: "100%", alignContent: "center"}}
      >
        <source
          src="https://hourlyspaces.s3.us-east-1.amazonaws.com/videos/SanFrancisco-P1-1.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoAutoplay;
