import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Col, Form, Image, Input, Row, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import { ProfileRoutesConstant } from "../../../../common/router/components/UserRoutes";
import { useAuth } from "../../../../common/components/authContext/AuthContext";
import { passwordPatternRegex } from "../../../../common/utilities/validator";

const { Title, Text } = Typography;

const ChangePassword: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [form] = Form.useForm();

  const validateConfirmPassword = (_: any, value: string) => {
    if (value && value !== form.getFieldValue("new_password")) {
      return Promise.reject(new Error(t("confirmNewPassword.matchMessage")));
    }
    return Promise.resolve();
  };

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
  };

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8} lg={6}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <CardLayout containerClassName="p-0">
                <Row gutter={[12, 12]}>
                  <Col xs={24} className="text-center">
                    <Image
                      src="https://www.hourlyspaces.com/images/no_avatar-xlarge.jpg"
                      alt="image"
                      preview={false}
                      className="image-box"
                    />
                  </Col>
                  <Col xs={24} className="text-center">
                    <Title level={3} className="mb-0">
                      {user.sub}
                    </Title>
                  </Col>
                  <Col xs={24}>
                    <Link
                      to={ProfileRoutesConstant.Users.EditProfile.path}
                      className="link"
                    >
                      Edit Profile
                    </Link>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col xs={24}>
              <CardLayout title="Quick Links" containerClassName="p-0">
                <Row>
                  <Col xs={24}>
                    <Link to="" className="link">
                      View/Edit Listings
                    </Link>
                  </Col>
                  <Col xs={24}>
                    <Link to="" className="link">
                      Reservations
                    </Link>
                  </Col>
                </Row>
              </CardLayout>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={16} lg={18}>
          <CardLayout containerClassName="p-0">
            <Form form={form} layout="horizontal" onFinish={onFinish}>
              <Row justify="center">
                <Col xs={24} md={6}>
                  <Text>Old Password</Text>
                </Col>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="old_password"
                    rules={[
                      {
                        required: true,
                        message: t("oldPassword.requireMessage"),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("oldPassword.placeholder")}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Text>New Password</Text>
                </Col>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: t("newPassword.requireMessage"),
                      },
                      {
                        pattern: passwordPatternRegex,
                        message: t("newPassword.patternMessage"),
                        validateTrigger: ["onBlur", "onClick"],
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("newPassword.placeholder")}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Text>Confirm New Password</Text>
                </Col>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="confirm_new_password"
                    dependencies={["newPassword"]}
                    rules={[
                      {
                        required: true,
                        message: t("confirmNewPassword.requireMessage"),
                      },
                      {
                        validator: validateConfirmPassword,
                        validateTrigger: ["onBlur", "onClick"],
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("confirmNewPassword.placeholder")}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" size="large" block>
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardLayout>
        </Col>
      </Row>
    </>
  );
};
export default ChangePassword;
