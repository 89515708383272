import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";
import "./WhyRent.style.scss";

const { Paragraph, Title } = Typography;

const WhyRent: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("whyRent")} titleTextColor="red">
      <Row className="why-rent">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            Why find your next short term rental on HourlySpaces.com?
          </Title>
          <Paragraph>
            There are hosts in over 100 countries that open their private
            properties for business and to casual travelers from around the
            world in order for them to meet new people, get new business and
            make some extra cash.
          </Paragraph>
          <Title level={5}>
            Access to friendly spaces anywhere in the world
          </Title>
          <Paragraph>
            Our website has thousands of great and friendly hosts from around
            the world. Renting your next space on our website increases your
            chances to find the perfect space you're looking for and save $$.
          </Paragraph>
          <Title level={5}>
            $1 Million Insurance for every reservation
          </Title>
          <Paragraph>
            Your peace of mind is priceless. So we don't charge for it. Every
            single booking on HourlySpaces.com is covered by a 1 Million dollar
            Insurance - at no cost to you.
          </Paragraph>
          <Title level={5}>There is no service charge</Title>
          <Paragraph>
            There are no fees charged to list your property; only a 5% service
            fee is billed once a reservation is confirmed.
          </Paragraph>
          <Title level={5}>
            Communicating with potential hosts has never been easier
          </Title>
          <Paragraph>
            Communication is not only good for your hosts but also for you!
            Communicating with potential hosts gives you a good feel of the
            people you rent from. With HourlySpaces, you also have the option to
            view hosts’ profiles and read reviews other guests have left for
            them to determine if they are a good fit for you and your needs.
          </Paragraph>
          <Title level={5}>The power is in your hands</Title>
          <Paragraph>
            You can rent for 1 hour or 3 weeks or 2 months and pay what fits
            your budget.
          </Paragraph>
          <Title level={5}>24/7 Support</Title>
          <Paragraph>
            Our team of professional support staff is available 24/7 to provide
            assistance and answer questions.
          </Paragraph>
          <Title level={5}>Talk it over</Title>
          <Paragraph>
            Discuss your decision to host on Hourlypaces with your neighbors,
            friends and coworkers.
          </Paragraph>
          <Title level={5}>Trust and Safety</Title>
          <Paragraph>
            Your privacy, safety and security are our first concern; therefore,
            we commit ourselves to create a safe and trustworthy community for
            both our hosts and renters. Our secure website and Verified
            Identification process were established to keep you as safe and
            secure as possible.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default WhyRent;
