import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const HourlySpacesComOffersLargestCollectionHourlySpaceRentalsAroundWorld: FC =
  () => {
    return (
      <>
        <Helmet>
          <title>
            HourlySpaces.com Offers the Largest Collection of Hourly Space
            Rentals Around the World
          </title>
        </Helmet>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Title level={4} className="my-0">
                  HourlySpaces.com Offers the Largest Collection of Hourly Space
                  Rentals Around the World
                </Title>
              </Col>
              <Col xs={24}>
                <Paragraph>
                  Hourly Spaces is a fast-growing business that is quickly on
                  the path to becoming a top competitor in the short-term rental
                  industry. The company is the first to provide “all hourly
                  space rentals under one roof.”
                </Paragraph>
                <Paragraph>
                  HourlySpaces.com, formerly operating as HourlyRoomRental.com,
                  is rapidly emerging; it is currently underway to become the
                  largest hourly spaces on demand provider in the travel and
                  business lodging industry. The fast growing company is making
                  waves in the hourly space rental marketplace, aiming to lead
                  in the short-term rental industry by providing consumers with
                  the most extensive selection of hourly rentals around the
                  world.
                </Paragraph>
                <Paragraph>
                  HourlySpaces seeks to offer consumers more than a mere listing
                  of offices and rooms rented by the hour. The company plans to
                  list everything from apartments and vacant real estate to
                  wedding venues and theaters. The company’s new website,
                  www.HourlySpaces.com, was launched in 2016 and already has
                  several listings available in many major cities such as
                  Washington, DC, New York, NY, Portland, Chicago, San
                  Francisco, Paris, Rome, Canada, etc with tens of thousands of
                  visitors from more than 100 countries.
                </Paragraph>
                <Paragraph>
                  The concept behind HourlySpaces is to provide consumers with
                  an easy, affordable way to rent space without having to pay
                  for an entire day, month, or getting locked into yearly leases
                  of office space. The company’s motto: “Why pay for a day when
                  you can pay for an hour,” was derived from the founder, Joe
                  Bhaktiar, a young Engineer who left his field to pursue his
                  love for travel along with his wife, Kristi, a lifelong nurse.
                  Booking a hotel for a day when it is only needed for a few
                  hours is troublesome – and expensive. “Between looking for
                  hotels, calling them and finalizing a room, an hour had
                  passed, and we still ended up paying for the whole day because
                  that’s just how hotels work. We felt robbed,” Joe states while
                  reminiscing about a time when his family visited him in New
                  York City. “Another day I found myself wanting to take a quick
                  nap during my lunch break because I had a headache but nowhere
                  to go. Multiple instances like this helped us to conceptualize
                  and create HourlySpaces.com,” he added.
                </Paragraph>
                <Paragraph>
                  Unlike other websites that single out only business
                  professionals seeking to rent hourly office space,
                  HourlySpaces aims to target everyone, travelers, businesses
                  and even students for their study rooms. Business
                  professionals can easily search for an hourly conference room,
                  meeting spaces, or virtual office to rent for a few hours and
                  vacationers can locate thousands of affordable properties to
                  rent by the hour, day, or week. HourlySpaces offers many
                  hourly rental options for office spaces, conference rooms,
                  virtual office, event spaces, and meeting spaces throughout
                  Washington, DC and NYC while also having short-term room
                  rentals within apartment and hotel options. Many of the
                  listings currently on the site offer rates as low as $15 per
                  hour. Think of it as retail vs. wholesale. 1 day has 24 hours
                  and is considered “bulk booking” when on HourlySpaces, whereas
                  in traditional booking systems 1 week barely qualifies for a
                  bulk discount.
                </Paragraph>
                <Paragraph>
                  In a world where virtual office owners and the freelance
                  economy is booming, and reports that of over 40-percent of the
                  United States workforce is made up of virtual workers, the
                  idea of “bulk booking” is an innovative concept. Virtual
                  offices in Washington, DC and NYC offer entrepreneurs the
                  possibility of having a prestigious address and a location
                  that offers conference rooms, meeting and office space without
                  having to sign yearly leases at an overpriced rate. Joe states
                  that the company is currently in negotiations with several
                  branded hotels to make their rooms available to customers for
                  an hourly space rental.
                </Paragraph>
                <Paragraph>
                  However, that is not all that HourlySpaces has to offer. In
                  addition to the ability to search and book short-term rooms,
                  apartments, and offices, the consumers can also list their own
                  spaces to earn a side income. On average, people who list
                  their property on the website earn an extra $300 per week.
                  Hosts have the ability to set their own rates based off of
                  location, condition, peak travel seasons, and many other
                  factors.
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
export default HourlySpacesComOffersLargestCollectionHourlySpaceRentalsAroundWorld;
