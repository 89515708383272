import { FC } from "react";
import { Container } from "react-bootstrap";
import { Card, CardProps, Col, ColProps, Row, RowProps } from "antd";
import "./CardLayout.style.scss";

type CardLayoutProps = {
  title?: React.ReactNode;
  titleTextColor?: "red" | "gray";
  rowProps?: RowProps;
  colProps?: ColProps;
  cardProps?: CardProps;
  containerClassName?: string; // Allow custom class name for the container
  children: React.ReactNode;
  fluid?: boolean;
};

const CardLayout: FC<CardLayoutProps> = ({
  title,
  titleTextColor = "gray", // Default text color to 'gray'
  rowProps,
  colProps,
  cardProps,
  containerClassName = "", // Default to an empty string if no class name is provided
  children,
  fluid,
}) => {
  // Combine the container class names
  const containerClasses = `card-layout ${containerClassName}`.trim();

  // Combine the Card class names
  const cardClasses = `${titleTextColor} ${cardProps?.className || ""}`.trim();

  return (
    <Container fluid={fluid ? fluid : undefined} className={containerClasses}>
      <Row justify="center" {...rowProps}>
        <Col xs={24} {...colProps}>
          <Card title={title} className={cardClasses} {...cardProps}>
            {children}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CardLayout;
