import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Row, Select, Space, Typography } from "antd";
import CardLayout from "../../../common/components/card-layout/CardLayout";

const { Option } = Select;
const { Title, Paragraph } = Typography;

const Listings: FC = () => {
  const listingOptions = useMemo(
    () => [
      {
        value: "all",
        label: "Show all listings",
      },
      {
        value: "active",
        label: "Show active",
      },
      {
        value: "hide",
        label: "Show hidden",
      },
    ],
    []
  );

  const selectOption = (event: any) => {
    console.log(event);
  };

  return (
    <>
      <Helmet>
        <title>Your Listings</title>
      </Helmet>
      <CardLayout containerClassName="p-0">
        <Row gutter={[12, 24]}>
          <Col xs={24} md={12} lg={6}>
            <Select
              size="large"
              defaultValue={listingOptions[0]}
              className="w-100"
              onChange={selectOption}
            >
              {listingOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={24}>
            <Space direction="vertical">
              <div>
                <Title level={5}>You don't have any listings!</Title>
                <Paragraph className="mb-0">
                  Listing your space on Hourly Spaces on Demand is an easy way
                  to monetize any extra space you have.
                </Paragraph>
                <Paragraph>
                  You'll also get to meet interesting travelers from around the
                  world!
                </Paragraph>
              </div>
              <Button type="primary" size="large">
                Post a new listing
              </Button>
            </Space>
          </Col>
        </Row>
      </CardLayout>
    </>
  );
};
export default Listings;
