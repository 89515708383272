export const loadGoogleMapsScript = async (): Promise<void> => {
  const existingScript = document.getElementById("googleMaps");

  if (existingScript) {
    // If the script is already present, return immediately
    return Promise.resolve();
  }

  // If the script is not already present, create it
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async&libraries=places`;
    script.id = "googleMaps";
    script.async = true;
    script.defer = true;

    script.onload = () => {
      resolve(); // Resolve the promise when the script loads successfully
    };

    script.onerror = () => {
      console.error("Failed to load Google Maps script");
      reject(new Error("Failed to load Google Maps script")); // Reject the promise on error
    };

    document.body.appendChild(script);
  });
};
