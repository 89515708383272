export const socialIconsData = [
  {
    icon: "bi bi-twitter footer-icon",
    href: "https://x.com/i/flow/login?redirect_after_login=%2FHourly_Spaces",
  },
  {
    icon: "bi bi-facebook",
    href: "https://www.facebook.com/HourlySpaces",
  },
  {
    icon: "bi bi-youtube",
    href: "https://www.youtube.com/channel/UCu-YeWLCWctxfFpJzBf6ybA",
  },
];

export const currentCitiesData = [
  { city: "Washington DC", path: "Washington DC" },
  { city: "New York NYC", path: "New York" },
  { city: "Boston", path: "Boston" },
  { city: "Philadelphia", path: "Philadelphia" },
  { city: "Chicago", path: "Chicago" },
  { city: "Atlanta", path: "Atlanta" },
  { city: "Miami", path: "Miami" },
  { city: "San Francisco SF", path: "San Francisco" },
];
