import { FC } from "react";
import { Layout } from "antd";
import MainHeader from "../header/MainHeader";
import MainFooter from "../footer/MainFooter";

const { Content } = Layout;

type CommonLayoutProps = {
  children: React.ReactNode;
};

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  return (
    <Layout className="main-layout">
      <MainHeader />
      <Content>{children}</Content>
      <MainFooter />
    </Layout>
  );
};

export default CommonLayout;
