import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Pagination,
  Popover,
  Row,
  Select,
  Slider,
  SliderSingleProps,
  Space,
  TimePicker,
  Typography,
} from "antd";
import {
  CaretDownFilled,
  HomeOutlined,
  QuestionCircleFilled,
  ThunderboltFilled,
  WalletOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { SearchFilter } from "../../common/interfaces/SerachFilter";
import SpaceList from "../../common/components/space-list/SpaceList";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";
import useResponsive from "../../hooks/useResponsive";
import "./SearchSpace.style.scss";

const { Title, Text, Paragraph } = Typography;

const filterValue: SearchFilter = {
  checkin: undefined,
  hours: "",
  number_of_guests: 1,
  checkout: undefined,
  hours1: "",
  instance_book: false,
  room_types: [],
  min_sortBy: "sortByRelevance",
  price: [10, 9999],
  min_Rooms: "",
  min_bathrooms: "",
  min_beds: "",
  property_type_id: [],
  amenities: [],
  keywords: "",
};

const SearchSpace: FC = () => {
  const [spaceFilterValue, setSpaceFilterValue] =
    useState<SearchFilter>(filterValue);
  const query = new URLSearchParams(useLocation().search);
  const location = query.get("location");

  const handleChangeValue = (name: string, value: any) => {
    setSpaceFilterValue({ ...spaceFilterValue, [name]: value });
  };

  const handleResetValue = () => {
    setSpaceFilterValue(filterValue);
  };
  useEffect(() => {
    // console.log("location found from URL>>>", location);
  }, [location]);

  useEffect(() => {
    // console.log("spaceFilterValue>>>", spaceFilterValue);
  }, [spaceFilterValue]);

  const spacesData = [
    {
      id: "1",
      spaceImage:
        "https://images.pexels.com/photos/245240/pexels-photo-245240.jpeg?cs=srgb&dl=pexels-atbo-66986-245240.jpg&fm=jpg",
      title: "Hourly Thompson Conference Room - 1204 Broadway",
      profileImage:
        "https://i0.wp.com/www.steverrobbins.com/wp-content/uploads/ceo-midjourney-office.jpg?resize=1024%2C683&ssl=1",
      pricePerHour: "$60",
      pricePerDay: "$400",
      address: "Chelsea, Massachusetts, United States - 02150",
      review: 5,
      reviewTotal: 1,
      lat: 40.753685,
      lng: -73.999161,
    }
    // {
    //   id: "2",
    //   spaceImage:
    //     "https://img.freepik.com/free-photo/modern-office-space-interior_158595-5206.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1724803200&semt=ais_hybrid",
    //   title: "Hourly Meeting Space in Financial District for 5 - B",
    //   profileImage:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2tAuram1AEiac9rt9ebsNcjGm3bPT-Czivw&s",
    //   pricePerHour: "$40",
    //   pricePerDay: "$300",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   lat: 21.661542,
    //   lng: 70.570213,
    // },
    // {
    //   id: "3",
    //   spaceImage:
    //     "https://plus.unsplash.com/premium_photo-1681487178876-a1156952ec60?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8b2ZmaWNlc3xlbnwwfHwwfHx8MA%3D%3D",
    //   title: "Hourly Meeting Room Near Penn Station - B",
    //   profileImage:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr8aNuufR5O96efVY5Z5z7PNW7D52whBLsiiD_PVgkj0eYFfFRuPoNPw8mhBhusbEfBzk&usqp=CAU",
    //   pricePerHour: "$50",
    //   pricePerDay: "$700",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   review: 4,
    //   reviewTotal: 1,
    //   lat: 22.428104,
    //   lng: 71.189593,
    // },
    // {
    //   id: "4",
    //   spaceImage:
    //     "https://retaildesignblog.net/wp-content/uploads/2017/01/Peacock-Technology-office-by-DWG-Surat-India.jpg",
    //   title: "Hourly Meeting Space in Financial District for 5 - B",
    //   profileImage:
    //     "https://upload.wikimedia.org/wikipedia/commons/5/5a/Sanjiv_Mehta%2C_Chairman_of_The_East_India_Company.jpg",
    //   pricePerHour: "$25",
    //   pricePerDay: "$260",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   lat: 21.628313,
    //   lng: 73.023446,
    // },
    // {
    //   id: "5",
    //   spaceImage:
    //     "https://images.pexels.com/photos/8353767/pexels-photo-8353767.jpeg",
    //   title: "Hourly Meeting Room Near Penn Station - B",
    //   profileImage:
    //     "https://media.istockphoto.com/id/1459814562/photo/smart-successful-intelligent-hispanic-or-brazilian-young-woman-financial-director-seo-manager.jpg?s=612x612&w=0&k=20&c=01sxpws-5PYPE54Ydwhvszljm_ZMMgO8Bh3K_rtROUI=",
    //   pricePerHour: "$59",
    //   pricePerDay: "$659",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   review: 5,
    //   reviewTotal: 1,
    //   lat: 22.321636,
    //   lng: 73.145199,
    // },
    // {
    //   id: "6",
    //   spaceImage:
    //     "https://media.istockphoto.com/id/1387606716/photo/interior-of-modern-empty-office.jpg?s=612x612&w=0&k=20&c=Gqu3UyANLCAgZyXTjzRmGNg79P71IcGKVNDuqESggDY=",
    //   title: "Hourly Thompson Conference Room - 1204 Broadway",
    //   profileImage:
    //     "https://cdn.shopify.com/s/files/1/0272/4714/9155/files/Mr.Shailendra-Malhotra.jpg?v=1607334000",
    //   pricePerHour: "$23",
    //   pricePerDay: "$356",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   review: 5,
    //   reviewTotal: 1,
    //   lat: 22.566413,
    //   lng: 72.405259,
    // },
    // {
    //   id: "7",
    //   spaceImage:
    //     "https://images.pexels.com/photos/245240/pexels-photo-245240.jpeg?cs=srgb&dl=pexels-atbo-66986-245240.jpg&fm=jpg",
    //   title: "Hourly Thompson Conference Room - 1204 Broadway",
    //   profileImage:
    //     "https://i0.wp.com/www.steverrobbins.com/wp-content/uploads/ceo-midjourney-office.jpg?resize=1024%2C683&ssl=1",
    //   pricePerHour: "$60",
    //   pricePerDay: "$400",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   review: 5,
    //   reviewTotal: 1,
    //   lat: 22.763528,
    //   lng: 71.42875,
    // },
    // {
    //   id: "8",
    //   spaceImage:
    //     "https://img.freepik.com/free-photo/modern-office-space-interior_158595-5206.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1724803200&semt=ais_hybrid",
    //   title: "Hourly Meeting Space in Financial District for 5 - B",
    //   profileImage:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2tAuram1AEiac9rt9ebsNcjGm3bPT-Czivw&s",
    //   pricePerHour: "$40",
    //   pricePerDay: "$300",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   lat: 21.456486,
    //   lng: 71.456685,
    // },
    // {
    //   id: "9",
    //   spaceImage:
    //     "https://plus.unsplash.com/premium_photo-1681487178876-a1156952ec60?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8b2ZmaWNlc3xlbnwwfHwwfHx8MA%3D%3D",
    //   title: "Hourly Meeting Room Near Penn Station - B",
    //   profileImage:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr8aNuufR5O96efVY5Z5z7PNW7D52whBLsiiD_PVgkj0eYFfFRuPoNPw8mhBhusbEfBzk&usqp=CAU",
    //   pricePerHour: "$50",
    //   pricePerDay: "$700",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   review: 4,
    //   reviewTotal: 1,
    //   lat: 20.922504,
    //   lng: 72.217077,
    // },
    // {
    //   id: "10",
    //   spaceImage:
    //     "https://retaildesignblog.net/wp-content/uploads/2017/01/Peacock-Technology-office-by-DWG-Surat-India.jpg",
    //   title: "Hourly Meeting Space in Financial District for 5 - B",
    //   profileImage:
    //     "https://upload.wikimedia.org/wikipedia/commons/5/5a/Sanjiv_Mehta%2C_Chairman_of_The_East_India_Company.jpg",
    //   pricePerHour: "$25",
    //   pricePerDay: "$260",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   lat: 22.777826,
    //   lng: 73.617411,
    // },
    // {
    //   id: "11",
    //   spaceImage:
    //     "https://images.pexels.com/photos/8353767/pexels-photo-8353767.jpeg",
    //   title: "Hourly Meeting Room Near Penn Station - B",
    //   profileImage:
    //     "https://media.istockphoto.com/id/1459814562/photo/smart-successful-intelligent-hispanic-or-brazilian-young-woman-financial-director-seo-manager.jpg?s=612x612&w=0&k=20&c=01sxpws-5PYPE54Ydwhvszljm_ZMMgO8Bh3K_rtROUI=",
    //   pricePerHour: "$59",
    //   pricePerDay: "$659",
    //   address: "Chelsea, Massachusetts, United States - 02150",
    //   review: 5,
    //   reviewTotal: 1,
    //   lat: 22.807341,
    //   lng: 73.598786,
    // },
  ];

  return (
    <div className="search-space">
      <Row gutter={0}>
        <Col xs={24} md={10}>
          <GoogleMaps spaceListData={spacesData} />
        </Col>
        <Col xs={24} md={14} className="filter-section">
          <FilterForm
            handleChangeValue={handleChangeValue}
            handleResetValue={handleResetValue}
            spaceFilterValue={spaceFilterValue}
            spaceListData={spacesData}
          />
        </Col>
      </Row>
    </div>
  );
};

type InfoWindowContentProps = {
  location: any;
  navigate: any;
};

const InfoWindowContent: FC<InfoWindowContentProps> = ({
  location,
  navigate,
}) => {
  return (
    <div className="popover-content">
      <Image
        className="popover-image"
        src={location.spaceImage}
        alt={location.title}
      />
      <Title
        level={5}
        className="popover-title"
        onClick={() =>
          navigate(PageRoutesConstant.Page.SpaceDetails.path, {
            state: { spaceId: location.id },
          })
        }
      >
        {location.title}
      </Title>
      <Paragraph className="popover-address">{location.address}</Paragraph>
      <Paragraph className="popover-hour">
        {location.pricePerHour}/hour
      </Paragraph>
      <Paragraph className="popover-day">{location.pricePerDay}/day</Paragraph>
    </div>
  );
};

type GoogleMapProps = {
  spaceListData: any[] | [];
};

const GoogleMaps: FC<GoogleMapProps> = ({ spaceListData }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const navigate = useNavigate();
  const mapRef = useRef<HTMLDivElement | null>(null);
  let openInfoWindow: google.maps.InfoWindow | null = null;

  useEffect(() => {
    if (isLoaded && mapRef.current && window.google) {
      const mapOptions = {
        zoom: 8,
        center: new google.maps.LatLng(40.753685, -73.999161), // Initial center
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      };

      const map = new google.maps.Map(mapRef.current, mapOptions);

      // Create markers from the locations array
      spaceListData.forEach((location) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.lat, location.lng),
          map: map,
          title: location.title,
        });

        const infoWindow = new google.maps.InfoWindow();
        const infoWindowDiv = document.createElement("div");
        const root = createRoot(infoWindowDiv); // Use createRoot instead of ReactDOM.render

        // Render your InfoWindowContent component
        root.render(
          <InfoWindowContent location={location} navigate={navigate} />
        );
        infoWindow.setContent(infoWindowDiv);

        marker.addListener("click", () => {
          if (openInfoWindow) {
            openInfoWindow.close();
          }
          infoWindow.open(map, marker);
          openInfoWindow = infoWindow;
        });
      });
    }
  }, [isLoaded, spaceListData]);

  return isLoaded ? (
    <div
      id="map"
      ref={mapRef}
      style={{ height: `${window.innerHeight - 45}px` }} // Ensure the map has a height
    ></div>
  ) : (
    <div>Loading map...</div>
  );
};

type FilterFormProps = {
  handleChangeValue(name: string, value: any): void;
  handleResetValue(): void;
  spaceFilterValue: SearchFilter;
  spaceListData: any[] | [];
};
const FilterForm: FC<FilterFormProps> = ({
  handleChangeValue,
  handleResetValue,
  spaceFilterValue,
  spaceListData,
}) => {
  const { isMobile } = useResponsive();
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const guestsOptions = useMemo(() => {
    return Array.from({ length: 16 }, (_, i) => i + 1).map((guest) => ({
      value: guest,
      label: (
        <Space>
          {guest < 16 ? guest : "16+"}
          {guest === 1 ? "Guest" : "Guests"}
        </Space>
      ),
    }));
  }, []);

  const sortOptions = useMemo(
    () => [
      { value: "sortByRelevance", label: "Relevance" },
      { value: "sortByHourlyPrice", label: "Hourly Price" },
      { value: "sortByDailyPrice", label: "Daily Price" },
    ],
    []
  );

  const propertyTypeOptions = useMemo(
    () => [
      { value: "conferenceRoom", label: "Conference Room" },
      { value: "hotel", label: "Hotel" },
      { value: "loft", label: "Loft" },
      { value: "eventSpace", label: "Event Space" },
      { value: "coWorkingSpace", label: "Co-Working Space" },
      { value: "virtualOffice", label: "Virtual Office" },
      { value: "photoStudio", label: "Photo Studio" },
      { value: "apartment", label: "Apartment" },
      { value: "house", label: "House" },
      { value: "personalOffice", label: "Personal Office" },
      { value: "boatYacht", label: "Boat/Yacht" },
      { value: "meetingSpace", label: "Meeting Space" },
      { value: "theater", label: "Theater" },
    ],
    []
  );

  const amenitiesOptions = useMemo(
    () => [
      { value: "smokingAllowed", label: "Smoking Allowed" },
      { value: "cableTV", label: "Cable TV" },
      { value: "wirelessInternet", label: "Wireless Internet" },
      { value: "airConditioning", label: "Air Conditioning" },
      { value: "elevatorInBuilding", label: "Elevator in Building" },
      { value: "handicapAccessible", label: "Handicap Accessible" },
      { value: "pool", label: "Pool" },
      { value: "kitchen", label: "Kitchen" },
      {
        value: "parkingIncluded",
        label: "Parking Included (additional cost may apply)",
      },
      { value: "washerDryer", label: "Washer / Dryer" },
      { value: "doormanReceptionist", label: "Doorman/Receptionist" },
      { value: "funRoom", label: "Fun room" },
      { value: "hotTub", label: "Hot Tub" },
      { value: "businessFurniture", label: "Business Furniture" },
      { value: "conferenceRoomFurniture", label: "Conference Room Furniture" },
      { value: "beverages", label: "Beverages (Additional costs may apply)" },
      { value: "printScanCopy", label: "Print/Scan/Copy" },
      { value: "ipVideoConference", label: "IP Video Conference" },
      { value: "videoConference", label: "Video Conference" },
      { value: "phoneConference", label: "Phone Conference" },
      { value: "lcdProjector", label: "LCD Projector" },
      { value: "bigScreenTV", label: "Big Screen TV" },
    ],
    []
  );

  const roomOptions = useMemo(() => {
    const options = [{ value: "", label: "Rooms" }];
    for (let i = 1; i <= 16; i++) {
      options.push({ value: i.toString(), label: `${i}` });
    }
    return options;
  }, []);

  const bathroomOptions = useMemo(() => {
    return [
      { value: "", label: "Bathrooms" },
      { value: "0", label: "0" },
      { value: "0.5", label: "0.5" },
      { value: "1", label: "1" },
      { value: "1.5", label: "1.5" },
      { value: "2", label: "2" },
      { value: "2.5", label: "2.5" },
      { value: "3", label: "3" },
      { value: "3.5", label: "3.5" },
      { value: "4", label: "4" },
      { value: "4.5", label: "4.5" },
      { value: "5", label: "5" },
      { value: "5.5", label: "5.5" },
      { value: "6", label: "6" },
      { value: "6.5", label: "6.5" },
      { value: "7", label: "7" },
      { value: "7.5", label: "7.5" },
      { value: "8+", label: "8+" },
    ];
  }, []);

  const bedOptions = useMemo(() => {
    const options = [{ value: "", label: "Beds" }];
    for (let i = 1; i <= 15; i++) {
      options.push({ value: i.toString(), label: `${i}` });
    }
    options.push({ value: "16+", label: "16+" });
    return options;
  }, []);

  const handlePage = (page: any, pagesize: any) => {
    //console.log(page, pagesize);
  };

  const content = (
    <Row gutter={[8, 8]}>
      <Col xs={24}>
        <Title level={5} className="mb-0">
          Entire Place
        </Title>
        <Text>Listing where you have the whole place to yourself.</Text>
      </Col>
      <Col xs={24}>
        <Title level={5} className="mb-0">
          Private room
        </Title>
        <Text>
          Listings where you have your own room but share some common spaces.
        </Text>
      </Col>
      <Col xs={24}>
        <Title level={5} className="mb-0">
          Shared room
        </Title>
        <Text>
          Listings where you'll share your room or your room may be a common
          space.
        </Text>
      </Col>
    </Row>
  );

  const marks: SliderSingleProps["marks"] = {
    0: 0,
    9999: 9999,
  };

  return (
    <div className="px-3">
      <Row align="middle">
        <Col xs={24} className="mt-3">
          <Row gutter={[24, 8]} className="mx-0">
            <Col xs={24} sm={6}>
              <Title level={5}>From Date</Title>
            </Col>
            <Col xs={24} sm={6}>
              <DatePicker
                placeholder="mm/dd/yy"
                size="large"
                className="w-100"
                format="MM/DD/YYYY"
                value={
                  spaceFilterValue.checkin
                    ? dayjs(spaceFilterValue.checkin, "MM/DD/YYYY")
                    : null
                }
                onChange={(date, dateString) =>
                  handleChangeValue("checkin", dateString)
                }
              />
            </Col>
            <Col xs={24} sm={6}>
              <TimePicker
                format="hh:mm A"
                use12Hours
                size="large"
                minuteStep={30}
                value={
                  spaceFilterValue?.hours
                    ? dayjs(spaceFilterValue.hours, "hh:mm A")
                    : null
                }
                onChange={(time, timeString) =>
                  handleChangeValue("hours", timeString)
                }
                className="w-100"
              />
            </Col>
            <Col xs={24} sm={6}>
              <Select
                size="large"
                options={guestsOptions}
                suffixIcon={<i className="bi bi-people-fill"></i>}
                className="w-100"
                value={spaceFilterValue.number_of_guests}
                onChange={(event) =>
                  handleChangeValue("number_of_guests", event)
                }
              />
            </Col>
            <Col xs={24} sm={6}>
              <Title level={5}>To Date</Title>
            </Col>
            <Col xs={24} sm={6}>
              <DatePicker
                placeholder="mm/dd/yy"
                size="large"
                className="w-100"
                format="MM/DD/YYYY"
                disabled={spaceFilterValue.checkin ? false : true}
                disabledDate={(current) =>
                  current.isBefore(spaceFilterValue.checkin, "day")
                }
                value={
                  spaceFilterValue.checkout
                    ? dayjs(spaceFilterValue.checkout, "MM/DD/YYYY")
                    : null
                }
                onChange={(date) =>
                  handleChangeValue("checkout", date?.format("MM/DD/YYYY"))
                }
              />
            </Col>
            <Col xs={24} sm={6}>
              <TimePicker
                format="hh:mm A"
                use12Hours
                size="large"
                minuteStep={30}
                className="w-100"
                disabled={spaceFilterValue.hours ? false : true}
                value={
                  spaceFilterValue?.hours1
                    ? dayjs(spaceFilterValue.hours1, "hh:mm A")
                    : null
                }
                onChange={(time, timeString) =>
                  handleChangeValue("hours1", timeString)
                }
              />
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col xs={24}>
          <Row gutter={[24, 8]} align="middle" className="mx-0">
            <Col xs={24} sm={6}>
              <Title level={5}>Booking type</Title>
            </Col>
            <Col xs={24} sm={6}>
              <Checkbox
                value={spaceFilterValue.instance_book}
                checked={spaceFilterValue.instance_book}
                onChange={(event) =>
                  handleChangeValue("instance_book", event.target.checked)
                }
              >
                Instant booking <ThunderboltFilled />
              </Checkbox>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col xs={24}>
          <Row gutter={[24, 8]} align="middle" className="mx-0">
            <Col xs={24} sm={6}>
              <Title level={5}>Space Type</Title>
            </Col>
            <Col xs={24} sm={6}>
              <Checkbox
                value="Entire home/apt"
                checked={spaceFilterValue.room_types.includes(
                  "Entire home/apt"
                )}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  const value = event.target.value;
                  handleChangeValue(
                    "room_types",
                    isChecked
                      ? [...spaceFilterValue.room_types, value]
                      : spaceFilterValue?.room_types?.filter(
                          (type: string) => type !== value
                        )
                  );
                }}
              >
                Entire Space <HomeOutlined />
              </Checkbox>
            </Col>
            <Col xs={24} sm={6}>
              <Checkbox
                value="Private room"
                checked={spaceFilterValue.room_types.includes("Private room")}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  const value = event.target.value;
                  handleChangeValue(
                    "room_types",
                    isChecked
                      ? [...spaceFilterValue.room_types, value]
                      : spaceFilterValue?.room_types?.filter(
                          (type: string) => type !== value
                        )
                  );
                }}
              >
                Private room <WalletOutlined />
              </Checkbox>
            </Col>
            <Col xs={24} sm={6}>
              <Row align="middle">
                <Col xs={22}>
                  <Checkbox
                    value="Shared room"
                    checked={spaceFilterValue.room_types.includes(
                      "Shared room"
                    )}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      const value = event.target.value;
                      handleChangeValue(
                        "room_types",
                        isChecked
                          ? [...spaceFilterValue.room_types, value]
                          : spaceFilterValue?.room_types?.filter(
                              (type: string) => type !== value
                            )
                      );
                    }}
                  >
                    <Space>
                      Shared room
                      <i className="bi bi-house-add"></i>
                    </Space>
                  </Checkbox>
                </Col>
                <Col xs={2}>
                  <Popover content={content}>
                    <QuestionCircleFilled />
                  </Popover>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col xs={24}>
          <Row gutter={[24, 8]} align="middle" className="mx-0">
            <Col xs={24} sm={6}>
              <Title level={5}>Sort By</Title>
            </Col>
            <Col xs={24} sm={6}>
              <Select
                value={spaceFilterValue.min_sortBy}
                size="large"
                options={sortOptions}
                suffixIcon={<CaretDownFilled />}
                className="w-100"
                onChange={(event) => handleChangeValue("min_sortBy", event)}
              />
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col xs={24} className="pt-3">
          <Row gutter={[24, 8]} align="middle" className="mx-0">
            <Col xs={24} sm={6}>
              <Title level={5}>Price</Title>
            </Col>
            <Col xs={24} sm={18}>
              <Slider
                range
                max={9999}
                marks={marks}
                value={spaceFilterValue.price}
                tooltip={{ open: true }}
                onChange={(event) => handleChangeValue("price", event)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      {!isShowMore && (
        <Row gutter={[24, 8]} className="more-filter">
          <Col xs={24} sm={12}>
            <Button
              type="default"
              size="large"
              className="w-100"
              onClick={() => setIsShowMore(!isShowMore)}
            >
              More Filters
            </Button>
          </Col>
          <Col xs={24} sm={12}>
            <Button
              type="primary"
              size="large"
              className="w-100"
              onClick={handleResetValue}
            >
              Reset Filters
            </Button>
          </Col>
        </Row>
      )}
      {isShowMore && (
        <Row wrap>
          <Col xs={24}>
            <Row gutter={[24, 8]} align="middle" className="mx-0">
              <Col xs={24} sm={6}>
                <Title level={5}>Size</Title>
              </Col>
              <Col xs={24} sm={6}>
                <Select
                  size="large"
                  options={roomOptions}
                  suffixIcon={<CaretDownFilled />}
                  className="w-100"
                  value={spaceFilterValue.min_Rooms}
                  onChange={(event) => handleChangeValue("min_Rooms", event)}
                />
              </Col>
              <Col xs={24} sm={6}>
                <Select
                  size="large"
                  options={bathroomOptions}
                  suffixIcon={<CaretDownFilled />}
                  className="w-100"
                  value={spaceFilterValue.min_bathrooms}
                  onChange={(event) =>
                    handleChangeValue("min_bathrooms", event)
                  }
                />
              </Col>
              <Col xs={24} sm={6}>
                <Select
                  size="large"
                  options={bedOptions}
                  suffixIcon={<CaretDownFilled />}
                  className="w-100"
                  value={spaceFilterValue.min_beds}
                  onChange={(event) => handleChangeValue("min_beds", event)}
                />
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col xs={24}>
            <Row gutter={[24, 8]} align="middle" className="mx-0">
              <Col xs={24} sm={6}>
                <Title level={5}>Property Type</Title>
              </Col>
              <Col xs={24} sm={18}>
                <Select
                  mode="multiple"
                  placeholder="Select Property Type"
                  size="large"
                  options={propertyTypeOptions}
                  suffixIcon={<CaretDownFilled />}
                  className="w-100"
                  value={spaceFilterValue.property_type_id}
                  onChange={(event) =>
                    handleChangeValue("property_type_id", event)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col xs={24}>
            <Row gutter={[24, 8]} align="middle" className="mx-0">
              <Col xs={24} sm={6}>
                <Title level={5}>Amenities</Title>
              </Col>
              <Col xs={24} sm={18}>
                <Select
                  mode="multiple"
                  placeholder="Select Amenities"
                  size="large"
                  options={amenitiesOptions}
                  suffixIcon={<CaretDownFilled />}
                  className="w-100"
                  value={spaceFilterValue.amenities}
                  onChange={(event) => handleChangeValue("amenities", event)}
                />
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col xs={24}>
            <Row gutter={[24, 8]} align="middle" className="mx-0">
              <Col xs={24} sm={6}>
                <Title level={5}>Keywords</Title>
              </Col>
              <Col xs={24} sm={18}>
                <Input
                  size="large"
                  placeholder="Ocean side, transit, relaxing..."
                  value={spaceFilterValue.keywords}
                  onChange={(event) =>
                    handleChangeValue("keywords", event.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col xs={24} className="mb-4">
            <Row gutter={[24, 12]}>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  Show Listings
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={handleResetValue}
                >
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!isShowMore && (
        <Row wrap>
          <Col xs={24}>
            <SpaceList
              spaceListData={spaceListData}
              rowProps={{
                gutter: [24, 24],
                justify: { sm: "start", md: "center", lg: "start" },
              }}
              colProps={{ xs: 24, sm: 12, md: 18, lg: 12, xxl: 8 }}
            />
          </Col>
          <Divider />
          <Col xs={24} className="mb-4">
            <Pagination
              total={505}
              pageSize={50}
              onChange={handlePage}
              showSizeChanger={false}
              hideOnSinglePage={true}
              responsive={true}
              align="end"
              showTotal={(total, range) => (
                <Title level={5} className="mb-0">
                  {isMobile
                    ? ""
                    : `${range[0]}-${range[1]} of ${total} Rentals`}
                </Title>
              )}
              className="me-auto"
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SearchSpace;
