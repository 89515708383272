import { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CommonFlowRoutes from "./components/PageRoutes";
import SearchFlowRoutes from "./components/SearchRoutes";
import { AuthProvider } from "../components/authContext/AuthContext";
import { PrivateRoute } from "./components/PrivateRoute";
import ProfileFlowRoutes from "./components/UserRoutes";
import ListingFlowRoutes from "./components/ListingRoute";
import AccountFlowRoutes from "./components/AccountRoute";
import TravelingFlowRoutes from "./components/TravelingRoute";
import MessageFlowRoutes from "./components/MessageRoute";
import DashboardFlowRoutes from "./components/DashboardRoute";
import BlogsFlowRoutes from "./components/BlogsRoute";

const MainRoutes: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AuthProvider>
      <Routes>
        {/* <Route path="/*" element={<UnderConstruction />} /> */}
        <Route path="/*" element={<CommonFlowRoutes />} />
        <Route path="/search/*" element={<SearchFlowRoutes />} />
        <Route path="/users/profile/*" element={<ProfileFlowRoutes />} />
        <Route path="/blogs/*" element={<BlogsFlowRoutes />} />
        <Route element={<PrivateRoute />}>
          <Route path="/account/*" element={<AccountFlowRoutes />} />
          <Route path="/users/*" element={<ProfileFlowRoutes />} />
          <Route path="/traveling/*" element={<TravelingFlowRoutes />} />
          <Route path="/listings/*" element={<ListingFlowRoutes />} />
          <Route path="/message/*" element={<MessageFlowRoutes />} />
          <Route path="/home/*" element={<DashboardFlowRoutes />} />
          {/* <Route path="/rooms/*" element={<RoomsFlowRoutes />} /> */}
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default MainRoutes;
