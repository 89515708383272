import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const PartyVenueRental: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("partyVenueRental")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Parties are thrown for many occasions—and more often than not, no
            two occasions are exactly alike. Therefore, why should the price of
            your party venue rental be the same for a day as for 6 hours? At
            Hourly spaces, we want you to be able to choose your venue for the
            amount of time that you need and not for the extra hours that you
            won’t spend at your party. Partying is meant to relieve stress, so
            why can’t finding your perfect party venue rental be stress-free?
          </Paragraph>
          <Paragraph>
            If you believe that finding your perfect rental should be an easier
            process then look no further than Hourly spaces. We strive to help
            you find your ideal rental space on the budget that you require.
            With numerous rental properties available to rent online, Hourly
            spaces lists all of the party venue rentals in the city that you
            need.
          </Paragraph>
          <Paragraph>
            Do you live in Miami but looking for a party venue rental for a
            birthday in New York City? Hourly spaces allows you to search our
            cities scattered across America no matter where you’re from. We work
            with companies to ensure that the best party venue rental spaces are
            provided to you. Our website is easy to navigate and features
            numerous search tools when looking for your perfect party venue
            rental. The properties listed will give you a description of the
            space along with photos, amenities, and extra specials that the
            companies may offer.
          </Paragraph>
          <Paragraph>
            The best part about renting with Hourly spaces is that you’re free
            to rent your perfect space for an hour, a day, a week, or even a
            month. If you’re looking to throw a day or night party, Hourly
            spaces can search for a venue within your criteria. The same search
            criteria can be used for hourly rentals as well. No matter what time
            of day or length of your party, we can provide you with all of the
            listings in your specific area that match your needs and budget. We
            help you find your rental so that you can get the party started the
            stress-free way.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default PartyVenueRental;
