// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog .blog-list .title {
  letter-spacing: 0.5px;
  font-size: 22px;
}`, "",{"version":3,"sources":["webpack://./src/pages/blogs/Blog.style.scss"],"names":[],"mappings":"AAEI;EACE,qBAAA;EACA,eAAA;AADN","sourcesContent":[".blog {\r\n  .blog-list {\r\n    .title {\r\n      letter-spacing: 0.5px;\r\n      font-size: 22px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
