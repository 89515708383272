export { default as Logo } from "./logo.png";
export { default as HowItWork1 } from "./home/how-it-work-1.png";
export { default as HowItWork2 } from "./home/how-it-work-2.png";
export { default as HowItWork3 } from "./home/how-it-work-3.png";
export { default as NewYork } from "./home/cities/new-york.jpg";
export { default as WashingtonDC } from "./home/cities/washington-dc.jpg";
export { default as Boston } from "./home/cities/boston.jpg";
export { default as SanFrancisco } from "./home/cities/san-francisco.jpg";
export { default as Philadelphia } from "./home/cities/philadelphia.jpg";
export { default as Chicago } from "./home/cities/chicago.jpg";
export { default as Atlanta } from "./home/cities/atlanta.jpg";
export { default as AddYourPlace } from "./how-it-work/add-your-place.png";
export { default as FindPlace } from "./how-it-work/find-place.png";
export { default as WhyHost } from "./how-it-work/why-hosts.png";
export { default as Hotels1 } from "./hotel-room/hourly-hotel-room.jpeg";
export { default as Hotels2 } from "./hotel-room/hourly-hotel-room-rental.jpeg";
export { default as Neighborhoods1 } from "./neighbor-hoods/new-york-city.jpg";
export { default as Neighborhoods2 } from "./neighbor-hoods/dc.jpg";
export { default as Neighborhoods3 } from "./neighbor-hoods/san_fran_pic.jpg";
export { default as Neighborhoods4 } from "./neighbor-hoods/philadelphia.jpg";
export { default as Neighborhoods5 } from "./neighbor-hoods/boston.jpg";
export { default as Neighborhoods6 } from "./neighbor-hoods/chicago_illinois_usa_downtown.jpg";
export { default as Neighborhoods7 } from "./neighbor-hoods/atlanta.jpg";
export { default as Neighborhoods8 } from "./neighbor-hoods/miami.jpg";
export { default as Blog1I1 } from "./blogs/blog-1.jpg";
export { default as Blog2I2 } from "./blogs/blog-2.jpg";
export { default as Blog3I3 } from "./blogs/blog-3.jpg";
