import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const RentPentHouse: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("rentPentHouse")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Have you dreamed of hosting your next party overlooking the
            Manhattan skyline from the pent house view? Or overlooking the white
            sand beaches of Miami? At Hourly spaces, we’re here to make your
            dream a reality. Renting pent houses on our website allows you to
            rent for the specific amount of time that you require whether it be
            by the hour, day, week or month. Travel to one of our numerous city
            locations across America and relax in a fully furnished pent house
            rental and see the city the way it’s meant to be seen!
          </Paragraph>
          <Paragraph>
            Hourly spaces offers pent house rentals in the city that you choose.
            Our rentals allow you to stay within your budget while traveling
            because of the unique way that you can specify how long you want to
            stay. Renting a pent house is never an easy process—and they’re
            often not available. But with Hourly spaces you can search our
            available pent house rentals instantly and choose the one best
            suited to your desires. Our pent house rentals can come fully
            furnished and our website details all of the available amenities and
            services.
          </Paragraph>
          <Paragraph>
            Want to know what your view will be like before you arrive? Hourly
            spaces provides your search for a rental pent house with photos and
            a description of the property that you require. We strive to provide
            you with great customer service so that you can find your perfect
            pent house stress-free. We work with companies to provide the best
            possible pent houses and list them on our website so that you have
            the ease of finding just what you’re looking for without a hassle.
          </Paragraph>
          <Paragraph>
            Renting pent houses has become an increasing trend for parties, but
            with Hourly spaces you can rent the pent house for as long as you
            need. So, if you’re traveling to a new city and you want to
            experience the best views, search on Hourly spaces for available
            rental pent houses.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default RentPentHouse;
