import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { languageData } from "./Language.constant";

const LanguageDropdown: FC = () => {
  const { i18n } = useTranslation();

  const languageOptions = useMemo(() => {
    return languageData.map((language) => ({
      value: language.code,
      label: language.name,
    }));
  }, []);

  const handleLanguage = (langCode: string) => {
    i18n.changeLanguage(langCode);
  };

  return (
    <Select
      className="dropdown"
      popupClassName="dropdown-popup"
      value={i18n.language}
      onChange={handleLanguage}
      aria-label="Select Language"
      size="middle"
      suffixIcon={<CaretDownOutlined />}
      options={languageOptions}
    />
  );
};
export default LanguageDropdown;
