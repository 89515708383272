import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { Col, Image, Row, Typography } from "antd";
import NextArrow from "../slider-next-arrow/NextArrow";
import PrevArrow from "../slider-prev-arrow/PrevArrow";
import {
  Atlanta,
  Boston,
  Chicago,
  NewYork,
  Philadelphia,
  SanFrancisco,
  WashingtonDC,
} from "../../../../assets/images";
import { SearchRoutesConstant } from "../../../../common/router/components/SearchRoutes";
import "./CitiesSlider.style.scss";

const { Title, Text } = Typography;

const CitiesSlider: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const citiesSliderData = [
    {
      city: "New York",
      image: NewYork,
    },
    {
      city: "Washington DC",
      image: WashingtonDC,
    },
    {
      city: "Boston",
      image: Boston,
    },
    {
      city: "San Francisco",
      image: SanFrancisco,
    },
    {
      city: "Philadelphia",
      image: Philadelphia,
    },
    {
      city: "Chicago",
      image: Chicago,
    },
    {
      city: "Atlanta",
      image: Atlanta,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="slider-wrapper">
      <Container>
        <Title level={3} className="title text-center">
          {t("home.citiesSlider.title")}
        </Title>

        <Slider {...settings} className="content-slider">
          {citiesSliderData.map((item, index) => (
            <div key={`slider_image_${index}`}>
              <div
                className="image-content"
                onClick={() =>
                  navigate(
                    `${SearchRoutesConstant.Search.Space.path}?location=${item.city}`
                  )
                }
              >
                <Image
                  src={item.image}
                  alt={item.city}
                  preview={false}
                  className="image"
                />
                <div className="city">
                  <h1 className="name">{item.city}</h1>
                </div>
                <div className="city-hover">
                  <div className="arrow-content">
                    <span className="arrow">
                      <i className="bi bi-arrow-right-circle-fill"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="my-4 text-center">
          <Row align="middle" justify="center">
            <Col xs={24} md={8}>
              <div>{t("home.citiesSlider.description1")}</div>
              <div>
                {t("home.citiesSlider.description2")}
                <Text className="notify ms-1">
                  {t("home.citiesSlider.notifyMe")}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default CitiesSlider;
