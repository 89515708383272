import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";

const { Title, Text, Paragraph } = Typography;

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  return (
    <CardLayout title={t("privacy_policy")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Hourly Properties Inc. and our affiliated companies (see Section 4
            below) (<Text strong>Hourly Spaces</Text> or
            <Text strong className="mx-1">
              we
            </Text>
            or <Text strong>us</Text> or <Text strong>our</Text>) recognizes the
            need to protect the privacy of the personal information you provide
            to Hourly Spaces with respect to your access and use of our website
            (https://www.hourlyspaces.com) (the <Text strong>Website</Text>) or
            through our mobile and web applications (the Website and such mobile
            and web applications being collectively referred to as the
            <Text strong className="ms-1">
              Applications
            </Text>
            ).
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Therefore, we have adopted this privacy policy (the
            <Text strong className="ms-1">
              Privacy Policy
            </Text>
            ), which sets forth, among other things, the type of information
            that will be collected, the purpose and use of the collected
            information, and your rights with regard to the collected
            information. This Privacy Policy governs how we collect and use your
            personal information wherever you use our Applications. By accessing
            the Applications, you are consenting to the collection and the use
            of your information by us, but only to the extent described herein.
            Should you wish to revoke your consent, you may do so by contacting
            us, information on which is set out at Section 4 below.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Hourly Spaces may make modifications, deletions and/or additions to
            this Privacy Policy (Changes) at any time. Changes will be
            effective: (i) thirty (30) days after Hourly Spaces provides notice
            of the Changes, whether such notice is provided through the
            Applications user interface, is sent to the email address associated
            with your account or otherwise; or (ii) when you opt-in or otherwise
            expressly agree to the Changes or a version of this Privacy Policy
            incorporating the Changes, whichever comes first.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Protection of Your Information</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            We are accountable for the information in our custody. Therefore,
            when we collect or use your information, we will utilize
            commercially reasonable safeguards to ensure its protection. It
            should be noted that no security procedure is currently 100%
            effective. Should any breach of your Personal Information occur, we
            will inform you as soon as reasonably possible, as required by
            applicable law.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Type and Purpose of Collection</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            We collect information at various points in the Applications to
            facilitate its use by our customers. Specifically, two types of
            information are collected:
          </Paragraph>
        </Col>
        <Col xs={24} className="ms-4">
          <Row>
            <Col xs={24}>
              <Paragraph>
                Non-Personal Information: Upon accessing the Applications,
                certain non-personal information will be automatically collected
                without your knowledge or consent, such as your IP address,
                location data (which is anonymous and where you log in through
                the Applications you will be asked if you consent to the
                Applications accessing your location, which can be updated at
                any time in your device settings) and the referring website
                (“Non-Personal Information”). We use Non-Personal Information to
                examine our traffic and to view how our customers use the
                Applications. This type of information will not allow you to be
                personally identified although we might be able to associate it
                with your account. For example, we use “cookies”, which contain
                only certain statistical information. For more information on
                cookies please see Section 11 below.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Identifying Personal Information: To utilize some portions of
                the Applications, you must first provide personal information
                that will allow you to be identified (
                <Text strong>Personal Information</Text>). This type of
                information will not be collected without your consent. The
                purposes of the collection of Personal Information are the
                following:
              </Paragraph>
            </Col>
            <Col xs={24}>
              <ol type="i" className="ms-4">
                <li>To fulfill your order;</li>
                <li>To send you an order confirmation;</li>
                <li>To administer your account;</li>
                <li>
                  To send you push notifications or SMS messages when you are
                  using the Applications in relation you use of the services we
                  provide, for example, to provide a code in order to access a
                  location;
                </li>
                <li>
                  To send you our newsletter provided you have not unsubscribed.
                  You may opt out of such newsletter through using the
                  unsubscribe link or by contacting us at
                  <Link
                    to="mailto:support@HourlySpaces.com"
                    className="link ms-1"
                  >
                    support@HourlySpaces.com
                  </Link>
                  ;
                </li>
                <li>To establish a relationship with you;</li>
                <li>
                  To facilitate your service with our affiliated service
                  providers;
                </li>
                <li>To anticipate and resolve problems with your service;</li>
                <li>
                  To understand your needs and desires vis-à-vis the
                  Applications;
                </li>
                <li>
                  To update you on changes to our services or products,
                  including new promotions provided that you have not
                  unsubscribed. You may opt out of such notifications through
                  the unsubscribe link or by contacting us at
                  <Link
                    to="mailto:support@HourlySpaces.com"
                    className="link ms-1"
                  >
                    support@HourlySpaces.com
                  </Link>
                  .
                </li>
              </ol>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Paragraph>
            We expressly acknowledge that we will not use your Personal
            Information for any other purposes without your consent. Further, we
            will only collect Personal Information to the extent necessary for
            the above mentioned purposes. The Personal Information we collect
            will vary depending on how you are using the Applications, but may
            include, without limitation: (i) your name, mailing address, phone
            number and email address; (ii) credit card information and charge
            history; (iii) unique identifiers such as user name, account number
            and password; (iv) preferences information such as product or
            content interests, communication or marketing preferences; (v)
            device information and data; (vi) reservation history and coupon
            usage history; and (vii) Google, Twitter and Facebook identity.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Additionally, Personal Information may be used and disclosed to the
            extent Hourly Spaces may deem reasonably necessary to enforce the
            terms of any agreement between you and Hourly Spaces, or to protect
            the rights, property or safety of any person or entity.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Right to Examine Information</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            You have the right to examine any of your Personal Information that
            we collect. Should you wish to examine such information, please send
            us a written request to
            <Link to="mailto:support@HourlySpaces.com" className="link ms-1">
              support@HourlySpaces.com
            </Link>
            . We reserve the right to charge you a reasonable administrative fee
            to access your information, as permitted by applicable law. In
            certain cases, we may not be able to provide you with access to all
            of your Personal Information (ex: if the information also pertains
            to the Personal Information of another user).
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Withdrawal of Consent</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            You may withdraw your consent to the collection of Personal
            Information at any time by sending an email to us. Upon receiving
            notice that you have revoked your consent, we will stop using your
            Personal Information within a reasonable time, which will vary
            depending on what information we have collected and for what
            purpose. Please note that we will send you an email confirmation
            upon receipt of your request. Therefore, if you do not receive a
            confirmation email, please contact us again with your request. If
            you do choose to withdraw such consent, your access to the
            Applications may be diminished, or your ability to choose some of
            the options on the Applications may be limited.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Contact email:
            <Link to="mailto:support@HourlySpaces.com" className="link ms-1">
              support@HourlySpaces.com
            </Link>
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Sharing Information</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            We will not sell, rent or disclose to outside parties the
            information we collect and save except that we may share the
            collected information with other parties as follows:
          </Paragraph>
        </Col>
        <Col xs={24} className="ms-4">
          <Row>
            <Col xs={24}>
              <Paragraph>
                Affiliated Service Providers: We have agreements with various
                affiliated service providers to facilitate the functioning of
                the Applications, with whom we may share the information we have
                collected. For example, we may share your credit card
                information with the credit card service provider to process
                your purchase. All administrative service providers that we use
                are required to have the same level of privacy protection as we
                have, and therefore we expect that your information will be
                handled with the same level of care that we employ.
                Additionally, for example, we may use analytic services.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Where required by law: We may share the collected information
                where required by law, specifically in response to a demand from
                government authorities where such demand meets the legal
                requirements.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Statistical Analysis: We may share Non-Personal Information and
                aggregated information with third parties, including but not
                limited to for advertising or marketing purposes. No Personal
                Information will be shared in this manner.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Transactions: In connection with, or during negotiations of, any
                merger, sale of company assets, financing or acquisition, or in
                any other situation where Personal Information may be disclosed
                or transferred as one of our business assets.
              </Paragraph>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Hourly Spaces Group companies and Hourly Spaces offices and
                operations: We may share Personal Information and Non-Personal
                Information with our group companies or with our offices and
                operations based outside your local country in order to provide
                you with our services and for our own operational processes.
              </Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Title level={5}>External Links</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            The Application contains links and references to other websites. We
            are not responsible for the collection, use and disclosure of
            information, or the privacy practices of such websites, and we
            expressly disclaim any liability relating thereto.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>International Transfer</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Both Personal Information and Non-Personal Information you submit
            via the Applications is sent to our group companies and will
            processed in the USA and stored on secure servers located in the
            USA. Such information may also be transferred by us to our offices
            and third parties. The countries concerned may not have similar data
            protection laws to your country. Where we transfer your information
            out of your jurisdiction we will take reasonable steps to ensure
            that your privacy rights continue to be protected. By submitting
            your information to the Applications, you agree to this storing,
            processing and transfer.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Payment processing</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Payment details you provide will be encrypted using secure sockets
            layer (SSL) technology before they are submitted over the internet.
            Payments made on the Applications are made through our payment
            gateway provider. You will be providing credit or debit card
            information directly to our payment gateway provider which operates
            a secure server to process payment details, encrypting your
            credit/debit card information and authorizing payment. Information
            which you supply to our payment gateway provider is not within our
            control and is subject to their own privacy policy and terms and
            conditions.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Terms of Service</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            This Privacy Policy is incorporated into and forms part of the Terms
            of Service, which outlines the terms and conditions you agree to
            when accessing and using the Applications, and which can be found
            here:
            <Link
              to={PageRoutesConstant.Page.TermsOfService.path}
              className="link ms-1"
            >
              Terms of Service
            </Link>
            .
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Persons Under 18</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            The Applications are not marketed toward persons under the age of
            18. If Hourly Spaces discovers that it has inadvertently collected
            Personal Information about individuals under the age of 18, it will
            promptly delete such information.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Cookies and similar technologies</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            When you interact with the Applications, we try to make that
            experience simple and meaningful. When you use our Applications, our
            web server sends a cookie to your computer or mobile device (as the
            case may be). Cookies are small pieces of information which are
            issued to your computer or mobile device (as the case may be) when
            you visit a website or access or use a mobile application and which
            store and sometimes track information about your use of a website or
            application (as the case may be). A number of cookies we use last
            only for the duration of your web session or Applications session
            and expire when you close your browser or exit the Applications.
            Other cookies are used to remember you when you return to the
            Applications and will last for longer.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Some of the cookies used by the Applications are set by us, and some
            are set by third parties who are delivering services on our behalf.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Most web and mobile device browsers automatically accept cookies
            but, if you prefer, you can change your browser to prevent that or
            to notify you each time a cookie is set. You can also learn more
            about cookies by visiting www.allaboutcookies.org which includes
            additional useful information on cookies and how to block cookies
            using different types of browser or mobile device. Please note,
            however, that by blocking or deleting cookies used on the
            Applications, you may not be able to take full advantage of the
            Applications.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            The Applications use cookies and similar technologies to cookies to
            collect information about your access and use of the Applications.
            You can also update your privacy settings on your device by setting
            the ‘Limit Ad Tracking’ and Diagnostics and Usage setting property
            located in the settings screen of your Apple iPhone or iPad, or by
            resetting your Android ID through apps that are available in the
            Play Store. You can also stop information collection by uninstalling
            the Applications on your device and you can use the standard
            uninstall process available as part of your device for this purpose.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Some information on the types of cookies we use and how we use them
            is set out below:
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5}>Social Media</Title>
        </Col>
        <Col xs={24}>
          <Paragraph>
            You can log-on to your account with us by using social media
            plug-ins from social media platforms such as Twitter and Facebook.
            If you choose to log in via a social media platform, our
            Applications will connect to the servers of the social media
            platform. We will receive your basic profile information as directed
            by the relevant social media platform when you log in. You will also
            have the option to share features of our Applications with the
            relevant social media platform as you wish. Visit the privacy policy
            of the relevant social media platform provider to learn more about
            your rights and choices.
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Paragraph>
            Should you have any questions or comments concerning this Privacy
            Policy, please do not hesitate to contact us at
            <Link to="mailto:support@HourlySpaces.com" className="link ms-1">
              support@HourlySpaces.com
            </Link>
            .
          </Paragraph>
        </Col>
        <Col xs={24}>
          <Title level={5} className="mb-0">
            NEED HELP?
          </Title>
          <Link to={PageRoutesConstant.Page.Faq.path} className="link">
            Read our FAQ
          </Link>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default PrivacyPolicy;
