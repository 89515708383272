import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import {
  CheckCircleTwoTone,
  GooglePlusOutlined,
  MailOutlined,
  MobileOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const TrustAndVerification: FC = () => {
  const [viewPhoneNumber, setViewPhoneNumber] = useState<boolean>(false);

  const verifySMS = (value: any) => {
    console.log(value);
  };

  return (
    <>
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <CardLayout containerClassName="p-0">
            <Row gutter={[12, 12]} align="middle">
              <Col xs={24} md={20} xl={21}>
                <Title level={5}>Verify Your ID</Title>
                <Paragraph>
                  Getting your Verified ID is the easiest way to help build
                  trust in the Hourly Spaces on Demand community. We'll verify
                  you by matching information from an online account to an
                  official ID.
                </Paragraph>
                <Paragraph>
                  Or, you can choose to only add the verifications you want
                  below.
                </Paragraph>
              </Col>
              <Col xs={24} md={4} xl={3}>
                <Button type="primary" size="large" className="text-uppercase">
                  Verify Me
                </Button>
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xs={24}>
          <CardLayout
            title={
              <Title level={4} className="mb-0">
                <CheckCircleTwoTone twoToneColor="#00be00" /> Your Current
                Verifications
              </Title>
            }
            containerClassName="p-0"
          >
            <Paragraph>
              You have no verifications yet. You can add more below
            </Paragraph>
          </CardLayout>
        </Col>
        <Col xs={24}>
          <CardLayout
            title={
              <Title level={4} className="mb-0">
                <PlusCircleOutlined twoToneColor="#82888a" /> Add More
                Verifications
              </Title>
            }
            containerClassName="p-0"
          >
            <Row gutter={[12, 12]} align="middle">
              <Col xs={24} md={20} xl={21}>
                <Space align="start" size={20}>
                  <GooglePlusOutlined size={50} />
                  <div>
                    <Title level={5}>Google</Title>
                    <Paragraph>
                      Connect your Hourly Spaces on Demand account to your
                      Google account for simplicity and ease.
                    </Paragraph>
                  </div>
                </Space>
              </Col>
              <Col xs={24} md={4} xl={3}>
                <Button type="primary" size="large" className="text-uppercase">
                  Connect
                </Button>
              </Col>

              <Col xs={24} md={20} xl={21}>
                <Space align="start" size={20}>
                  <MailOutlined />
                  <div>
                    <Title level={5}>Email</Title>
                    <Paragraph>
                      Please verify your email address by clicking the link in
                      the message we just sent to: nikunjpdesai@gmail.com
                    </Paragraph>
                  </div>
                </Space>
              </Col>
              <Col xs={24} md={4} xl={3}>
                <Button type="primary" size="large" className="text-uppercase">
                  Connect
                </Button>
              </Col>

              <Col xs={24} md={20}>
                <Space align="start" size={20}>
                  <MobileOutlined />
                  <div>
                    <Title level={5}>Phone Number</Title>
                    <Paragraph>
                      Make it easier to communicate with a verified phone
                      number. We’ll send you a code by SMS or read it to you
                      over the phone. Enter the code below to confirm that
                      you’re the person on the other end.
                    </Paragraph>
                    {!viewPhoneNumber ? (
                      <>
                        <Paragraph className="mb-0">
                          No phone number entered
                        </Paragraph>
                        <Button
                          type="link"
                          className="link p-0"
                          onClick={() => setViewPhoneNumber(!viewPhoneNumber)}
                        >
                          Add a Phone number
                        </Button>
                      </>
                    ) : (
                      <>
                        <Form onFinish={verifySMS} layout="inline">
                          <Row gutter={[12, 12]}>
                            <Col xs={24}>
                              <Text>Choose a country:</Text>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                name="country"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your country",
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  placeholder="Select your country"
                                >
                                  <Option value="india">India</Option>
                                  <Option value="canada">Canada</Option>
                                  <Option value="united-state">
                                    United State
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                              <Text>Add a phone number:</Text>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                name="phone"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your number!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter your number"
                                  size="large"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24}>
                              <Space>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                >
                                  Verify via SMS
                                </Button>
                                <Button
                                  type="link"
                                  size="large"
                                  className="link"
                                >
                                  Why Verify?
                                </Button>
                              </Space>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    )}
                  </div>
                </Space>
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xs={24}>
          <CardLayout title="Verification Status" containerClassName="p-0 mb-0">
            <Row gutter={[12, 12]}>
              <Col xs={24}>
                <Radio disabled={true}>No, Not verified</Radio>
              </Col>
              <Col xs={24}>
                <Radio checked={true}>Yes, Verified</Radio>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </>
  );
};
export default TrustAndVerification;
