import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../../components/profile-layout/ProfileLayout";
import Notification from "../../../pages/user/account/notification/Notification";
import PayoutPreferences from "../../../pages/user/account/payout-preferences/PayoutPreferences";
import TransactionHistory from "../../../pages/user/account/transaction-history/TransactionHistory";
import MyWishList from "../../../pages/user/account/my-wish-list/MyWishList";
import Security from "../../../pages/user/account/security/Security";
import Settings from "../../../pages/user/account/settings/Settings";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Account: RouteKey;
}

const AccountBaseRoutesConstant: IRoute = {
  Account: {
    Base: {
      path: "/account",
    },
  },
};

export const AccountRoutesConstant: IRoute = {
  Account: {
    Notification: {
      path: `${AccountBaseRoutesConstant.Account.Base.path}`,
      element: <Notification />,
    },
    Payout: {
      path: `${AccountBaseRoutesConstant.Account.Base.path}/payout`,
      element: <PayoutPreferences />,
    },
    Transaction: {
      path: `${AccountBaseRoutesConstant.Account.Base.path}/transaction`,
      element: <TransactionHistory />,
    },
    MyWishList: {
      path: `${AccountBaseRoutesConstant.Account.Base.path}/mywishlist`,
      element: <MyWishList />,
    },
    Security: {
      path: `${AccountBaseRoutesConstant.Account.Base.path}/security`,
      element: <Security />,
    },
    Settings: {
      path: `${AccountBaseRoutesConstant.Account.Base.path}/settings`,
      element: <Settings />,
    },
  },
};

const AccountRoutes = () => {
  return (
    <Routes>
      {Object.keys(AccountRoutesConstant.Account).map((key) => {
        const route = AccountRoutesConstant.Account[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              AccountRoutesConstant.Account.Notification.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const AccountFlowRoutes: FC = () => {
  return (
    <ProfileLayout>
      <AccountRoutes />
    </ProfileLayout>
  );
};

export default AccountFlowRoutes;
