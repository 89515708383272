import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Select, Typography } from "antd";
import CardLayout from "../../../common/components/card-layout/CardLayout";

const { Option } = Select;
const { Paragraph } = Typography;

const Inbox: FC = () => {
  const selectOption = (event: any) => {
    console.log(event);
  };

  const messageOptions = useMemo(
    () => [
      { value: "all", label: "All Messages (0)" },
      { value: "starred", label: "Starred (0)" },
      { value: "unread", label: "Unread (0)" },
      { value: "never_responded", label: "Never Responded (0)" },
      { value: "reservations", label: "Reservations (0)" },
      { value: "cancel_reservation", label: "Cancel Reservations (0)" },
      { value: "hidden", label: "Archived (0)" },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>Inbox</title>
      </Helmet>
      <CardLayout
        containerClassName="p-0"
        title={
          <Row>
            <Col xs={24} md={12} lg={6}>
              <Select
                size="large"
                defaultValue={messageOptions[0]}
                onChange={selectOption}
                className="w-100"
              >
                {messageOptions.map((option, index) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        }
      >
        <Row gutter={[12, 12]}>
          <Col xs={24}>
            <Paragraph className="mb-0">Nothing to show you.</Paragraph>
          </Col>
        </Row>
      </CardLayout>
    </>
  );
};
export default Inbox;
