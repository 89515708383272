// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  position: relative;
  height: 600px;
  overflow: hidden;
}
.video-container video {
  width: 100%;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/video/VideoAutoPlay.style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,oBAAA;AACR","sourcesContent":[".video-container {\r\n    position: relative;\r\n    height: 600px;\r\n    overflow: hidden;\r\n\r\n    video {\r\n        width: 100%;\r\n        pointer-events: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
