import { FC } from "react";
import { RightOutlined } from "@ant-design/icons";
import "./NextArrow.style.scss";

const PrevArrow: FC = (props: any) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} next-arrow`} onClick={onClick}>
      <RightOutlined />
    </div>
  );
};

export default PrevArrow;
