import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const HourlySpacesLikeAirbnbHour: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hourly Spaces is like Airbnb by the hour</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Hourly Spaces is like Airbnb by the hour
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                This could be good for napping. “There have been times that I
                lived a ways from my job and had run myself short on sleep. A
                nap on my lunch hour or in the break during a split shift would
                have been a lifesaver. But to go all the way home and back would
                have burned all of the time that could have been for napping. I
                actually rented a cheap motel room not far from my job for this
                purpose a couple of times. I had to pay for a whole night even
                though I just needed it briefly. And the room was less
                beautifully appointed and safe and such as the ones on this
                service might be for the same price.” Said one client that is
                happy to know that HourlySpaces now exists!
              </Paragraph>
              <Paragraph>
                “I wonder if it also allows for showering. I’ve totally had
                times where I’d pay that kind of price to be able to take a
                shower in the middle of the work day. Times when I’d had a messy
                day at work or was worried that my deodorant wasn’t up to the
                task, etc. But driving home wouldn’t leave me enough time. But
                if I could just duck in and shower and get back on it, my whole
                day would be so much more pleasant.” Asked someone in the room
                and the answer was yes! Yayy!
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default HourlySpacesLikeAirbnbHour;
