import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Select, Tabs, TabsProps, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const { Title } = Typography;
const { Option } = Select;

const TransactionHistory: FC = () => {
  const items: TabsProps["items"] = [
    {
      key: "transaction",
      label: "Completed Transaction",
      children: <CompletedTransaction />,
    },
    {
      key: "future",
      label: "Future Transaction",
      children: <FutureTransaction />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Your Transaction Details</title>
      </Helmet>
      <Tabs items={items} />
    </>
  );
};

interface TransactionOption {
  value: string;
  label: string;
}

const CompletedTransaction: FC = () => {
  const payoutMethod: TransactionOption[] = useMemo(
    () => [
      { value: "0", label: "All payout methods" },
      { value: "2", label: "Paypal" },
      { value: "1", label: "CreditCard" },
      { value: "4", label: "By check" },
    ],
    []
  );

  const payoutListing: TransactionOption[] = useMemo(
    () => [{ value: "0", label: "All listings" }],
    []
  );

  const payoutYear: TransactionOption[] = useMemo(
    () => [
      { value: "0", label: "All" },
      { value: "2024", label: "2024" },
    ],
    []
  );
  const payoutMonth: TransactionOption[] = useMemo(
    () => [
      { value: "0", label: "All months" },
      { value: "1", label: "January" },
      { value: "2", label: "February" },
      { value: "3", label: "March" },
      { value: "4", label: "April" },
      { value: "5", label: "May" },
      { value: "6", label: "June" },
      { value: "7", label: "July" },
      { value: "8", label: "August" },
      { value: "9", label: "September" },
      { value: "10", label: "October" },
      { value: "11", label: "November" },
      { value: "12", label: "December" },
    ],
    []
  );

  return (
    <CardLayout
      containerClassName="p-0"
      title={
        <Row gutter={[12, 12]}>
          <Col xs={24} md={6} className="mt-2 mt-md-0">
            <Select
              size="large"
              placeholder="Select your gender"
              defaultValue={payoutMethod[0]}
              className="w-100"
            >
              {payoutMethod.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={6}>
            <Select
              size="large"
              placeholder="Select your gender"
              defaultValue={payoutListing[0]}
              className="w-100"
            >
              {payoutListing.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={6}>
            <Select
              size="large"
              placeholder="Select your gender"
              defaultValue={payoutYear[0]}
              className="w-100"
            >
              {payoutYear.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={6} className="mb-2 mb-md-0">
            <Select
              size="large"
              placeholder="Select your gender"
              defaultValue={payoutMonth[0]}
              className="w-100"
            >
              {payoutMonth.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      }
    >
      <Row justify="center">
        <Col>
          <Title level={4}>No Transactions.</Title>
        </Col>
      </Row>
    </CardLayout>
  );
};

const FutureTransaction: FC = () => {
  const payoutMethod: TransactionOption[] = useMemo(
    () => [
      { value: "0", label: "All payout methods" },
      { value: "2", label: "Paypal" },
      { value: "1", label: "CreditCard" },
      { value: "4", label: "By check" },
    ],
    []
  );

  const payoutListing: TransactionOption[] = useMemo(
    () => [{ value: "0", label: "All listings" }],
    []
  );

  return (
    <CardLayout
      containerClassName="p-0"
      title={
        <Row gutter={[12, 12]}>
          <Col xs={24} md={6} className="mt-2 mt-md-0">
            <Select
              size="large"
              placeholder="Select your gender"
              defaultValue={payoutMethod[0]}
              className="w-100"
            >
              {payoutMethod.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={6}>
            <Select
              size="large"
              placeholder="Select your gender"
              defaultValue={payoutListing[0]}
              className="w-100"
            >
              {payoutListing.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      }
    >
      <Row justify="center">
        <Col>
          <Title level={4}>No Transactions.</Title>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default TransactionHistory;
