import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Row, Space, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const { Text } = Typography;

const Review: FC = () => {
  const [aboutYou, setAboutYou] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>Your Reviews</title>
      </Helmet>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <CardLayout title="Reviews" containerClassName="p-0">
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Text>
                  Reviews are allowed only at the end of a trip booked through
                  Hourly Spaces on Demand.
                </Text>
              </Col>
              <Col>
                <Space size={15}>
                  <Button
                    type="primary"
                    size="large"
                    className="text-uppercase"
                    onClick={() => setAboutYou(true)}
                  >
                    About You
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="text-uppercase"
                    onClick={() => setAboutYou(false)}
                  >
                    By You
                  </Button>
                </Space>
              </Col>
            </Row>
          </CardLayout>
        </Col>

        <Col xs={24}>
          <CardLayout title="Review" containerClassName="p-0">
            <Row gutter={[24, 24]}>
              {aboutYou ? (
                <Col xs={24}>
                  <Text>No one has reviewed you yet</Text>
                </Col>
              ) : (
                <Col xs={24}>
                  <Text>No one has reviewed by you</Text>
                </Col>
              )}
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </>
  );
};
export default Review;
