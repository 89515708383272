import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../../components/profile-layout/ProfileLayout";
import Profile from "../../../pages/user/profile/Profile";
import ChangePassword from "../../../pages/user/profile/change-password/ChangePassword";
import EditProfile from "../../../pages/user/profile/edit-profile/EditProfile";
import References from "../../../pages/user/profile/references/References";
import Review from "../../../pages/user/profile/review/Review";
import TrustAndVerification from "../../../pages/user/profile/trust-and-verification/TrustAndVerification";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Users: RouteKey;
}

const UsersBaseRoutesConstant: IRoute = {
  Users: {
    Base: {
      path: "/users",
    },
  },
};

const UsersProfileBaseRoutesConstant: IRoute = {
  Users: {
    Profile: {
      path: `${UsersBaseRoutesConstant.Users.Base.path}/profile`,
    },
  },
};

export const ProfileRoutesConstant: IRoute = {
  Users: {
    Profile: {
      path: `${UsersProfileBaseRoutesConstant.Users.Profile.path}/:name`,
      element: <Profile />,
    },
    ChangePassword: {
      path: `${UsersBaseRoutesConstant.Users.Base.path}/change_password`,
      element: <ChangePassword />,
    },
    EditProfile: {
      path: `${UsersBaseRoutesConstant.Users.Base.path}/edit`,
      element: <EditProfile />,
    },
    References: {
      path: `${UsersBaseRoutesConstant.Users.Base.path}/references`,
      element: <References />,
    },
    Review: {
      path: `${UsersBaseRoutesConstant.Users.Base.path}/review`,
      element: <Review />,
    },
    TrustAndVerification: {
      path: `${UsersBaseRoutesConstant.Users.Base.path}/verify`,
      element: <TrustAndVerification />,
    },
  },
};

const UserRoutes = () => {
  return (
    <Routes>
      {Object.keys(ProfileRoutesConstant.Users).map((key) => {
        const route = ProfileRoutesConstant.Users[key];
        return (
          <Route
            key={key}
            path={route.path
              .replace(UsersProfileBaseRoutesConstant.Users.Profile.path, "")
              .replace(UsersBaseRoutesConstant.Users.Base.path, "")}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const UserFlowRoutes: FC = () => {
  return (
    <ProfileLayout>
      <UserRoutes />
    </ProfileLayout>
  );
};

export default UserFlowRoutes;
