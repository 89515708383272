import { FC, useEffect, useRef, useState } from "react";
import { Input, InputRef } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { loadGoogleMapsScript } from "../../../utilities/loadGoogleMaps";

type GMPAutocompleteProps = {
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  placeholder?: string;
  className?: string;
  size?: SizeType;
};

const GMPAutocomplete: FC<GMPAutocompleteProps> = ({
  onPlaceSelected,
  placeholder = "",
  className = "",
  size = "middle",
}) => {
  const inputRef = useRef<InputRef | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadGoogleMapsScript();
        setIsScriptLoaded(true);
      } catch (error) {
        console.error('Error loading Google Maps API:', error);
      }
    };

    loadScript();
  }, []);
  
  useEffect(() => {
    if (!isScriptLoaded) return;
    
    const timerId = setTimeout(() => {
      // Check if google.maps is available
      if (
        typeof window.google === "undefined" ||
        typeof window.google.maps === "undefined"
      ) {
        console.error("Google Maps API is not available");
        return;
      }

      // Check if google.maps.places is available
      if (typeof window.google.maps.places === "undefined") {
        console.error("Google Maps Places API is not available");
        return;
      }

      // Ensure inputRef.current.input is available
      if (!inputRef.current?.input) {
        console.error("Input reference is not available");
        return;
      }

      // Initialize the Autocomplete instance
      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current.input
      );
      // Add event listener for place_changed
      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current?.getPlace();
        if (place) {
          const placeValue = place.formatted_address || place.name || "";
          setInputValue(placeValue); // Call onChange with selected place
          onPlaceSelected(place);
        }
      });

      return () => {
        // Clean up the event listener
        if (autocompleteRef.current) {
          google.maps.event.clearInstanceListeners(autocompleteRef.current);
        }
      };
    }, 600);

    return () => clearTimeout(timerId); // Cleanup timeout
  }, [isScriptLoaded, onPlaceSelected]);

  return (
    <Input
      ref={inputRef}
      size={size}
      placeholder={placeholder}
      className={className}
      value={inputValue} // Bind input value to state
      onChange={(e) => setInputValue(e.target.value)} // Update state on input change
    />
  );
};

export default GMPAutocomplete;
