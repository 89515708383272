import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Collapse, CollapseProps, Space, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";

const { Text, Paragraph } = Typography;

const FAQ: FC = () => {
  const { t } = useTranslation();

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "How do I sign up?",
      children: (
        <Space direction="vertical">
          <Text>
            Go to
            <Link
              to={PageRoutesConstant.Page.SignUp.path}
              className="link ms-1"
            >
              sign up
            </Link>
          </Text>
          <Paragraph>
            You can either signup using your Facebook, Twitter,Google Plus
            accounts or by simply entering your email address. We promise not to
            share your information with any third party.
          </Paragraph>
        </Space>
      ),
      showArrow: false,
    },
    {
      key: "2",
      label: "How easy is it to get paid?",
      children: (
        <Paragraph>
          Our system provides an easy way to get paid! We send you a check at
          your choice of address within 3 days of reservation!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "3",
      label: "How to Host?",
      children: (
        <Space direction="vertical">
          <Text>Hosting is as easy as three simple steps!</Text>
          <Text>1) List Your Space</Text>
          <Text>2) Respond to Requests</Text>
          <Text>3) Welcome your guests</Text>
        </Space>
      ),
      showArrow: false,
    },
    {
      key: "4",
      label: "I've been invited to join HourlySpaces.com. What now?",
      children: (
        <Paragraph>
          Someone has invited you to join HourlySpaces.com, that means you can
          sign up for a free account to view the reservation details and access
          the space. As a new user, you’ll also receive free credit of $25
          towards your next reservation. Enjoy!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "5",
      label: "How to reserve a space?",
      children: (
        <Space direction="vertical">
          <Text>Reserving a space is as easy as three simple steps!</Text>
          <Text>1) Search</Text>
          <Text>2) Book</Text>
          <Text>3) Travel</Text>
        </Space>
      ),
      showArrow: false,
    },
    {
      key: "6",
      label: "Is it safe to rent on HourlySpaces.com",
      children: (
        <Paragraph>
          It is very safe to rent any space on HourlySpaces because we screen
          every listing and every travel is backed by a $1 Million insurance
          policy for your peace of mind!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "7",
      label: "How can I rent my free space on HourlySpaces.com?",
      children: (
        <Paragraph>
          Simply click on the "List your space" button on the top right corner
          of the website! For specific questions, email us or call us anytime
          and we will walk you through the easy steps!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "8",
      label: "Are the spaces professionally presentable?",
      children: (
        <Paragraph>
          We require our hosts to provide a clean space to our users. If you
          feel the space you rent is not in professional condition, please
          contact us immediately so we can address the problem and refund you
          the money in addition to working on fixing the problem so you can be
          worry free next time you rent a space with us!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "9",
      label: "What amenities do the spaces have?",
      children: (
        <Paragraph>
          The spaces are all equipped with necessary amenities such as
          bathrooms. Most spaces include amenities such as water, coffee,
          internet, phone, office furniture, photocopy machines, that either
          come free or are charged in addition to your reservation. You can find
          a more detailed list of amenities under each listing's description.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "10",
      label: "How can I pay for the reservation?",
      children: (
        <Text>
          We accept all major credit cards and payment through paypal.
        </Text>
      ),
      showArrow: false,
    },
    {
      key: "11",
      label: "How & when does the host get their payment?",
      children: (
        <Paragraph>
          Hosts receive payments via check after the guest has checked in to the
          space. These payouts can be scheduled bi-monthly or monthly based on
          the host's payout requirements.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "12",
      label: "I arrived early for my reservation, can I check in early?",
      children: (
        <Paragraph>
          As long as the space is available, you can modify your reservation to
          a previous time at no extra cost!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "13",
      label: "What if I need to extend my reservation?",
      children: (
        <Paragraph>
          If you need to extend your reservation, you can do so by simply making
          a new reservation.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "14",
      label: "Will I be sharing the space with anyone?",
      children: (
        <Paragraph>
          Unless marked “Shared,” all our spaces are private. Our spaces are
          managed by friendly associates — they clean after every reservation,
          so they’re never far if you have any questions!
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "15",
      label: "Can I bring my own food and drinks?",
      children: (
        <Space direction="vertical" size="middle">
          <Paragraph>
            Food and beverages are permitted in all spaces, unless indicated
            otherwise. However, we ask that you be mindful of the furniture.
          </Paragraph>
          <Paragraph>
            Planning on ordering catering? Please send the details of your
            event, including the number of guests, prior to booking to
            <Link to="mailto:support@hourlyspaces.com" className="link ms-1">
              support@hourlyspaces.com
            </Link>{" "}
            or feel free to coordinate directly with the space manager. We’re
            happy to point you to spaces with adequate resources for food
            service, storage and disposal.
          </Paragraph>
          <Paragraph>
            Any food spills? Just give us a call so we can ensure our operations
            associates can have things cleaned up for the next guest.
          </Paragraph>
        </Space>
      ),
      showArrow: false,
    },
    {
      key: "16",
      label: "What are the rules for Alcohol and Smoking?",
      children: (
        <Paragraph>
          Smoking is not permitted in any of our spaces. Permission of alcohol
          is upto the discretion of individual hosts. If you have any such
          requirements for your event, please communicate with the host to get
          permission prior to reserving the space.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "17",
      label:
        "If I book multiple days in a row, can I leave my belongings in the space?",
      children: (
        <Paragraph>
          As long as you're returning to the same space and there is no one else
          using the space in between your reservations, you can leave your
          belongings behind. However, neither the host, nor HourlySpaces is
          responsible for any lost or stolen items.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "18",
      label: "How long does it take to receive confirmation on a booking?",
      children: (
        <Paragraph>
          Since most of our reservations are made for instant use, the response
          time is almost instant. However, our policy allows hosts to respond
          within 24 hours from when the booking requested.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "19",
      label: "Where can I set operating hours for my listing?",
      children: (
        <Paragraph>
          Once logged in to your account, click on your username on the top
          right corner of the screen. From the dropdown, select 'Your Listings'.
          Then click on 'Manage listing & calendar' for the listing for which
          you wish to set operating hours. On the left menu, click on 'Timing'.
          This is where you set the timings for your listing.
        </Paragraph>
      ),
      showArrow: false,
    },
    {
      key: "20",
      label: "How to import or export other calendars?",
      children: (
        <Paragraph>
          Once logged in to your account, click on your username on the top
          right corner of the screen. From the dropdown, select 'Your Listings'.
          Then click on 'Manage listing & calendar' for the listing for which
          you wish to export/import the calendar. On the left menu, click on
          'Calendar'. On the top of calendar, you can see two buttons, one for
          importing third party calendars to hourlyspaces calendar and one for
          exporting hourlyspaces calendar to other calendars like outlook or
          google calendar.
        </Paragraph>
      ),
      showArrow: false,
    },
  ];

  return (
    <CardLayout title={t("faq")} titleTextColor="red">
      <Collapse size="small" accordion items={items}></Collapse>
    </CardLayout>
  );
};

export default FAQ;
