import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const SmallWorkEnvironmentsMakeSense: FC = () => {
    return (
    <>
      <Helmet>
        <title>Why Small Work Environments Make Sense</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Why Small Work Environments Make Sense
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Large office spaces are quietly waving a farewell hand to the
                corporate world of America. In the last century, there has been
                a significant rise in millennial-based companies. These
                companies take the lavish and typically large work environments
                and compact them into smaller and more efficient work spaces.
              </Paragraph>
              <Paragraph>
                Start-up companies don’t require formal office work spaces at
                all times. Instead, these companies are using less office space
                along with more energy-efficient and comfortable work
                environments. Renting hourly office spaces has become beneficial
                and necessary to small businesses. The traditional office
                building has consisted of dull-gray cubicles with fluorescent
                lighting (and bad office puns). But the new generation has begun
                to kick this standard to the ground. Some of the new features of
                millennial work environments include: smaller offices,
                green-themed decorations, and newly improved break rooms.
              </Paragraph>
              <Paragraph>
                Smaller offices provide companies with the needed space to work
                at a lower rental price. Smaller businesses often don’t require
                entire office floors or buildings to conduct business. What
                makes smaller work environments unique is that they are easy to
                maintain on a budget while still providing employees (and
                potential investors) with a professional look and environment.
                Companies can start from anywhere, and the best way to expand a
                small business is with minimal costs. Smaller working spaces
                have become standard to a rapidly growing and diverse corporate
                America.
              </Paragraph>
              <Paragraph>
                Green-themed decorations are on the rise in corporate America.
                Green-theme is the way an office is designed in order to reflect
                modern and energy-efficient products and business environments.
                New office spaces are designed to let in more natural light and
                strive to eliminate the standard fluorescent lighting. These
                green-themed offices have a more open and loft-like design,
                disregarding the boxy cubicle mess that was the 1970’s, in order
                to facilitate improved communication in a less-stressful
                environment for employees. These offices also feature more
                “green” office furniture. Some of this equipment includes
                energy-efficient lightbulbs, green and earth colored office
                chairs, environmentally-centered paintings and artwork, and
                natural hardwood floors. Green is reported to be the least
                stressful color on the eyes, and new companies have taken this
                idea into great consideration for an overall improved office
                environment.
              </Paragraph>
              <Paragraph>
                Break rooms have been re-vamped with the increase in
                technological start-up companies. The idea is to provide
                employees with a safe place where they can relax when work has
                become stressful. Common break rooms feature TV’s, vending
                machines, and games. The revamped version of the break room now
                includes medicine balls, couches, and La-Z-Y-Boy style chairs
                for employee relaxation. In order to make smaller work
                environments more comfortable, break rooms have been redesigned
                to improve employee satisfaction with their work.
              </Paragraph>
              <Paragraph>
                So, what makes small work environments successful?
              </Paragraph>
              <Paragraph>
                Smaller offices are needed when a company is on the rise. Large
                corporations still dominate America, however, some of the
                nation’s greatest multi-million dollar companies have started
                out in a garage (Apple or Microsoft) or in a dorm room
                (Facebook). Today, smaller work environments allow start-ups and
                small businesses the chance to grow their clientele network
                (while looking more professional than a dorm room). Small work
                environments are cost-effective and easy to maintain and provide
                overall customer satisfaction.
              </Paragraph>
              <Paragraph>
                How does Hourly spaces play a role in the small work space
                environment?
              </Paragraph>
              <Paragraph>
                Hourly spaces is unique because it allows any kind of office
                space to be rented. What’s beneficial for smaller companies is
                that they can rent out spaces (with professional addresses) for
                any period of time that they desire. Whether or not your
                business is run out of your garage, you can still have the
                professionalism that an hourly rented office space provides.
                Investor presentations, small group meetings, training sessions,
                and many more can be held in our small work rental spaces. In
                addition to all of these features, Hourly spaces features many
                of the newly designed small work spaces for start-up companies
                with features like the ones mentioned above. Overall, Hourly
                spaces is dedicated to host all types of companies in a
                well-budgeted manner focused on your businesses successful
                future.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default SmallWorkEnvironmentsMakeSense;
