import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Image, Row, Typography } from "antd";
import { Blog3I3 } from "../../../assets/images";

const { Title, Paragraph } = Typography;

const MakeNineKMonthSittingStarBucksNineAmSixPm: FC = () => {
  return (
    <>
      <Helmet>
        <title>
          How I Make $9,000/month by sitting at Starbucks from 9a.m. to 6 p.m.
        </title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24} className="text-center">
          <Image src={Blog3I3} alt={Blog3I3} preview={false} />
        </Col>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                How I Make $9,000/month by sitting at Starbucks from 9a.m. to 6
                p.m.
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Life in New York City is one-of-a-kind. I find that in New York,
                the right apartment, job, and friends are constantly running
                through our minds. Thousands of jobs are available and the city
                is bursting with commerce and economic opportunity. Real estate
                in New York City has never slowed. There are numerous listings
                available on just about any newspaper you pick up.
              </Paragraph>
              <Paragraph>
                I’ve been living in New York City for 3 years now. I have a
                stable job and an apartment on the lower west side. About a year
                ago, I realized that I needed a second job in order to live the
                life in New York City that I’ve always wanted. Unfortunately, I
                work 9 a.m. – 6 p.m. and I often go overtime because of my busy
                job. I knew that I needed another job, but I wasn’t particularly
                trying to have one that required extra hours—because I knew I
                would lose my sanity if I started working nights.
              </Paragraph>
              <Paragraph>
                I went online and considered working for online companies. I
                considered selling my stocks in the market. I considered walking
                dogs on weekends. These were all great options for me, but they
                just weren’t offering the kind of income that I desired (and I
                knew my dog allergies would get the best of me).
              </Paragraph>
              <Paragraph>
                I heard from one of my neighbors that they rented out their
                apartment for others to use during the summer. I was curious but
                not sure that I wanted strangers to live in my house while I
                wasn’t there—plus, where would I live? My neighbor then referred
                me to Hourlyspaces.com. I toured the website (not even thinking
                about real estate) and began to realize the benefits of renting
                my apartment for only a few hours. I would be able to go to
                work, come home at my normal time, check my bank account, and
                have made money just by allowing someone to rent out my one
                bedroom apartment.
              </Paragraph>
              <Paragraph>
                My apartment was in prime location for Hourlyspaces.com. New
                York City rentals go fast and are often expensive. So, I took a
                leap of faith and registered my apartment on Hourly spaces, just
                to see how popular my apartment would become. The website was
                really easy to navigate and it gave me step-by-step instructions
                on how I could list my apartment. I took photos, wrote a
                description, listed my amenities, and posted my listing
                instantly.
              </Paragraph>
              <Paragraph>
                I was immediately amazed by how fast I received requests from
                numerous individuals wanting to rent my apartment during the
                day. It became hard to pick renters and they often struggled
                over when to take turns to rent my apartment—I was thrilled!
                Once I found the perfect renters, I would leave my apartment, go
                to work, return, and have instantly made money. Hourly spaces
                allowed me to choose trusted individuals to rent my apartment
                during the day. I now get to go to work and earn my salary at a
                job that I love, and then come home with an extra $3,000 a month
                in my pocket just for renting my apartment.
              </Paragraph>
              <Paragraph>
                This website allowed me to continue living in New York City
                while giving me an extra boost to my cash flow. I couldn’t be
                happier and I owe it all to Hourly spaces for their continued
                support and excellent customer service. I always recommend my
                friends to the website when they need an economic boost. I don’t
                see how I could make money in an easier way!
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default MakeNineKMonthSittingStarBucksNineAmSixPm;
