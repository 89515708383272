import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const HourlyApartments: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyApartments")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            When you’re a frequent traveler, it’s easy to get tired of the
            grandiose hotels. Check in times are specific and often in the
            afternoon when you’re trying to unwind from a stressful day. But
            what if the next time you traveled, you were able to rent an hourly
            apartment?
          </Paragraph>
          <Paragraph>
            Hourly apartments are available on our website for immediate rental.
            These apartments can come fully furnished—without a specific
            check-in time. The apartments are often listed by actual owners who
            will provide cleaning and maintenance services upon your request.
            When you stay in an hourly apartment, you feel like you’re staying
            in home environment. When you feel at home, it’s easier to relax and
            unwind—without having to worry about other hotel guests or maid
            service disturbing you.
          </Paragraph>
          <Paragraph>
            Hourly apartments are listed on Hourly spaces directly from home
            owners and companies. We offer the best possible apartments that are
            verified from our trusted website. We want to provide you with the
            perfect rental space—no matter what type! Hotels often don’t allow
            you to rent a room for only a few hours, but with our hourly
            apartment rentals we give you the option to rent for a few hours, a
            day, a week or even a month. Whatever your rental needs are, we can
            cater to them.
          </Paragraph>
          <Paragraph>
            Our apartments come with a full description with photos and
            amenities listed so that you can choose the best space. We realize
            that each customers requirements are different, which is why we
            offer a multitude of properties that are all unique to different
            requests. Searching for an hourly apartment is no longer a hassle
            with Hourly spaces because our website is easy to navigate and it
            generates the best possible rentals in seconds. When you rent an
            hourly apartment, you can feel as though you actually live in the
            city that you’re renting from. When you’re trying to get the full
            city experience, renting an apartment by the hour allows you to
            emerge yourself in the city lifestyle while saving you time and
            money!
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyApartments;
