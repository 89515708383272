import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { useAuth } from "../authContext/AuthContext";
import { ProfileRoutesConstant } from "../../router/components/UserRoutes";
import { AccountRoutesConstant } from "../../router/components/AccountRoute";
import { ListingRoutesConstant } from "../../router/components/ListingRoute";
import { TravelingRoutesConstant } from "../../router/components/TravelingRoute";
import { DashboardRoutesConstant } from "../../router/components/DashboardRoute";
import { MessageRoutesConstant } from "../../router/components/MessageRoute";
import "./ProfileHeader.style.scss";

const { Title, Text } = Typography;

type SubHeaderKeys =
  | "home"
  | "message"
  | "users"
  | "account"
  | "traveling"
  | "listings";

interface MainHeaderItem {
  name: string;
  key: SubHeaderKeys;
  path: string;
}

const ProfileHeader: FC = () => {
  const [activeMainListItem, setActiveMainListItem] =
    useState<SubHeaderKeys>("users");
  const [activeSubListItem, setActiveSubListItem] = useState<string>("");
  const [publicUserName, setPublicUserName] = useState<string>("");
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const mainHeaderData: MainHeaderItem[] = useMemo(
    () => [
      {
        name: "Dashboard",
        key: "home",
        path: DashboardRoutesConstant.Home.Dashboard.path,
      },
      {
        name: "Inbox",
        key: "message",
        path: MessageRoutesConstant.Message.Inbox.path,
      },
      {
        name: "Your Listings",
        key: "listings",
        path: ListingRoutesConstant.Listing.Listing.path,
      },
      {
        name: "Your Trips",
        key: "traveling",
        path: TravelingRoutesConstant.Traveling.YourTrips.path,
      },
      {
        name: "Account",
        key: "account",
        path: AccountRoutesConstant.Account.Notification.path,
      },
      {
        name: "Profile",
        key: "users",
        path: isAuthenticated
          ? `${ProfileRoutesConstant.Users.Profile.path.replace(
              ":name",
              user?.sub
            )}`
          : `${ProfileRoutesConstant.Users.Profile.path.replace(
              ":name",
              publicUserName
            )}`,
      },
    ],
    [isAuthenticated, publicUserName, user?.sub]
  );

  const subHeaderData: Record<
    SubHeaderKeys,
    { name: string; path: string; isVisible?: boolean }[]
  > = useMemo(
    () => ({
      users: [
        {
          name: "Edit Profile",
          path: ProfileRoutesConstant.Users.EditProfile.path,
          isVisible: true,
        },
        {
          name: "Change Password",
          path: ProfileRoutesConstant.Users.ChangePassword.path,
          isVisible: isAuthenticated,
        },
        {
          name: "Reference",
          path: ProfileRoutesConstant.Users.References.path,
          isVisible: true,
        },
        {
          name: "Reviews",
          path: ProfileRoutesConstant.Users.Review.path,
          isVisible: true,
        },
        {
          name: "View Public Profile",
          path: isAuthenticated
            ? `${ProfileRoutesConstant.Users.Profile.path.replace(
                ":name",
                user?.sub
              )}`
            : `${ProfileRoutesConstant.Users.Profile.path.replace(
                ":name",
                publicUserName
              )}`,
          isVisible: true,
        },
        {
          name: "Trust and Verification",
          path: ProfileRoutesConstant.Users.TrustAndVerification.path,
          isVisible: true,
        },
      ],
      account: [
        {
          name: "Notification",
          path: AccountRoutesConstant.Account.Notification.path,
          isVisible: true,
        },
        {
          name: "Payout Preferences",
          path: AccountRoutesConstant.Account.Payout.path,
          isVisible: true,
        },
        {
          name: "Transaction History",
          path: AccountRoutesConstant.Account.Transaction.path,
          isVisible: true,
        },
        {
          name: "My Wish List",
          path: AccountRoutesConstant.Account.MyWishList.path,
          isVisible: true,
        },
        {
          name: "Security",
          path: AccountRoutesConstant.Account.Security.path,
          isVisible: true,
        },
        {
          name: "Settings",
          path: AccountRoutesConstant.Account.Settings.path,
          isVisible: true,
        },
      ],
      traveling: [
        {
          name: "Your Trips",
          path: TravelingRoutesConstant.Traveling.YourTrips.path,
          isVisible: true,
        },
        {
          name: "Previous Trips",
          path: TravelingRoutesConstant.Traveling.PreviousTrips.path,
          isVisible: true,
        },
      ],
      listings: [
        {
          name: "Manage Listings",
          path: ListingRoutesConstant.Listing.Listing.path,
          isVisible: true,
        },
        {
          name: "Incoming Reservations",
          path: ListingRoutesConstant.Listing.IncomingReservations.path,
          isVisible: true,
        },
        {
          name: "Policies",
          path: ListingRoutesConstant.Listing.Policies.path,
          isVisible: true,
        },
      ],
      home: [],
      message: [],
    }),
    [isAuthenticated, publicUserName, user?.sub]
  );

  useEffect(() => {
    const splitPath = location.pathname.split("/");
    setPublicUserName(splitPath[3]);
    if (isAuthenticated) {
      // Find active main header based on the URL path
      const foundMainItem = mainHeaderData.find(
        (item) => item.path.split("/")[1] === splitPath[1]
      );

      if (foundMainItem) {
        setActiveMainListItem(foundMainItem.key);
      }

      // Find active sub header based on the URL path
      const foundSubItem = subHeaderData[foundMainItem?.key || "users"]?.find(
        (item) => item.path === location.pathname
      );

      if (foundSubItem) {
        setActiveSubListItem(foundSubItem.name);
      }
    } else {
      const foundSubItem = subHeaderData.users?.find(
        (item) => item.path === location.pathname
      );
      if (foundSubItem) setActiveSubListItem(foundSubItem?.name);
    }
  }, [isAuthenticated, location.pathname, mainHeaderData, subHeaderData]);

  // Handle main header click
  const handleMainListClick = (item: MainHeaderItem) => {
    setActiveMainListItem(item.key);
    setActiveSubListItem("");
    navigate(item.path);
  };

  // Handle sub-header click
  const handleSubListClick = (name: string, path: string) => {
    setActiveSubListItem(name);
    navigate(path);
  };

  return (
    <Row className="profile-header">
      <Col xs={24}>
        <Row>
          {mainHeaderData.map((item, i) => (
            <Col xs={24} md={8} lg={4} key={`main_header_${i}`}>
              <Title
                level={3}
                className={`main-list-item ${
                  activeMainListItem === item.key ? "active" : ""
                }`}
                onClick={() => handleMainListClick(item)}
              >
                {item.name}
              </Title>
            </Col>
          ))}
        </Row>
      </Col>
      <Col>
        <Row gutter={[24, 12]} wrap>
          {subHeaderData[activeMainListItem]
            ?.filter((sh) => sh.isVisible)
            .map((item, i) => (
              <Col key={`sub_header_${i}_${activeMainListItem}`}>
                <Text
                  className={`sub-list-item ${
                    activeSubListItem === item.name ? "active" : ""
                  }`}
                  onClick={() => handleSubListClick(item.name, item.path)}
                >
                  {item.name}
                </Text>
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default ProfileHeader;
