import { FC } from "react";
import { LeftOutlined } from "@ant-design/icons";
import "./PrevArrow.style.scss";

const PrevArrow: FC = (props: any) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} prev-arrow`} onClick={onClick}>
      <LeftOutlined />
    </div>
  );
};

export default PrevArrow;
