import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const WeddingVenueRental: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("weddingVenueRental")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Weddings are celebrated all over the world. In today’s economy,
            however, weddings can cost thousands of dollars. Between the place
            cards, bridesmaid dresses, flower arrangements, and wedding
            cake—weddings are one of the most detail-oriented events. But the
            main question on every wedding planners mind comes to be: where will
            this special day take place?
          </Paragraph>
          <Paragraph>
            Hourly spaces allows you to search our locations all over America
            with rental properties that will allow you to rent a property for
            the amount of time that you need. Weddings are already stressful,
            and finding the perfect place to get married shouldn’t be difficult.
            Wedding venue rentals from Hourly spaces will allow you to find the
            specific venue you need that matches your style (and importantly,
            your budget). All of the available amenities that the venue provides
            with your rental are listed in detail on our website making the
            search much easier. Wedding venue rental properties often provide
            chairs, tables and dinner service to clients at discounted prices
            once you book your reservation. This is just one of the various
            benefits to renting with Hourly spaces.
          </Paragraph>
          <Paragraph>
            We strive to provide our customers with quality customer service and
            will always be available to assist with questions that may arise. We
            provide our customers with details about each venue along with
            photos and basic services that each property offers. We want to
            ensure that you will find your perfect wedding venue rental with us
            which is why we have providers from all over the United States
            working to enlist their best venues.
          </Paragraph>
          <Paragraph>
            Wedding venue rentals shouldn’t cost you thousands of dollars for an
            entire day if they’re only being used for a few hours. Hourly spaces
            allows you to rent the venue you require and still remain within
            your budget. Our venues are in some of the top cities in America and
            range from a wide variety of styles. Instead of spending half of
            your budget on just the venue alone, save your money and put it
            towards more important things (like a European vacation).
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default WeddingVenueRental;
