import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { HttpStatusCode } from "axios";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";
import { post } from "../../common/services/ApiService";
import { useAuth } from "../../common/components/authContext/AuthContext";
import { API_URLS } from "../../common/constant/ApiUrls";
import "./SignIn.style.scss";

const { Paragraph } = Typography;

const SignIn: FC = () => {
  const [isForgot, setIsForgot] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { login } = useAuth();

  const onFinish = async (values: any) => {
    setIsLoading(true);
    await post<any>(API_URLS.SIGN_IN, values)
      .then((result) => {
        if (result.status === HttpStatusCode.Ok) {
          login(result.data.jwttoken);
          notification.success({ message: "Logged in successfully." });
          form.resetFields();
          setIsLoading(false);
        } else {
          notification.error({
            message: "Login failed",
            description: "Please try again",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.error,
          description: error.response.data.message,
        });
        setIsLoading(false);
      });
  };

  return (
    <div className="signin-form">
      <Helmet>
        <title>Sign In / Sign Up</title>
      </Helmet>
      <CardLayout title={t("signIn.cardTitle")} colProps={{ lg: 14 }}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Form form={form} onFinish={onFinish} autoComplete="off">
              <Row gutter={[12, 12]} justify="center">
                <Col xs={24} md={14}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "signIn.formDetails.username.requireMessage"
                        ),
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      placeholder={t("signIn.formDetails.username.placeholder")}
                      size="large"
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={14}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "signIn.formDetails.password.requireMessage"
                        ),
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input.Password
                      placeholder={t("signIn.formDetails.password.placeholder")}
                      size="large"
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={14}>
                  <Button
                    type="link"
                    className="forgot-password"
                    onClick={() => setIsForgot(true)}
                  >
                    {t("signIn.formDetails.forgotPassword")}
                  </Button>
                  {isForgot && (
                    <ForgotPassWord
                      isForgot={isForgot}
                      setIsForgot={setIsForgot}
                    />
                  )}
                </Col>
                <Col xs={24} md={14}>
                  <Row gutter={[24, 0]} align="middle">
                    <Col>
                      <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        className="text-uppercase"
                        size="large"
                        disabled={isLoading}
                      >
                        {t("signIn.formDetails.buttonText")}
                      </Button>
                    </Col>
                    <Col>
                      <Form.Item
                        name="remember_me"
                        valuePropName="checked"
                        className="mb-0"
                      >
                        <Checkbox>
                          {t("signIn.formDetails.rememberMe")}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={24}>
            <Row justify="center">
              <Col xs={24} md={14}>
                <Paragraph>
                  {t("signIn.notMember")}&nbsp;
                  <Link
                    to={PageRoutesConstant.Page.SignUp.path}
                    rel="noreferrer"
                    className="link"
                  >
                    {t("signIn.signUpNow")}
                  </Link>
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardLayout>
    </div>
  );
};

interface ForgotPassWordProps {
  isForgot: boolean;
  setIsForgot: (value: boolean) => void;
}
const ForgotPassWord: FC<ForgotPassWordProps> = ({ isForgot, setIsForgot }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
    form.resetFields();
    setIsForgot(!isForgot);
  };

  return (
    <Modal
      title={t("signIn.forgotPassword.title")}
      open={isForgot}
      onCancel={() => setIsForgot(!isForgot)}
      footer={null}
    >
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <Paragraph>{t("signIn.forgotPassword.description")}</Paragraph>
        </Col>
        <Col xs={24}>
          <Form onFinish={onFinish} autoComplete="off">
            <Row gutter={[0, 24]}>
              <Col xs={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("signIn.forgotPassword.email.requireMessage"),
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-uppercase"
                >
                  {t("signIn.forgotPassword.resetPassword")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default SignIn;
