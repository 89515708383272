import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const RentPracticeRooms: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("rentPracticeRooms")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Renting practice rooms can be used for a multitude of classes or
            practice sessions. Whether you’re a small business owner and you
            need more space or you’re a professional ballet dancer in a new city
            looking for a room to practice—Hourly spaces has your perfect space.
            We are a unique website because we provide property rentals by the
            hour for all kinds of purposes. When you search for a keyword on our
            website, like practice rooms, we search the best possible properties
            that match your description. We work with companies offering the
            rental spaces to provide our customers with the best possible rental
            space.
          </Paragraph>
          <Paragraph>
            Renting practice rooms makes sense when you need a good amount of
            space to conduct dance, music, art or even martial arts. But when
            you don’t require the property for more than 2 hours, Hourly spaces
            will allow you to rent your next practice room for the exact amount
            of time that you need. Our website will provide you available dates
            and exact prices for the required amount of time that you need. We
            list our properties in detail so that you can get the best rental
            space that you need with all amenities and photos of the space
            available to you.
          </Paragraph>
          <Paragraph>
            We understand the need to have a certain amount of space on a
            budget, that’s why we offer our rentals at special prices when you
            book by the hour, day, week or even month. Our locations are in
            prime areas all over the county that are available for immediate
            rental. Hourly spaces is determined to provide you with the best
            rental property that you need. Our search engine is unique because
            we ask for any specific factors that might go in to your next rental
            property. Once you hit search, we find the best possible location
            based on your criteria. So, next time you’re looking for a rental
            practice room for a few hours at a fraction of the cost for the
            entire day, consider Hourly spaces to make your decision faster and
            easier.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default RentPracticeRooms;
