import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// Base URL for the API
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create an instance of axios
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to make GET requests
export const get = async <T>(
  url: string,
  token?: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return api.get<T>(url, config);
};

// Function to make POST requests
export const post = async <T>(
  url: string,
  data: any,
  token?: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return api.post<T>(url, data, config);
};

// Function to make PUT requests
export const put = async <T>(
  url: string,
  data: any,
  token?: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return api.put<T>(url, data, config);
};

// Function to make DELETE requests
export const del = async <T>(
  url: string,
  token?: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return api.delete<T>(url, config);
};

// Function to make PATCH requests
export const patch = async <T>(
  url: string,
  data: any,
  token?: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return api.patch<T>(url, data, config);
};
