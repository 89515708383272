import { FC } from "react";
import { useTranslation } from "react-i18next";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { Col, Row, Typography } from "antd";
import "./ShortTermRental.style.scss";

const { Paragraph, Title } = Typography;

const ShortTermRental: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("shortTermRental")} titleTextColor="red">
      <Row className="short-term-rental">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            Short term rental is the way to go
          </Title>
          <Title level={5}>Works for all</Title>
          <Paragraph>
            Short term rentals are a great way to save money in today’s global
            economy. New methods of extra income are growing increasingly high
            due to the popularity of sharing economy and the high penetration of
            internet to the farthest corners of the world. Short term rental is
            a way you can rent out a portion of your office or residence, as
            well as other properties that you own but are not using, in order to
            earn some extra cash. Same applies to short term business space
            rentals. Nowadays, lots of travelers are young adventure enthusiasts
            trying to experience cultures, rather than those with deep budgets
            going on vacations. Therefore, if you can provide a decent space and
            maybe throw in some food and beverage while spending quality time
            with the traveler, the chances of making more money increase even if
            you only rent it out during the holiday season.Short term rentals
            are a great way to save money in today’s global economy. New methods
            of extra income are growing increasingly high due to the popularity
            of sharing economy and the high penetration of internet to the
            farthest corners of the world. Short term rental is a way you can
            rent out a portion of your office or residence, as well as other
            properties that you own but are not using, in order to earn some
            extra cash. Same applies to short term business space rentals.
            Nowadays, lots of travelers are young adventure enthusiasts trying
            to experience cultures, rather than those with deep budgets going on
            vacations. Therefore, if you can provide a decent space and maybe
            throw in some food and beverage while spending quality time with the
            traveler, the chances of making more money increase even if you only
            rent it out during the holiday season.
          </Paragraph>
          <Title level={5}>Benefits </Title>
          <Paragraph>
            As in any income system, short term rental of your free space comes
            with many benefits. Some of these include making extra income and
            meeting new people. If you keep the obvious benefit of money aside,
            the people who seek experiences such as local short term rentals,
            more often than not tend to be extremely interesting people with
            many stories to offer about their experiences and culture. These
            travelers love sharing their experiences, and you will be able to
            learn and enjoy many things about their life by interacting with
            them. If you are friendly with them and enthusiastic about their
            stories, you increase your changes of recieving a high approval
            rating.
          </Paragraph>
          <Title level={5}>Concerns</Title>
          <Paragraph>
            One of the biggest concerns with regard to renting out your space to
            a stranger would be safety. Issues with the physical property and
            payment are avoided with our background checks, allowing us to learn
            more about the guest before you accept their request. Rather than
            corresponding merely through emails, we talk to them via phone call,
            that way we have an increased chance of knowing what kind of a
            person they are. We have a set of rules regarding safety in our
            listings. All reservations are also backed by a 1 million dollar
            insurance, which means if anything happens with the reservation on
            either side, you will have nothing to worry about.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default ShortTermRental;
