import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../../components/profile-layout/ProfileLayout";
import YourTrips from "../../../pages/user/traveling/your-trips/YourTrips";
import PreviousTrips from "../../../pages/user/traveling/previous-trips/PreviousTrips";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Traveling: RouteKey;
}

const TravelingBaseRoutesConstant: IRoute = {
  Traveling: {
    Base: {
      path: "/traveling",
    },
  },
};

export const TravelingRoutesConstant: IRoute = {
  Traveling: {
    YourTrips: {
      path: `${TravelingBaseRoutesConstant.Traveling.Base.path}/your-trips`,
      element: <YourTrips />,
    },
    PreviousTrips: {
      path: `${TravelingBaseRoutesConstant.Traveling.Base.path}/previous-trips`,
      element: <PreviousTrips />,
    },
  },
};

const TravelingRoutes = () => {
  return (
    <Routes>
      {Object.keys(TravelingRoutesConstant.Traveling).map((key) => {
        const route = TravelingRoutesConstant.Traveling[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              TravelingBaseRoutesConstant.Traveling.Base.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const TravelingFlowRoutes: FC = () => {
  return (
    <ProfileLayout>
      <TravelingRoutes />
    </ProfileLayout>
  );
};

export default TravelingFlowRoutes;
