import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph } = Typography;

const HourlyHouseRental: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyHouseRental")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Paragraph>
            Hourly house rentals have become a new way to travel and settle into
            an actual house instead of a crowded hotel. Why? Hourly house
            rentals provide you with the access of staying in a house without
            paying for the mortgage and lawn service. When you want to stay in a
            different town yet you want to rent a full house because of the size
            of your family or the prime location—hourly house rentals are your
            premiere choice for travel!
          </Paragraph>
          <Paragraph>
            Hourly spaces allows you to search for an hourly house rental. We
            list the best possible houses on our website to provide you with the
            property you require. If you’re traveling to a new city but you’d
            rather get to know what daily life is like (rather than from the
            basic hotel life) then renting a house by the hour will be your best
            travel decision.
          </Paragraph>
          <Paragraph>
            Searching for the perfect house rental can be time consuming.
            Homeowners can rent their houses directly from our trusted website.
            We provide a stress-free service to easily connect you with the most
            trusted rental properties available. Our hourly house rentals often
            come fully furnished and we provide a detailed description of
            exactly what your property offers before you rent. This is in an
            effort to make your decision fast and easy.
          </Paragraph>
          <Paragraph>
            Renting a house by the hour can be beneficial to you when you only
            require the space for a certain amount of time. Some properties only
            let you rent by the month, but at Hourly spaces we shatter this
            standard and allow you to rent for exactly the amount of time you
            need. Whether you’re on vacation or business travel, we provide all
            of the hourly house rentals available for your requirements. The
            next time you decide to travel, consider renting a house by the hour
            for the full experience of the town you’re staying in—while staying
            in your budget.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyHouseRental;
