import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Popover, Row, Typography } from "antd";
import { Helmet } from "react-helmet";
import { Rule } from "antd/es/form";
import {
  CheckOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";
import {
  alphabetsPatternRegex,
  passwordPatternRegex,
  usernamePatternRegex,
} from "../../common/utilities/validator";
import { Captcha } from "../../common/components/recaptcha/Recaptcha";
import "./SignUp.style.scss";

const { Paragraph } = Typography;

const SignUp: FC = () => {
  const [captchaError, setCaptchaError] = useState<string>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    if (captchaToken) {
      console.log("Form Values:", values);
      form.resetFields();
      navigate(PageRoutesConstant.Page.SignIn.path);
    } else {
      setCaptchaError("Please confirm that you are not a robot.");
    }
  };

  const formFields: RenderFormItemProps[] = [
    {
      name: "first_name",
      placeholder: "firstName.placeholder",
      requireMessage: "firstName.requireMessage",
      prefix: <UserOutlined />,
      type: "text",
      rules: [
        {
          pattern: alphabetsPatternRegex,
          message: t("firstName.patternMessage"),
          validateTrigger: ["onBlur", "onClick"],
        },
      ],
    },
    {
      name: "last_name",
      placeholder: "lastName.placeholder",
      requireMessage: "lastName.requireMessage",
      prefix: <UserOutlined />,
      type: "text",
      rules: [
        {
          pattern: alphabetsPatternRegex,
          message: t("lastName.patternMessage"),
          validateTrigger: ["onBlur", "onClick"],
        },
      ],
    },
    {
      name: "username",
      placeholder: "userName.placeholder",
      requireMessage: "userName.requireMessage",
      prefix: <UserOutlined />,
      type: "text",
      rules: [
        {
          pattern: usernamePatternRegex,
          message: t("userName.patternMessage"),
          validateTrigger: ["onBlur", "onClick"],
        },
      ],
    },
    {
      name: "email",
      placeholder: "email.placeholder",
      requireMessage: "email.requireMessage",
      prefix: <MailOutlined />,
      type: "text",
      rules: [
        {
          type: "email",
          message: t("email.validMessage"),
          validateTrigger: ["onBlur", "onClick"],
        },
      ],
    },
    {
      name: "password",
      placeholder: "password.placeholder",
      requireMessage: "password.requireMessage",
      prefix: <LockOutlined />,
      type: "password",
      rules: [
        {
          pattern: passwordPatternRegex,
          message: t("password.patternMessage"),
          validateTrigger: ["onBlur", "onClick"],
        },
      ],
    },
    {
      name: "confirmpassword",
      placeholder: "confirmPassword.placeholder",
      requireMessage: "confirmPassword.requireMessage",
      prefix: <LockOutlined />,
      type: "password",
      dependencies: ["password"],
      rules: [
        ({ getFieldValue }: any) => ({
          validator(_: any, value: string) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            } else if (!getFieldValue("password")) {
              return Promise.reject(
                new Error(t("confirmPassword.nullPasswordMessage"))
              );
            }
            return Promise.reject(new Error(t("confirmPassword.matchMessage")));
          },
          validateTrigger: ["onBlur", "onClick"],
        }),
      ],
    },
  ];

  return (
    <div className="signup-form">
      <Helmet>
        <title>Sign Up for the site</title>
      </Helmet>
      <CardLayout title={t("signUp.cardTitle")} colProps={{ lg: 14 }}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Form form={form} onFinish={onFinish} autoComplete="off">
              <Row gutter={[12, 12]} justify="center">
                {formFields.map((field: any) => RenderFormItem(field))}
                <Col xs={24} md={14}>
                  <Captcha
                    error={captchaError}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    setCaptchaError={setCaptchaError}
                  />
                </Col>
                <Col xs={24} md={14}>
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="text-uppercase"
                      size="large"
                    >
                      {t("signUp.formDetails.buttonText")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={24}>
            <Row justify="center">
              <Col xs={24} md={14}>
                <Paragraph>
                  {t("signUp.alreadyMember")}&nbsp;
                  <Link
                    to={PageRoutesConstant.Page.SignIn.path}
                    rel="noreferrer"
                    className="link"
                  >
                    {t("signUp.loginHere")}
                  </Link>
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardLayout>
    </div>
  );
};

interface RenderFormItemProps {
  name: string;
  placeholder: string;
  requireMessage: string;
  prefix?: React.ReactNode;
  type?: "text" | "password";
  dependencies?: string[];
  rules?: Rule[];
}

const RenderFormItem: FC<RenderFormItemProps> = ({
  name,
  placeholder,
  requireMessage,
  prefix,
  type = "text", // default to 'text'
  dependencies,
  rules = [],
}) => {
  const { t } = useTranslation();

  const content = (
    <Row>
      <Col span={24} className="popover-content">
        <Paragraph className="mb-0">
          {<CheckOutlined />} At least one digit [0-9].
        </Paragraph>
        <Paragraph>
          {<CheckOutlined />} At least one lowercase Latin character [a-z].
        </Paragraph>
        <Paragraph>
          {<CheckOutlined />} At least one uppercase Latin character [A-Z].
        </Paragraph>
        <Paragraph>
          {<CheckOutlined />} At least one special character like ! @ # & ( ).
        </Paragraph>
        <Paragraph>
          {<CheckOutlined />} A length of at least 8 characters and a maximum of
          20 characters.
        </Paragraph>
      </Col>
    </Row>
  );

  const inputComponent =
    type === "password" ? (
      <Input.Password
        placeholder={t(placeholder)}
        size="large"
        prefix={prefix}
        addonAfter={
          name === "password" && (
            <Popover content={content} title="Password must contain">
              <InfoCircleOutlined />
            </Popover>
          )
        }
      />
    ) : (
      <Input placeholder={t(placeholder)} size="large" prefix={prefix} />
    );

  return (
    <Col xs={24} md={14} key={name}>
      <Form.Item
        name={name}
        dependencies={dependencies}
        rules={[
          {
            required: true,
            message: t(requireMessage),
          },
          ...rules,
        ]}
        className="mb-0"
      >
        {inputComponent}
      </Form.Item>
    </Col>
  );
};
export default SignUp;
