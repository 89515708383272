import { FC } from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { BlogsRoutesConstant } from "../../common/router/components/BlogsRoute";
import "./Blog.style.scss";

const Blog: FC = () => {
  return (
    <div className="blog">
      <Row>
        <Col xs={24} className="blog-list">
          <ListGroup className="border">
            <Row>
              {Object.keys(BlogsRoutesConstant.Blogs).map((key, index) => {
                return BlogsRoutesConstant.Blogs[key].title ? (
                  <Col xs={24} key={`blog_${index}`}>
                    <ListGroup.Item
                      as={Link}
                      action
                      to={BlogsRoutesConstant.Blogs[key].path}
                    >
                      <h1 className="title mb-0">
                        {BlogsRoutesConstant.Blogs[key].title}
                      </h1>
                    </ListGroup.Item>
                  </Col>
                ) : null;
              })}
            </Row>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};
export default Blog;
