import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Form, Input, Radio, Row, Space, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const { Title } = Typography;
const { TextArea } = Input;

const Settings: FC = () => {
  const [cancelAccount, setCancelAccount] = useState<boolean>(false);

  const handleCancelAccount = (value: any) => {
    console.log(value);
  };
  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      {cancelAccount ? (
        <CardLayout containerClassName="p-0">
          <Form onFinish={handleCancelAccount} layout="inline">
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Title level={4}>Tell us why you're leaving</Title>
              </Col>
              <Col>
                <Form.Item
                  name="reason"
                  rules={[
                    { required: true, message: "Please select a one reason." },
                  ]}
                >
                  <Radio.Group>
                    <Row gutter={[12, 24]}>
                      <Col xs={24}>
                        <Radio value="User have safety concerns">
                          I have safety concerns.
                        </Radio>
                      </Col>
                      <Col xs={24}>
                        <Radio value="User have privacy concerns">
                          I have privacy concerns.
                        </Radio>
                      </Col>
                      <Col xs={24}>
                        <Radio value="User don't find it useful">
                          I don't find it useful.
                        </Radio>
                      </Col>
                      <Col xs={24}>
                        <Radio value="User don't understand how to use it">
                          I don't understand how to use it.
                        </Radio>
                      </Col>
                      <Col xs={24}>
                        <Radio value="It's temporary; User will be back">
                          It's temporary; I'll be back.
                        </Radio>
                      </Col>
                      <Col xs={24}>
                        <Radio value="Other">Other</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Space direction="vertical" className="w-100">
                  <Title level={5}>Care to tell us more?</Title>

                  <Form.Item name="details">
                    <TextArea rows={4} />
                  </Form.Item>
                </Space>
              </Col>

              <Col xs={24}>
                <Space direction="vertical" className="w-100">
                  <Title level={5} className="mb-0">
                    Can we contact you for more details?
                  </Title>
                  <Form.Item name="contact_ok">
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Space>
              </Col>

              <Col xs={24}>
                <Space direction="vertical">
                  <Title level={5}>What's going to happen</Title>
                  <ul>
                    <li>Your profile and any listings will disappear.</li>
                    <li>We'll miss you terribly.</li>
                  </ul>
                </Space>
              </Col>

              <Col>
                <Space>
                  <Button type="primary" size="large" htmlType="submit">
                    Cancel my account
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => setCancelAccount(false)}
                  >
                    Don't cancel account
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </CardLayout>
      ) : (
        <CardLayout title="Cancel My Account" containerClassName="p-0">
          <Row>
            <Col xs={24}>
              <Button
                type="primary"
                size="large"
                onClick={() => setCancelAccount(true)}
              >
                Cancel Account
              </Button>
            </Col>
          </Row>
        </CardLayout>
      )}
    </>
  );
};
export default Settings;
