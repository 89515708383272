import { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";
import { ProfileRoutesConstant } from "../../../common/router/components/UserRoutes";
import { PageRoutesConstant } from "../../../common/router/components/PageRoutes";
import { SearchRoutesConstant } from "../../../common/router/components/SearchRoutes";

const { Title, Paragraph } = Typography;

const VirtualOfficeRentalsWork: FC = () => {
  return (
    <>
      <Helmet>
        <title>How Virtual Office Rentals Work</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                How Virtual Office Rentals Work
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                The demand for virtual office rentals has increasing
                exponentially in recent years, but few companies appear to know
                what it really means to have a virtual office. Some have heard
                of virtual offices, but think that using one just means paying
                for an address. Start-ups and telecommuters benefit the most
                from virtual offices today—and by much more than just a mailbox.
                So, how do virtual office rentals work? HourlySpaces is here to
                ease your mind and offer you the best deals possible.
              </Paragraph>
              <Title level={4}>Full Service</Title>
              <Paragraph>
                NGIN offers short term rentals in the heart of Boston. Search
                them on our website using the link today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "NGIN_Workplace"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/NGIN_Workplace
                </Link>
              </Paragraph>
              <Paragraph>
                Thrive Coworking offers the best conference rooms located in
                Atlanta, Georgia. To search available listings, visit the link
                today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "workatthrive"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/workatthrive
                </Link>
              </Paragraph>
              <Title level={4}>Office Space Rental</Title>
              <Paragraph>
                Although your office is virtual, you may occasionally need to
                use actual space to conduct business. Virtual offices provide
                that space in the form of conference room rental, meeting space
                rental, and hourly office space rental. Why rental? Because
                until the minute you need it, you won’t have to pay for it.
                Normally, businesses pay large sums of money to lease an office
                for substantial amounts of time. Whether or not they’re actually
                using the conference room or office space doesn’t matter—they’re
                still required to pay. Virtual offices are completely different
                because you only pay for the office space when you need it.
              </Paragraph>
              <Paragraph>
                Check out Intelligent Office for premiere office rentals in the
                Boston area. Follow the link today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "intelligentofficeboston"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/intelligentofficeboston
                </Link>
              </Paragraph>
              <Paragraph>
                Cubico is registered on our website and lists hourly office
                rentals in NYC. To view all available listings follow the link
                today! today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "Cubico-Soho"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/Cubico-Soho
                </Link>
              </Paragraph>
              <Title level={4}>Sharing Space</Title>
              <Paragraph>
                Unlike an office dedicated you and your team—virtual offices are
                shared. Sometimes it’s as simple as scheduling a time for
                ameeting space rental in advance, because other businesses may
                be using the services. When business use spaces for long periods
                of time during ashort term rental, they may actually share the
                office room with other companies. This can lead to a positive,
                collaborative environment—especially for small businesses. If
                you only have a few employees in your business team it may be
                refreshing to see others in the office—or use them for
                networking opportunities.
              </Paragraph>
              <Paragraph>
                BootUp offers professional meeting space rentals in the San
                Francisco area. Follow the link to view all available listings
                today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "BootUp"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/BootUp
                </Link>
              </Paragraph>
              <Title level={4}>Flexibility</Title>
              <Paragraph>
                The key to virtual offices is flexibility. They’re flexible
                because users don’t have to buy an office or sign any long term
                leases. So, if a company is just starting up they may want to
                expand or add employees at a later time. Virtual office rentals
                easily advocate for this type of expansion. Conversely,
                downsizing is also made easy. It’s simply a matter of
                coordinating with rental services to pay for another desk or
                take one away in a short term rental.
              </Paragraph>
              <Paragraph>
                Although a lot of business owners are familiar with the idea of
                working virtually and telecommuting, there’s much more to a
                virtual office than simply collaborating online. Virtual offices
                like
                <Link
                  to={PageRoutesConstant.Page.Home.path}
                  className="link mx-1"
                >
                  Hourly Spaces
                </Link>
                give companies that don’t have their own offices all the tools,
                resources, and rooms they need to make their business
                professional and successful.
              </Paragraph>
              <Paragraph>
                Intelligent Office is located in Chicago and offers office
                rental deals on our website! Follow the link to search today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "intellchicago"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/intellchicago
                </Link>
              </Paragraph>
              <Paragraph>
                Virtual office rentals offer a way for companies to stay
                connected while not in an actual office building.
                HourlySpaceshosts numerous rentals from all across the nation to
                bring you the best deals on premiere spaces. So, the next time
                you’re thinking about continuing your business outside of a
                traditional office setting consider HourlySpaces office rentals.
              </Paragraph>
              <Paragraph>
                Your perfect space is just a click away. To search for available
                rentals near you, follow the link to the destination of your
                choice! Martha’s Vineyard,
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Boston`}
                  className="link mx-1"
                >
                  Boston,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Washington DC`}
                  className="link mx-1"
                >
                  Washington DC,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=New York, NY, United States`}
                  className="link mx-1"
                >
                  New York City (NYC),
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Philadelphia`}
                  className="link mx-1"
                >
                  Philadelphia,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Chicago, IL, United States`}
                  className="link mx-1"
                >
                  Chicago,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Atlanta, GA, United States`}
                  className="link mx-1"
                >
                  Atlanta,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Miami, FL, United States`}
                  className="link mx-1"
                >
                  Miami,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=San Francisco, CA, United States`}
                  className="link mx-1"
                >
                  San Francisco (SF).
                </Link>
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default VirtualOfficeRentalsWork;
