import { FC } from "react";
import { useTranslation } from "react-i18next";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { Col, Row, Typography } from "antd";
import "./HourlyOfficeSpaceRental.style.scss";

const { Paragraph, Title } = Typography;

const HourlyOfficeSpaceRental: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyOfficeSpace")} titleTextColor="red">
      <Row className="hourly-office-space">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            World class office spaces for rent by the hour
          </Title>
          <Title level={5}>The Beginning</Title>
          <Paragraph>
            It is the era of startups, low budget small businesses and making
            things work even without great capital. Young entrepreneurs are
            growing more than ever in various fields around the world. In this
            environment where innovation is admired over how much money you
            initially make, you do not necessarily have to have a work space of
            your own in order to be deemed credible. While you and your team
            work in the comfort of your own homes through a well-connected
            virtual office system, you can always hire an hourly office space
            for your formal meetings, brainstorming sessions, client meetings or
            any other reason that you absolutely require a proper office space.
            You can rent them hourly, daily, weekly or monthly and a space that
            fits your needs.
          </Paragraph>
          <Title level={5}>Demand</Title>
          <Paragraph>
            In most cases, many large office spaces are not filled entirely and
            are grabbed by startups and entrepreneurs for discounted prices
            which also gives an added benefit of networking with like-minded
            people. The community that is formed through a shared office space
            often ends up collaborating and helping each other reach their
            targets faster and easier. With the breadth of the company that you
            rend the office space in, you can ask for a few choices in order to
            select the right space to meet the budget and all the other needs.
            Selecting the right temporary office space differs with the exact
            need you are going to use it for. For example, if you are planning
            to meet a client, you may meed a room with stylish décor in order to
            give out a sophisticated impression about your company, while if it
            is a weekly meet up with all your long distance employees, you may
            go for a casual space with lots of room.
          </Paragraph>
          <Title level={5}>What you get</Title>

          <Paragraph>
            Despite what kind of outlook you chose for the office space, there
            are a few essentials you need to be getting before you spend any
            money. Wifi is essential (of course!) and this is not only for your
            personal needs of the internet, but for tools that increase
            productivity such as video conferences and web telecasts. Most of
            the hourly office spaces are now designed in a way that is different
            from the traditional office appearance of a table and a few chairs
            along with a filing cabinet. You will be able to get a space with
            comfortable couches, modern furniture and even your very own
            receptionist and lounge area if you are willing to spend a little
            more.
          </Paragraph>
          <Title level={5}>Transition</Title>
          <Paragraph>
            Our lives continue to transition towards becoming virtually virtual,
            the need of a work place can occur, especially if you are doing the
            kind of business which requires you to meet with clients, suppliers
            and other business people regularly. In todays competition, you need
            a professional space as your business grows. This does not only
            create a good impression for your company to an outsider, but also
            helps you to focus more on your work. Short term rental for your
            meetings can save you lots of money, especially if you're an
            entrepreneur renting an hourly office space.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyOfficeSpaceRental;
