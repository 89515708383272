import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { Helmet } from "react-helmet";

const { Title, Paragraph } = Typography;

const HourlySpacesLetsRentRoomsHourEverythingNoSex: FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Hourly Spaces lets you rent rooms by the hour for everything but
          No-Sex
        </title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Hourly Spaces lets you rent rooms by the hour for everything but
                No-Sex
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Now you can rent a private room by the half hour thanks to a new
                service called HourlySpaces, which, they will have you know, is
                totally not at all for sex. Instead, it’s a “quiet space in the
                middle of the hustle and bustle” that you use for wholesome
                activities like relaxing and napping and getting work done and
                not-sex.
              </Paragraph>
              <Paragraph>
                Basically, as New Yorker explains, it’s Zipcar, but for spaces
                instead of wheels. If you need to go to Costco but you don’t
                have a car, you borrow one. If you need to absolutely not have
                sex for an hour or two, you rent from HourlySpaces . The spaces
                are “stocked with everything you need — and nothing you don’t.”
                That means yoga mats and wifi and desks and choices and chairs
                and condoms. Hahahaha just kidding! No one will ever have sex in
                these rent-per-hour rooms what is wrong with you? Over the
                course of “months of usage, we know [sex] just doesn’t happen,”
                HourlySpaces’s NYC City Manager tells us. Okay, NYC City
                Manager, I trust you. No sex ever, only yoga and iPhone charging
                for us.
              </Paragraph>
              <Paragraph>
                Right now, HourlySpaces is only available in major cities across
                the United States, but they’ve got plans to expand to other
                cities soon. A room runs you $10-$25 an hour, with the first
                hour on the house. So nap! Meditate! Apply to graduate school!
                Do whatever you do in a private room! Except have sex. Nobody
                does that.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default HourlySpacesLetsRentRoomsHourEverythingNoSex;
