import { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const WebsiteLunchtimeQuickies: FC = () => {
  return (
    <>
      <Helmet>
        <title>A website for lunchtime quickies</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                A website for lunchtime quickies
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                What if I told you that you could rent a clean, private space
                that included a couch, a desk, free Wi-Fi, possibly a bed, and
                maybe a shower for $20 an hour? What is the first thing you’d
                think of using that space for? Sex.
              </Paragraph>
              <Paragraph>
                The founders of HourlySpaces, a new app that connects
                city-dwellers in major cities across the US has made available
                spaces with the above amenities, believe their technology is
                being used by hip, urban, mobile-workforce folks who just need
                to “take refuge from the daily grind” and experience “the
                perfect time-out experience.” You know what I imagine many of
                their clients are really doing in those rooms? Not having sex.
              </Paragraph>
              <Paragraph>
                The company wants to create a temporary home or office for you,
                you’re not supposed to be doing the one thing that many of us do
                in our homes, and quite a few of us do in our offices.
              </Paragraph>
              <Paragraph>
                HourlySpaces’s prudish response may not seem like a big deal,
                but it’s actually endemic of the rampant sex-negativity of the
                VC-funded startup scene. Despite the fact that there is quite
                literally millions to be made on apps and websites that focus on
                sexuality, it’s very challenging for startups that contain
                “adult content” to find funding.
              </Paragraph>
              <Paragraph>
                It’s always about the fear of what everyone else will think.”
                Silicon Valley boasts of being so cutting-edge, and yet when a
                company wants to talk about sex, the men who hold its purse
                strings are like eight-year-old boys running away with hands
                over their ears. As a result, even the blowjob-on-demand service
                <Link
                  to="https://www.grindr.com"
                  className="link mx-1"
                  target="_blank"
                >
                  Grindr
                </Link>
                has rules stating that
                <Link
                  to="https://www.grindr.com/profile-guidelines"
                  className="link mx-1"
                  target="_blank"
                >
                  nothing explicit can be included in profiles
                </Link>
                and refers to itself euphemistically as a “friend finder.”
              </Paragraph>
              <Paragraph>
                Personally, I’m sick of it. I’m ready for Silicon Valley leaders
                to step up and be adults who acknowledge and celebrate sex as an
                awesome part of life. This puritanical prudishness is not only
                hurting us as a society but it’s also hurting these investors’
                bottom lines. Seems to me that if apps like HourlySpaces and
                Grindr could be honest about what they exist for without fear of
                losing funding, we’d all be better off.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default WebsiteLunchtimeQuickies;
