const emailPatternRegex: RegExp = /^([\w\\.\\-]+)@([\w\\-]+)((\.(\w){2,3})+)$/i;
const alphabetsPatternRegex: RegExp = /^[a-zA-Z]*$/;
const usernamePatternRegex: RegExp = /^[a-zA-Z1-9]*$/;
const passwordPatternRegex: RegExp =
  /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[-@#$%^&*_!+=\\[\]{}|:',.?/`~"();])|(?=.*[a-z])(?=.*\d)(?=.*[-@#$%^&*_!+=\\[\]{}|:',.?/`~"();])|(?=.*[A-Z])(?=.*\d)(?=.*[-@#$%^&*_!+=\\[\]{}|:',.?/`~"();]))[-A-Za-z0-9@#$%^&*_!+=\\[\]{}|:',.?/`~"();]{8,16}$/;
const phonePatternRegex: RegExp = /^[0-9+ ]+$/;

const validateEmails = (_: any, value: string) => {
  if (!value) {
    // no value, so no need for validation
    return Promise.resolve();
  }

  const emails = value.split(",").map((email) => email.trim());
  const invalidEmails = emails.filter(
    (email) => !emailPatternRegex.test(email)
  );

  if (invalidEmails.length > 0) {
    return Promise.reject(
      new Error(`Invalid email(s): ${invalidEmails.join(", ")}`)
    );
  }

  return Promise.resolve();
};

export {
  emailPatternRegex,
  alphabetsPatternRegex,
  usernamePatternRegex,
  passwordPatternRegex,
  phonePatternRegex,
  validateEmails,
};
