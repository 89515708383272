import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ProfileRoutesConstant } from "../../router/components/UserRoutes";
import { PageRoutesConstant } from "../../router/components/PageRoutes";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
  user: any;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const TOKEN_KEY = "authToken"; // LocalStorage key for the token

// Check if token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    return true; // Invalid token
  }
};

// Retrieve token from localStorage
const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

// Store token in localStorage
const storeToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

// Remove token from localStorage
const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

//export const profileData=()=

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); // Loading state to handle initial check
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = getToken();

    // Function to handle authentication and user decoding
    const handleAuth = (token: string | null) => {
      if (token && !isTokenExpired(token)) {
        try {
          const decoded = jwtDecode<any>(token);
          setUser(decoded);
          setIsAuthenticated(true);
          setLoading(false); // Authentication check complete
        } catch (error) {
          console.error("Token invalid or decoding failed");
          logout();
        }
      } else {
        logout(); // Logout if token is expired or null
      }
    };

    handleAuth(token); // Check token on mount and on location change

    if (
      token &&
      !isTokenExpired(token) &&
      (location.pathname === PageRoutesConstant.Page.SignIn.path ||
        location.pathname === PageRoutesConstant.Page.SignUp.path)
    ) {
      navigate(PageRoutesConstant.Page.Home.path);
    }
  }, [location.pathname, navigate]);

  const login = async (token: string) => {
    if (!isTokenExpired(token)) {
      storeToken(token);
      const decoded = jwtDecode<any>(token);
      setUser(decoded);
      setIsAuthenticated(true);

      const userName = decoded.sub as string;
      const from =
        location.state?.from?.pathname ||
        ProfileRoutesConstant.Users.Profile.path.replace(":name", userName); // Default to home if "from" is not available
      
        // Redirect back to the original page or home
      navigate(from, { replace: true });
    } else {
      console.error("Token is expired, cannot log in");
      logout();
    }
  };

  const logout = () => {
    removeToken();
    setUser(null);
    setIsAuthenticated(false);
    setLoading(false);
  };

  // Show loading state while authentication is being checked
  if (loading) {
    return (
      <Spin
        size="large"
        tip="Loading..."
        indicator={<LoadingOutlined spin />}
        fullscreen
      ></Spin>
    );
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
