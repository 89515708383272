import { Neighborhoods1, Neighborhoods2, Neighborhoods3, Neighborhoods4, Neighborhoods5, Neighborhoods6, Neighborhoods7, Neighborhoods8 } from "../../assets/images";

export const spaceCityData = [
  {
    city: "Washington DC",
    bannerImage: Neighborhoods2,
    description:
      "Instead of skyscrapers, pillars of American history, culture, and politics dominate DC.",
    searchFor: "Search for spaces in Washington DC",
    getAroundWith: ["Cars", "Public", "Transit"],
    awesomePlacesToRent: "See Washington DC listings",
    knownFor:
      "The White House, the President, politics, Capitol Hill, US history, American monuments, the National Mall, the Pentagon, Georgetown, suits and ties",
    availableServices:
      "hourly office space Washington DC, short term rental Washington DC, hourly room rental Washington DC, hourly office space rental Washington DC, rent conference room Washington DC, virtual office Washington DC, meeting room Washington DC, rent office space Washington DC, wedding venue Washington DC, hourly office rental Washington DC, hourly conference room rental Washington DC, event space rental Washington DC, party venue Washington DC, short term office space rental Washington DC, hourly hotels Washington DC",
    seeAll: "See all spaces in Washington DC",
  },
  {
    city: "New York",
    bannerImage: Neighborhoods1,
    description:
      "From dawn until dusk until dawn again, trendsetting New York City never sleeps and never lets up.This is an awesome city",
    searchFor: "Search for spaces in New York",
    getAroundWith: ["Cars", "Public", "Transit", "bikes"],
    awesomePlacesToRent: "See New York listings",
    knownFor:
      "Bright lights, long nights, tall buildings, high fashion, Lady Liberty, Central Park, Broadway, Wall Street, museums, the cutting edge, setting trends",
    availableServices:
      "hourly office space New York, short term rental New York, hourly room rental New York, hourly office space rental New York, rent conference room New York, virtual office New York, meeting room New York, rent office space New York, wedding venue New York, hourly office rental New York, hourly conference room rental New York, event space rental New York, party venue New York, short term office space rental New York, hourly hotels New York",
    seeAll: "See all spaces in New York",
  },
  {
    city: "Boston",
    bannerImage: Neighborhoods5,
    description:
      "24th largest city in the United States, Boston is the economic and cultural anchor of a substantially larger metropolitan area called Greater Boston, home to 4.7 million people and the tenth-largest metropolitan area in the country. Greater Boston as a commuting region is home to 8.1 million people, making it the sixth-largest combined statistical area in the United States.",
    searchFor: "Search for spaces in Boston",
    getAroundWith: ["Public Transit", "Cars"],
    awesomePlacesToRent: "See Boston listings",
    knownFor:
      "The city is known for many colleges and universities make Boston an international center of higher education, including law, medicine, engineering, and business, and the city is considered to be a world leader in innovation and entrepreneurship.",
    availableServices:
      "hourly office space Boston, short term rental Boston, hourly room rental Boston, hourly office space rental Boston, rent conference room Boston, virtual office Boston, meeting room Boston, rent office space Boston, wedding venue Boston, hourly office rental Boston, hourly conference room rental Boston, event space rental Boston, party venue Boston, short term office space rental Boston, hourly hotels Boston",
    seeAll: "See all spaces in Boston",
  },
  {
    city: "Philadelphia",
    bannerImage: Neighborhoods4,
    description:
      "Philadelphia is the largest city in the Commonwealth of Pennsylvania and the fifth-most populous in the United States, with an estimated population in 2014 of 1,560,297 In the Northeastern United States, at the confluence of the Delaware and Schuylkill rivers, Philadelphia is the economic and cultural anchor of the Delaware Valley, a metropolitan area home to 7.2 million people and the eighth-largest combined statistical area in the United States.",
    searchFor: "Search for spaces in Philadelphia",
    getAroundWith: ["Bike", "Walk", "Public", "Transit", "Car"],
    awesomePlacesToRent: "See Philadelphia listings",
    knownFor:
      "Philadelphia, Pennsylvania’s largest city, is notable for its rich history, on display at the Liberty Bell, Independence Hall – where the Declaration of Independence and Constitution were signed – and other American Revolutionary sites. No less iconic are the steps of the Philadelphia Museum of Art, immortalized by Sylvester Stallone’s triumphant run in the film 'Rocky.'",
    availableServices:
      "hourly office space Philadelphia, short term rental Philadelphia, hourly room rental Philadelphia, hourly office space rental Philadelphia, rent conference room Philadelphia, virtual office Philadelphia, meeting room Philadelphia, rent office space Philadelphia, wedding venue Philadelphia, hourly office rental Philadelphia, hourly conference room rental Philadelphia, event space rental Philadelphia, party venue Philadelphia, short term office space rental Philadelphia, hourly hotels Philadelphia",
    seeAll: "See all spaces in Philadelphia",
  },
  {
    city: "Chicago",
    bannerImage: Neighborhoods6,
    description:
      "Chicago is the third most populous city in the United States. With over 2.7 million residents, it is the most populous city in the state of Illinois and the Midwestern United States, and the county seat of Cook County. The Chicago metropolitan area, often referred to as Chicagoland, has nearly 10 million people and is the third-largest in the U.S.",
    searchFor: "Search for spaces in Chicago",
    getAroundWith: ["Public Transit", "Cars", "Taxi"],
    awesomePlacesToRent: "See Chicago listings",
    knownFor:
      "Chicago, on Lake Michigan in Illinois, is among the largest cities in the U.S. Famed for its bold architecture, it has a skyline bristling with skyscrapers such as the iconic John Hancock Center, sleek, 1,451-ft. Willis Tower and neo-Gothic Tribune Tower. The city is also renowned for its museums, including the Art Institute and its expansive collections, including noted Impressionist works. The culture of Chicago, Illinois is known for the invention or significant advancement of several performing arts, including improvisational comedy, house music, blues, gospel, jazz, and soul. The city is additionally known for various popular culinary dishes, including deep-dish pizza, the Chicago-style hot dog and the Italian beef sandwich.",
    availableServices:
      "hourly office space Chicago, short term rental Chicago, hourly room rental Chicago, hourly office space rental Chicago, rent conference room Chicago, virtual office Chicago, meeting room Chicago, rent office space Chicago, wedding venue Chicago, hourly office rental Chicago, hourly conference room rental Chicago, event space rental Chicago, party venue Chicago, short term office space rental Chicago, hourly hotels Chicago",
    seeAll: "See all spaces in Chicago",
  },
  {
    city: "Atlanta",
    bannerImage: Neighborhoods7,
    description:
      "Atlanta is the capital of and the most populous city in the U.S. state of Georgia, with an estimated 2015 population of 463,878. Atlanta is the cultural and economic center of the Atlanta metropolitan area, home to 5,522,942 people and the ninth largest metropolitan area in the United States. Atlanta is the county seat of Fulton, and a small portion of the city extends eastward into DeKalb County.",
    searchFor: "Search for spaces in Atlanta",
    getAroundWith: ["Public Transit", "Cars", "Bike", "Taxi"],
    awesomePlacesToRent: "See Atlanta listings",
    knownFor:
      "The ATL. A-town. Hotlanta. Whatever you want to call it, Atlanta is known for many different things: its diverse musical heritage (John Mayer got his big break in Atlanta, as did Ludacris and Usher); its Southern hospitality; its sports teams (the Braves, Falcons and Hawks, to name a few) and Hartsfield-Jackson International Airport, ‘the world’s busiest passenger airport.’",
    availableServices:
      "hourly office space Atlanta, short term rental Atlanta, hourly room rental Atlanta, hourly office space rental Atlanta, rent conference room Atlanta, virtual office Atlanta, meeting room Atlanta, rent office space Atlanta, wedding venue Atlanta, hourly office rental Atlanta, hourly conference room rental Atlanta, event space rental Atlanta, party venue Atlanta, short term office space rental Atlanta, hourly hotels Atlanta",
    seeAll: "See all spaces in Atlanta",
  },
  {
    city: "Miami",
    bannerImage: Neighborhoods8,
    description:
      "Miami, at Florida's southeastern tip, is a vibrant city whose Cuban influence is reflected in the cafes and cigar shops that line Calle Ocho in Little Havana. Miami Beach, on barrier islands across the turquoise waters of Biscayne Bay, is home to glamorous South Beach, famed for its colorful art deco buildings, white sand, surfside hotels and trendsetting nightclubs.",
    searchFor: "Search for spaces in Miami",
    getAroundWith: ["Public Transit", "Taxi", "Bike", "Trolley"],
    awesomePlacesToRent: "See Miami listings",
    knownFor:
      "Miami was ranked as the richest city in the United States (of four U.S. cities included in the survey) and the world's fifth-richest city, in terms of purchasing power. In addition to such annual festivals like Calle Ocho Festival and Carnaval Miami, Miami is home to many entertainment venues, theaters, museums, parks and performing arts centers. The cuisine of Miami is a reflection of its diverse population, with a heavy influence especially from Caribbean cuisine and from Latin American cuisine. By combining the two with American cuisine, it has spawned a unique South Florida style of cooking known as Floribbean cuisine. Floribbean cuisine is widely available throughout Miami and South Florida, and can be found in restaurant chains such as Pollo Tropical. Miami is also known for its seafood, with many seafood restaurants located along the Miami River, and in and around Biscayne Bay.",
    availableServices:
      "hourly office space Miami, short term rental Miami, hourly room rental Miami, hourly office space rental Miami, rent conference room Miami, virtual office Miami, meeting room Miami, rent office space Miami, wedding venue Miami, hourly office rental Miami, hourly conference room rental Miami, event space rental Miami, party venue Miami, short term office space rental Miami, hourly hotels Miami",
    seeAll: "See all spaces in Miami",
  },
  {
    city: "San Francisco",
    bannerImage: Neighborhoods3,
    description:
      "San Francisco is the cultural, commercial, and financial center of Northern California and the only consolidated city-county in California. It has a density of about 18,451 people per square mile, making it the the second-most densely populated major city in the United States after New York City. The city and its surrounding areas are known as the San Francisco Bay Area and has a population of 8.7 million.",
    searchFor: "Search for spaces in San Francisco",
    getAroundWith: ["Public Transit", "Cars", "Taxi"],
    awesomePlacesToRent: "See San Francisco listings",
    knownFor:
      "A popular tourist destination, San Francisco is known for its cool summers, fog, steep rolling hills, eclectic mix of architecture, and landmarks, including the Golden Gate Bridge, cable cars, the former Alcatraz Federal Penitentiary Fisherman's Wharf, and its Chinatown district.",
    availableServices:
      "hourly office space San Francisco, short term rental San Francisco, hourly room rental San Francisco, hourly office space rental San Francisco, rent conference room San Francisco, virtual office San Francisco, meeting room San Francisco, rent office space San Francisco, wedding venue San Francisco, hourly office rental San Francisco, hourly conference room rental San Francisco, event space rental San Francisco, party venue San Francisco, short term office space rental San Francisco, hourly hotels San Francisco",
    seeAll: "See all spaces in  San Francisco",
  },
];
