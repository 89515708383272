import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const TIME_FORMAT = "HH:mm";
const MERIDIEM_TIME_FORMAT = "HH:mm A";
const DAY_WITH_TIME_FORMAT = "ddd, MMM DD hh:mm A";

export const FormatDisplayDate = (
  value: string | Date | undefined
): string | undefined => {
  if (!value) return undefined;
  const dateTime = dayjs(value);
  return `${dateTime.toDate().toLocaleDateString()}`;
};

export const FormatDisplayTime = (
  value: string | dayjs.Dayjs | undefined,
  hasMeridiem: boolean | undefined = true
): string | undefined => {
  if (!value) return undefined;
  const dateTime = dayjs(value);
  return `${dateTime.format(hasMeridiem ? MERIDIEM_TIME_FORMAT : TIME_FORMAT)}`;
};

export const FormatDayWithTime = (
  day: number,
  hour: number,
  minute: number,
  format: string = DAY_WITH_TIME_FORMAT
) =>
  dayjs()
    .add(day, "day")
    .set("hour", hour)
    .set("minute", minute)
    .format(format);
