import { FC, useMemo, useState } from "react";
import { Select, Space } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { currencyData } from "./Currency.constant";

const CurrencyDropdown: FC = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencyData[0].currency
  );

  const currencyOptions = useMemo(() => {
    return currencyData.map((currency) => ({
      value: currency.currency,
      label: (
        <Space>
          {currency.symbol}
          {currency.currency}
        </Space>
      ),
    }));
  }, []);

  const handleCurrency = (currency: string) => {
    setSelectedCurrency(currency);
  };
  return (
    <Select
      className="dropdown"
      popupClassName="dropdown-popup"
      value={selectedCurrency}
      onChange={handleCurrency}
      aria-label="Select Currency"
      size="middle"
      suffixIcon={<CaretDownOutlined />}
      options={currencyOptions}
    />
  );
};
export default CurrencyDropdown;
