import { FC } from "react";
import { Badge, Col, Row, Tabs, Tooltip, Typography } from "antd";
import { CaretRightOutlined, RightOutlined } from "@ant-design/icons";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { FormatDayWithTime } from "../../common/utilities/format";
import "./CancellationPolicies.style.scss";
import { useParams } from "react-router-dom";

const { Title, Text, Paragraph } = Typography;

const CancellationPolicies: FC = () => {
  const { cancellation } = useParams();

  const tabItems = [
    {
      key: "flexible",
      label: "Flexible",
      children: <Flexible />,
      destroyInactiveTabPane: true,
    },
    {
      key: "moderate",
      label: "Moderate",
      children: <Moderate />,
      destroyInactiveTabPane: true,
    },
    {
      key: "strict",
      label: "Strict",
      children: <Strict />,
      destroyInactiveTabPane: true,
    },
    {
      key: "super-strict",
      label: "Super Strict",
      children: <SuperStrict />,
      destroyInactiveTabPane: true,
    },
    {
      key: "long-term",
      label: "Long Term",
      children: <LongTerm />,
      destroyInactiveTabPane: true,
    },
  ];

  return (
    <CardLayout>
      <Title level={3}>Cancellation Policies</Title>
      <Paragraph>
        Hourly Spaces on Demand allows hosts to choose among 4 standardized
        cancellation policies (Flexible,Moderate,Strict,Super Strict) that we
        will enforce to protect both guest and host alike. The Super Strict
        cancellation policy applies to special circumstances and is by
        invitation only. The Long Term cancellation policy applies to all
        reservations of 28 nights or more. Each listing and reservation on our
        site will clearly state the cancellation policy. Guests may cancel and
        review any penalties by viewing their travel plans and then clicking
        'Cancel' on the appropriate reservation.
      </Paragraph>
      <Tabs
        type="card"
        defaultActiveKey={cancellation}
        items={tabItems}
        rootClassName="cp-tabs"
      ></Tabs>
    </CardLayout>
  );
};

const Flexible: FC = () => {
  const prevDay = FormatDayWithTime(-1, 15, 0); // Previous day at 03:00 PM
  const currentDay = FormatDayWithTime(0, 15, 0); // Current day at 03:00 PM
  const nextDay = FormatDayWithTime(1, 11, 0); // Next day at 11:00 AM

  return (
    <Row>
      <Col xs={24}>
        <Title level={3}>
          Flexible: Full refund 1 day prior to arrival, except fees
        </Title>
      </Col>
      <Col xs={24}>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cleaning fees, Security fees and Additional Guest fees are always
          refunded if the guest did not check in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          The Hourly Spaces on Demand service fee is non-refundable.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          If there is a complaint from either party, notice must be given to
          Hourly Spaces on Demand within 24 hours of check-in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Hourly Spaces on Demand will mediate when necessary, and has the final
          say in all disputes.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          A reservation is officially cancelled when the guest clicks the
          cancellation button on the cancellation confirmation page, which they
          can find in Dashboard <RightOutlined /> Your Trips
          <RightOutlined /> Cancel.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cancellation policies may be superseded by the Guest Refund Policy,
          safety cancellations, or extenuating circumstances. Please review
          these exceptions.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Applicable taxes will be retained and remitted.
        </Paragraph>
      </Col>
      <Col xs={24}>
        <Row className="timeline">
          <Col xs={8}>
            <div className="green">
              <Tooltip
                title="1 day prior"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>Example</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{prevDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="orange">
              <Tooltip
                title="Check in"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{currentDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check out"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{nextDay}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={24} md={8}>
            <Paragraph>
              For a Full refund, cancellation must be made a 1 day prior to
              listing's local check in time (or 3:00 PM if not specified) on the
              day of check in. For example, if check-in is on Tuesday, cancel by
              the previous Monday before check in time.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest cancels less than 1 days in advance, the first night
              is non-refundable but the remaining nights will be 100% refunded.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest arrives and decides to leave early, the nights not
              spent 1 days after the official cancellation are 100% refunded.
              Cleaning fees, Security fees, Additional Guest fees are
              non-refunded.
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Moderate: FC = () => {
  const prevDay = FormatDayWithTime(-5, 15, 0); // Previous 5 day at 03:00 PM
  const currentDay = FormatDayWithTime(0, 15, 0); // Current day at 03:00 PM
  const nextDay = FormatDayWithTime(1, 11, 0); // Next day at 11:00 AM

  return (
    <Row>
      <Col xs={24}>
        <Title level={3}>
          Moderate: Full refund 5 days prior to arrival, except fees
        </Title>
      </Col>
      <Col xs={24}>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cleaning fees, Security fees and Additional Guest fees are always
          refunded if the guest did not check in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          The Hourly Spaces on Demand service fee is non-refundable.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          If there is a complaint from either party, notice must be given to
          Hourly Spaces on Demand within 24 hours of check-in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Hourly Spaces on Demand will mediate when necessary, and has the final
          say in all disputes.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          A reservation is officially cancelled when the guest clicks the
          cancellation button on the cancellation confirmation page, which they
          can find in Dashboard <RightOutlined /> Your Trips
          <RightOutlined /> Cancel.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cancellation policies may be superseded by the Guest Refund Policy,
          safety cancellations, or extenuating circumstances. Please review
          these exceptions.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Applicable taxes will be retained and remitted.
        </Paragraph>
      </Col>
      <Col xs={24}>
        <Row className="timeline">
          <Col xs={8}>
            <div className="green">
              <Tooltip
                title="5 day prior"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>Example</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{prevDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="orange">
              <Tooltip
                title="Check in"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{currentDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check out"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{nextDay}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={24} md={8}>
            <Paragraph>
              For a Full refund, cancellation must be made a 5 days prior to
              listing's local check in time (or 3:00 PM if not specified) on the
              day of check in. For example, if check-in is on Wednesday, cancel
              by the previous Friday before check in time.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest cancels less than 5 days in advance, the first night
              is non-refundable but the remaining nights will be 100% refunded.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest arrives and decides to leave early, the nights not
              spent 1 days after the official cancellation are 50% refunded.
              Cleaning fees, Security fees, Additional Guest fees are
              non-refunded.
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Strict: FC = () => {
  const prevDay = FormatDayWithTime(-7, 15, 0); // Previous 7 day at 03:00 PM
  const currentDay = FormatDayWithTime(0, 15, 0); // Current day at 03:00 PM
  const nextDay = FormatDayWithTime(1, 11, 0); // Next day at 11:00 AM

  return (
    <Row>
      <Col xs={24}>
        <Title level={3}>
          Strict: 50% refund up until 7 days prior to arrival, except fees
        </Title>
      </Col>
      <Col xs={24}>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cleaning fees, Security fees and Additional Guest fees are always
          refunded if the guest did not check in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          The Hourly Spaces on Demand service fee is non-refundable.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          If there is a complaint from either party, notice must be given to
          Hourly Spaces on Demand within 24 hours of check-in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Hourly Spaces on Demand will mediate when necessary, and has the final
          say in all disputes.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          A reservation is officially cancelled when the guest clicks the
          cancellation button on the cancellation confirmation page, which they
          can find in Dashboard <RightOutlined /> Your Trips
          <RightOutlined /> Cancel.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cancellation policies may be superseded by the Guest Refund Policy,
          safety cancellations, or extenuating circumstances. Please review
          these exceptions.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Applicable taxes will be retained and remitted.
        </Paragraph>
      </Col>
      <Col xs={24}>
        <Row className="timeline">
          <Col xs={8}>
            <div className="green">
              <Tooltip
                title="7 day prior"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>Example</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{prevDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check in"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{currentDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check out"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{nextDay}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={24} md={8}>
            <Paragraph>
              For a 50% refund, cancellation must be made a 7 days prior to
              listing's local check in time (or 3:00 PM if not specified) on the
              day of check in. For example, if check-in is on Wednesday, cancel
              by the previous Wednesday before check in time.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest cancels less than 7 days in advance, the all nights
              are non-refundable.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest arrives and decides to leave early, the nights not
              spent 0 days after the official cancellation are non-refunded.
              Cleaning fees, Security fees, Additional Guest fees are
              non-refunded.
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const SuperStrict: FC = () => {
  const currentDay = FormatDayWithTime(0, 15, 0); // Current day at 03:00 PM
  const nextDay = FormatDayWithTime(1, 11, 0); // Next day at 11:00 AM

  return (
    <Row>
      <Col xs={24}>
        <Title level={3}>
          Super Strict: No refund after booking, except fees
        </Title>
      </Col>
      <Col xs={24}>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cleaning fees, Security fees and Additional Guest fees are always
          refunded if the guest did not check in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Hourly Spaces on Demand service fee is non-refundable.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          If there is a complaint from either party, notice must be given to
          Hourly Spaces on Demand within 24 hours of check-in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Hourly Spaces on Demand will mediate when necessary, and has the final
          say in all disputes.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          A reservation is officially cancelled when the guest clicks the
          cancellation button on the cancellation confirmation page, which they
          can find in Dashboard <RightOutlined /> Your Trips
          <RightOutlined /> Cancel.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cancellation policies may be superseded by the Guest Refund Policy,
          safety cancellations, or extenuating circumstances. Please review
          these exceptions.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Applicable taxes will be retained and remitted.
        </Paragraph>
      </Col>
      <Col xs={24}>
        <Row className="timeline">
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check in"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{currentDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check out"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{nextDay}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest cancels less than 30 days in advance, the all nights
              are non-refundable.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest arrives and decides to leave early, the nights not
              spent 0 days after the official cancellation are non-refunded.
              Cleaning fees, Security fees, Additional Guest fees are
              non-refunded.
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const LongTerm: FC = () => {
  const currentDay = FormatDayWithTime(0, 15, 0); // Current day at 03:00 PM
  const nextDay = FormatDayWithTime(30, 11, 0); // Next 30 day at 11:00 AM

  return (
    <Row>
      <Col xs={24}>
        <Title level={3}>
          Long Term: First month down payment, 1 day notice for lease,
          termination
        </Title>
      </Col>
      <Col xs={24}>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cleaning fees, Security fees and Additional Guest fees are always
          refunded if the guest did not check in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          The Hourly Spaces on Demand service fee is non-refundable.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          If there is a complaint from either party, notice must be given to
          Hourly Spaces on Demand within 24 hours of check-in.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Hourly Spaces on Demand will mediate when necessary, and has the final
          say in all disputes.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          A reservation is officially cancelled when the guest clicks the
          cancellation button on the cancellation confirmation page, which they
          can find in Dashboard <RightOutlined /> Your Trips
          <RightOutlined /> Cancel.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Cancellation policies may be superseded by the Guest Refund Policy,
          safety cancellations, or extenuating circumstances. Please review
          these exceptions.
        </Paragraph>
        <Paragraph className="mb-0">
          <CaretRightOutlined className="me-1" />
          Applicable taxes will be retained and remitted.
        </Paragraph>
      </Col>
      <Col xs={24}>
        <Row className="timeline">
          <Col xs={8}>
            <div className="orange">
              <Tooltip
                title="Check in"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{currentDay}</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <div className="pink">
              <Tooltip
                title="Check out"
                placement="top"
                zIndex={9}
                autoAdjustOverflow={false}
                open
                overlayClassName="cp-tooltip"
              >
                <Badge status="default" />
              </Tooltip>
            </div>
            <Row justify="start">
              <Col>
                <Text>&nbsp;</Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={18} sm={12} className="text-center text-md-end">
                <Text>{nextDay}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest cancels less than 30 days in advance, the first night
              is non-refundable but the remaining nights will be 100% refunded.
            </Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Paragraph>
              If the guest arrives and decides to leave early, the nights not
              spent 0 days after the official cancellation are non-refunded.
              Cleaning fees, Security fees, Additional Guest fees are
              non-refunded.
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CancellationPolicies;
