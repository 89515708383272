import { HeartTwoTone } from "@ant-design/icons";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Card,
  Col,
  ColProps,
  Image,
  Rate,
  Row,
  RowProps,
  Typography,
} from "antd";
import { PageRoutesConstant } from "../../router/components/PageRoutes";
import { ProfileRoutesConstant } from "../../router/components/UserRoutes";
import { useAuth } from "../authContext/AuthContext";
import CardLayout from "../card-layout/CardLayout";
import "./SpaceList.style.scss";

const { Title, Text } = Typography;
const { Meta } = Card;

type SpaceListProps = {
  spaceListData: any[] | [];
  rowProps?: RowProps;
  colProps?: ColProps;
};
const SpaceList: FC<SpaceListProps> = ({
  spaceListData,
  rowProps,
  colProps,
}) => {
  const [isHoverId, setIsHoverId] = useState<number | undefined>(undefined);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  return (
    <div className="space-list">
      <Row {...rowProps}>
        {spaceListData.map((item, i) => (
          <Col {...colProps} key={`space_data_${i}`}>
            <Badge.Ribbon
              text={
                <Text
                  strong
                  className="price-text"
                >{`${item.pricePerHour}/hour`}</Text>
              }
              placement="start"
              color="#3C3F40"
              className="hour-price"
            >
              <Badge.Ribbon
                text={
                  <Text
                    strong
                    className="price-text"
                  >{`${item.pricePerDay}/day`}</Text>
                }
                placement="start"
                color="#3C3F40"
                className="day-price"
              >
                <CardLayout
                  containerClassName="p-0"
                  cardProps={{
                    classNames: { body: "px-2 py-3", actions: "px-2" },
                    cover: (
                      <Image
                        src={item.spaceImage}
                        alt={item.spaceImage}
                        preview={false}
                        className="card-image"
                        onClick={() =>
                          navigate(PageRoutesConstant.Page.SpaceDetails.path, {
                            state: { spaceId: item.id },
                          })
                        }
                      />
                    ),
                    actions: [
                      <Row align="middle">
                        <Col xs={16} className="text-start">
                          <Rate
                            disabled
                            defaultValue={item.review}
                            className="align-middle"
                          />
                        </Col>
                        <Col xs={6} className="text-center">
                          <Text className="align-middle">
                            {item.reviewTotal ? item.reviewTotal : 0} Review
                          </Text>
                        </Col>
                        <Col xs={2} className="text-center">
                          <Text className="align-middle">
                            <HeartTwoTone
                              twoToneColor={
                                isHoverId === i ? "#eb3c44" : "#bbbbbb"
                              }
                              onMouseOver={() => setIsHoverId(i)}
                              onMouseLeave={() => setIsHoverId(undefined)}
                              onClick={() => {
                                if (isAuthenticated) {
                                  navigate("/");
                                } else {
                                  navigate(PageRoutesConstant.Page.SignIn.path);
                                }
                              }}
                            />
                          </Text>
                        </Col>
                      </Row>,
                    ],
                  }}
                >
                  <Meta
                    avatar={
                      <Avatar
                        src={item.profileImage}
                        size={64}
                        onClick={() =>
                          navigate(
                            `${ProfileRoutesConstant.Users.Profile.path.replace(
                              ":name",
                              item.title
                            )}`
                          )
                        }
                        className="avatar-image"
                      />
                    }
                    title={
                      <Title
                        ellipsis
                        level={5}
                        className="title mb-0"
                        onClick={() =>
                          navigate(PageRoutesConstant.Page.SpaceDetails.path, {
                            state: { spaceId: item.id },
                          })
                        }
                      >
                        {item.title}
                      </Title>
                    }
                    description={<Text>{item.address}</Text>}
                    className="space-details"
                  />
                </CardLayout>
              </Badge.Ribbon>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default SpaceList;
