import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Blog from "../../../pages/blogs/Blog";
import CommonLayout from "../../components/common-layout/CommonLayout";
import ImportanceOfFaceToFaceMeetings from "../../../pages/blogs/importance-face-face-meeting-space-dc/ImportanceOfFaceToFaceMeetings";
import ChangeTheWayYouMakeDecisionsConferenceRoomsInDC from "../../../pages/blogs/change-way-make-decisions-conference-rooms-dc/ChangeTheWayYouMakeDecisionsConferenceRoomsInDC";
import CardLayout from "../../components/card-layout/CardLayout";
import HomeAwayHome from "../../../pages/blogs/home-away-home/HomeAwayHome";
import FreelancersLoveHourlyOfficeRentalsNycStartupHelpingFreelancersSecureDreamOfficeNextNothing from "../../../pages/blogs/freelancers-secure-dream-office-next-nothing/FreelancersSecureDreamOfficeNextNothing";
import WebsiteLunchtimeQuickies from "../../../pages/blogs/website-lunchtime-quickies/WebsiteLunchtimeQuickies";
import HourlySpacesLikeAirbnbHour from "../../../pages/blogs/hourly-spaces-like-airbnb-hour/HourlySpacesLikeAirbnbHour";
import HourlySpacesLetsRentRoomsHourEverythingNoSex from "../../../pages/blogs/hourly-spaces-lets-rent-rooms-hour-everything-no-sex/HourlySpacesLetsRentRoomsHourEverythingNoSex";
import MakeNineKMonthSittingStarBucksNineAmSixPm from "../../../pages/blogs/make-nine-k-month-sitting-starbucks-nine-am-six-pm/MakeNineKMonthSittingStarBucksNineAmSixPm";
import HourlySpacesComOffersLargestCollectionHourlySpaceRentalsAroundWorld from "../../../pages/blogs/hourlyspaces-com-offers-largest-collection-hourly-space-rentals-around-world/HourlySpacesComOffersLargestCollectionHourlySpaceRentalsAroundWorld";
import CompanyExplainsMillennialsPreferMicroRentalsHours from "../../../pages/blogs/company-explains-millennials-prefer-micro-rentals-hours/CompanyExplainsMillennialsPreferMicroRentalsHours";
import SmallWorkEnvironmentsMakeSense from "../../../pages/blogs/small-work-environments-make-sense/SmallWorkEnvironmentsMakeSense";
import LuxuriousOfficeSpaceRental from "../../../pages/blogs/luxurious-office-space-rental/LuxuriousOfficeSpaceRental";
import VirtualOfficeRentalsWork from "../../../pages/blogs/virtual-office-rentals-work/VirtualOfficeRentalsWork";
import PlacesRentSuccessfulBusinessMeetings from "../../../pages/blogs/places-rent-successful-business-meetings/PlacesRentSuccessfulBusinessMeetings";
import BookMeetingSpaceInstantly from "../../../pages/blogs/book-meeting-space-instantly/BookMeetingSpaceInstantly";
import HourlySpacesNewYork from "../../../pages/blogs/hourly-spaces-new-york/HourlySpacesNewYork";
import VirtualOfficeRentalsSaveMoney from "../../../pages/blogs/virtual-office-rentals-save-money/VirtualOfficeRentalsSaveMoney";
import ShortTermApartmentRentals from "../../../pages/blogs/short-term-apartment-rentals/ShortTermApartmentRentals";
import VirtualOfficeSpace from "../../../pages/blogs/virtual-office-space/VirtualOfficeSpace";
import ConferenceRoomsMeetingRooms from "../../../pages/blogs/conference-rooms-meeting-rooms/ConferenceRoomsMeetingRooms";
import HourlySpaces from "../../../pages/blogs/hourly-spaces/HourlySpaces";
import ShortTermRoomRentals from "../../../pages/blogs/short-term-room-rentals/ShortTermRoomRentals";
import HourlyOfficeSpaceRentalPrivateAndShared from "../../../pages/blogs/hourly-office-space-rental-private-and-shared/HourlyOfficeSpaceRentalPrivateAndShared";

interface RouteKey {
  [key: string]: {
    title?: string;
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Blogs: RouteKey;
}

const BlogsBaseRoutesConstant: IRoute = {
  Blogs: {
    Base: {
      path: "/blogs",
    },
  },
};

export const BlogsRoutesConstant: IRoute = {
  Blogs: {
    Home: {
      path: BlogsBaseRoutesConstant.Blogs.Base.path,
      element: <Blog />,
    },
    ImportanceOfFaceToFaceMeetings: {
      title: "Importance of Face-To-Face Meetings",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/importance-face-face-meeting-space-dc`,
      element: <ImportanceOfFaceToFaceMeetings />,
    },
    ChangeTheWayYouMakeDecisionsConferenceRoomsInDC: {
      title: "Change the way you make decisions – Conference Rooms in DC",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/change-way-make-decisions-conference-rooms-dc`,
      element: <ChangeTheWayYouMakeDecisionsConferenceRoomsInDC />,
    },
    HomeAwayHome: {
      title: "A Home Away From Home",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/home-away-home`,
      element: <HomeAwayHome />,
    },
    FreelancersLoveHourlyOfficeRentalsNycStartupHelpingFreelancersSecureDreamOfficeNextNothing:
      {
        title:
          "Why Freelancers Love Hourly Office Rentals & How This NYC Startup Is Helping Freelancers Secure Their Dream Office For Next To Nothing",
        path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/freelancers-love-hourly-office-rentals-nyc-startup-helping-freelancers-secure-dream-office-next-nothing`,
        element: (
          <FreelancersLoveHourlyOfficeRentalsNycStartupHelpingFreelancersSecureDreamOfficeNextNothing />
        ),
      },
    WebsiteLunchtimeQuickies: {
      title: "A website for lunchtime quickies",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/website-lunchtime-quickies`,
      element: <WebsiteLunchtimeQuickies />,
    },
    HourlySpacesLikeAirbnbHour: {
      title: "Hourly Spaces is like Airbnb by the hour",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/hourly-spaces-like-airbnb-hour`,
      element: <HourlySpacesLikeAirbnbHour />,
    },
    HourlySpacesLetsRentRoomsHourEverythingNoSex: {
      title:
        "Hourly Spaces lets you rent rooms by the hour for everything but No-Sex",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/hourly-spaces-lets-rent-rooms-hour-everything-no-sex`,
      element: <HourlySpacesLetsRentRoomsHourEverythingNoSex />,
    },
    MakeNineKMonthSittingStarBucksNineAmSixPm: {
      title:
        "How I Make $9,000/month by sitting at Starbucks from 9a.m. to 6 p.m.",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/make-9000month-sitting-starbucks-9a-m-6-p-m`,
      element: <MakeNineKMonthSittingStarBucksNineAmSixPm />,
    },
    HourlySpacesComOffersLargestCollectionHourlySpaceRentalsAroundWorld: {
      title:
        "HourlySpaces.com Offers the Largest Collection of Hourly Space Rentals Around the World",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/hourlyspaces-com-offers-largest-collection-hourly-space-rentals-around-world`,
      element: (
        <HourlySpacesComOffersLargestCollectionHourlySpaceRentalsAroundWorld />
      ),
    },
    CompanyExplainsMillennialsPreferMicroRentalsHours: {
      title:
        "Company Explains Why Millennials Prefer Micro Rentals for a Few Hours",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/company-explains-millennials-prefer-micro-rentals-hours`,
      element: <CompanyExplainsMillennialsPreferMicroRentalsHours />,
    },
    SmallWorkEnvironmentsMakeSense: {
      title: "Why Small Work Environments Make Sense",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/small-work-environments-make-sense`,
      element: <SmallWorkEnvironmentsMakeSense />,
    },
    LuxuriousOfficeSpaceRental: {
      title: "Luxurious Office Space Rental",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/luxurious-office-space-rental`,
      element: <LuxuriousOfficeSpaceRental />,
    },
    VirtualOfficeRentalsWork: {
      title: "How Virtual Office Rentals Work",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/virtual-office-rentals-work`,
      element: <VirtualOfficeRentalsWork />,
    },
    PlacesRentSuccessfulBusinessMeetings: {
      title: "Places to Rent for Successful Business Meetings",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/places-rent-successful-business-meetings`,
      element: <PlacesRentSuccessfulBusinessMeetings />,
    },
    BookMeetingSpaceInstantly: {
      title: "Book Meeting Space Instantly",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/book-meeting-space-instantly`,
      element: <BookMeetingSpaceInstantly />,
    },
    HourlySpacesNewYork: {
      title: "Hourly Spaces in New York",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/hourly-spaces-new-york`,
      element: <HourlySpacesNewYork />,
    },
    VirtualOfficeRentalsSaveMoney: {
      title: "How Virtual Office Rentals Save Money",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/virtual-office-rentals-save-money`,
      element: <VirtualOfficeRentalsSaveMoney />,
    },
    ShortTermApartmentRentals: {
      title: "Short Term Apartment rentals",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/short-term-apartment-rentals`,
      element: <ShortTermApartmentRentals />,
    },
    VirtualOfficeSpace: {
      title: "Virtual Office Space",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/virtual-office-space`,
      element: <VirtualOfficeSpace />,
    },
    ConferenceRoomsMeetingRooms: {
      title: "Conference Rooms and Meeting Rooms",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/conference-rooms-meeting-rooms`,
      element: <ConferenceRoomsMeetingRooms />,
    },
    HourlySpaces: {
      title: "Hourly Spaces",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/hourly-spaces`,
      element: <HourlySpaces />,
    },
    ShortTermRoomRentals: {
      title: "Short Term Room Rentals",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/short-term-room-rentals`,
      element: <ShortTermRoomRentals />,
    },
    HourlyOfficeSpaceRentalPrivateAndShared: {
      title: "Hourly Office Space Rental– Private and Shared",
      path: `${BlogsBaseRoutesConstant.Blogs.Base.path}/hourly-office-space-rental-private-and-shared`,
      element: <HourlyOfficeSpaceRentalPrivateAndShared />,
    },
  },
};

const BlogsRoutes = () => {
  return (
    <Routes>
      {Object.keys(BlogsRoutesConstant.Blogs).map((key) => {
        const route = BlogsRoutesConstant.Blogs[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              BlogsBaseRoutesConstant.Blogs.Base.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const BlogsFlowRoutes: FC = () => {
  return (
    <CommonLayout>
      <CardLayout>
        <BlogsRoutes />
      </CardLayout>
    </CommonLayout>
  );
};

export default BlogsFlowRoutes;
