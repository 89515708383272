import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";
import "./WhyHost.style.scss";

const { Paragraph, Title } = Typography;

const WhyHost: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("whyHost")} titleTextColor="red">
      <Row className="why-host">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            Why should you rent your space on HourlySpaces.com?
          </Title>
          <Paragraph>
            There are hosts in many major cities across the United States that
            open their private properties for business and casual travelers from
            around the world in order for them to meet new people, get new
            business and make some extra cash.
          </Paragraph>
          <Title level={5}>
            Expose and market your real estate to a larger audience
          </Title>
          <Paragraph>
            Our website has thousands of visitors from around the world each
            day. Listing your space on our website exposes your listings to a
            very large audience. You sit back and relax while we work hard to
            get you more business.
          </Paragraph>
          <Title level={5}>
            $1 Million Insurance for every reservation
          </Title>
          <Paragraph>
            Your peace of mind is priceless, so we don't charge you for it.
            Every single booking on HourlySpaces.com is covered by a $1 Million
            Insurance - at no cost to you.
          </Paragraph>
          <Title level={5}>
            There is no service charge
          </Title>
          <Paragraph>
            There are no fees charged to list your property; only a 10% service
            fee is billed once a reservation is confirmed.
          </Paragraph>
          <Title level={5}>
            Communicating with potential guests has never been easier
          </Title>
          <Paragraph>
            Communication is not only good for your guests but also for you!
            Communicating with potential guests gives you a good feel of the
            people you give access to your space. With HourlySpaces, you also
            have the option to view guests’ profiles and read reviews other
            hosts have left for them to determine if they are a good fit for you
            and your property.
          </Paragraph>
          <Title level={5}>
            The power is in your hands
          </Title>
          <Paragraph>
            When you create a listing, you set your space’s availability, price
            and all requirements for reservations. You can rent for 1 hour or 3
            weeks or 2 months. During peak travel season, you have the option to
            increase your prices and make more money accommodating guests.
          </Paragraph>
          <Title level={5}>24/7 Support</Title>
          <Paragraph>
            Our team of professional support staff is available 24/7 to provide
            assistance and answer questions.
          </Paragraph>
          <Title level={5}>Talk it over</Title>
          <Paragraph>
            Discuss your decision to host on HourlySpaces with your neighbors,
            friends and coworkers.
          </Paragraph>
          <Title level={5}>Trust and Safety</Title>
          <Paragraph>
            Your privacy, safety and security are our first concern; therefore,
            we commit ourselves to create a safe and trustworthy community for
            both our hosts and renters. Our secure website and Verified
            Identification process were established to keep you as safe and
            secure as possible.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default WhyHost;
