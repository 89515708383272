import { FC } from "react";
import { Layout } from "antd";
import MainHeader from "../header/MainHeader";

const { Content } = Layout;

type SearchLayoutProps = {
  children: React.ReactNode;
};

const SearchLayout: FC<SearchLayoutProps> = ({ children }) => {
  return (
    <Layout className="main-layout">
      <MainHeader fluid />
      <Content>{children}</Content>
    </Layout>
  );
};

export default SearchLayout;
