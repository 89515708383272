import { FC } from "react";
import { Layout } from "antd";
import MainHeader from "../header/MainHeader";
import MainFooter from "../footer/MainFooter";
import CardLayout from "../card-layout/CardLayout";
import ProfileHeader from "../profile-header/ProfileHeader";

type ProfileLayoutProps = {
  children: React.ReactNode;
};

const ProfilesLayout: FC<ProfileLayoutProps> = ({ children }) => {
  return (
    <Layout className="main-layout">
      <MainHeader />
      <CardLayout title={<ProfileHeader />}>{children}</CardLayout>
      <MainFooter />
    </Layout>
  );
};

export default ProfilesLayout;
