import { FC } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Image, Typography } from "antd";
import PrevArrow from "../slider-prev-arrow/PrevArrow";
import NextArrow from "../slider-next-arrow/NextArrow";
import { PageRoutesConstant } from "../../../../common/router/components/PageRoutes";
import "./SpacesSlider.style.scss";

const { Title, Paragraph } = Typography;

const SpacesSlider: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };

  const spacesData = [
    {
      id: "1",
      spaceImage:
        "https://images.pexels.com/photos/245240/pexels-photo-245240.jpeg?cs=srgb&dl=pexels-atbo-66986-245240.jpg&fm=jpg",
      title: "Hourly Thompson Conference Room - 1204 Broadway",
      profileImage:
        "https://i0.wp.com/www.steverrobbins.com/wp-content/uploads/ceo-midjourney-office.jpg?resize=1024%2C683&ssl=1",
      pricePerHour: "$60",
      pricePerDay: "$400",
      city: "New York",
      location: "New York",
    }
    // {
    //   id: "2",
    //   spaceImage:
    //     "https://img.freepik.com/free-photo/modern-office-space-interior_158595-5206.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1724803200&semt=ais_hybrid",
    //   title: "Hourly Meeting Space in Financial District for 5 - B",
    //   profileImage:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2tAuram1AEiac9rt9ebsNcjGm3bPT-Czivw&s",
    //   pricePerHour: "$40",
    //   pricePerDay: "$300",
    //   city: "New York",
    //   location: "New York",
    // },
    // {
    //   id: "3",
    //   spaceImage:
    //     "https://plus.unsplash.com/premium_photo-1681487178876-a1156952ec60?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8b2ZmaWNlc3xlbnwwfHwwfHx8MA%3D%3D",
    //   title: "Hourly Meeting Room Near Penn Station - B",
    //   profileImage:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr8aNuufR5O96efVY5Z5z7PNW7D52whBLsiiD_PVgkj0eYFfFRuPoNPw8mhBhusbEfBzk&usqp=CAU",
    //   pricePerHour: "$50",
    //   pricePerDay: "$700",
    //   city: "New York",
    //   location: "New York",
    // },
    // {
    //   id: "4",
    //   spaceImage:
    //     "https://retaildesignblog.net/wp-content/uploads/2017/01/Peacock-Technology-office-by-DWG-Surat-India.jpg",
    //   title: "Hourly Meeting Space in Financial District for 5 - B",
    //   profileImage:
    //     "https://upload.wikimedia.org/wikipedia/commons/5/5a/Sanjiv_Mehta%2C_Chairman_of_The_East_India_Company.jpg",
    //   pricePerHour: "$25",
    //   pricePerDay: "$260",
    //   city: "New York",
    //   location: "New York",
    // },
    // {
    //   id: "5",
    //   spaceImage:
    //     "https://images.pexels.com/photos/8353767/pexels-photo-8353767.jpeg",
    //   title: "Hourly Meeting Room Near Penn Station - B",
    //   profileImage:
    //     "https://media.istockphoto.com/id/1459814562/photo/smart-successful-intelligent-hispanic-or-brazilian-young-woman-financial-director-seo-manager.jpg?s=612x612&w=0&k=20&c=01sxpws-5PYPE54Ydwhvszljm_ZMMgO8Bh3K_rtROUI=",
    //   pricePerHour: "$59",
    //   pricePerDay: "$659",
    //   city: "New York",
    //   location: "New York",
    // },
    // {
    //   id: "6",
    //   spaceImage:
    //     "https://media.istockphoto.com/id/1387606716/photo/interior-of-modern-empty-office.jpg?s=612x612&w=0&k=20&c=Gqu3UyANLCAgZyXTjzRmGNg79P71IcGKVNDuqESggDY=",
    //   title: "Hourly Thompson Conference Room - 1204 Broadway",
    //   profileImage:
    //     "https://cdn.shopify.com/s/files/1/0272/4714/9155/files/Mr.Shailendra-Malhotra.jpg?v=1607334000",
    //   pricePerHour: "$23",
    //   pricePerDay: "$356",
    //   city: "New York",
    //   location: "New York",
    // }
  ];

  return (
    <div className="slider-wrapper">
      <Container>
        <Title level={3} className="title text-center">
          {t("home.spacesSlider.title")}
        </Title>

        <Slider {...settings} className="content-slider">
          {spacesData.map((item, index) => (
            <div key={`slider_image_${index}`}>
              <div
                className="image-content"
                onClick={() =>
                  navigate(PageRoutesConstant.Page.SpaceDetails.path, {
                    state: { spaceId: item.id },
                  })
                }
              >
                <Image
                  src={item.spaceImage}
                  alt={item.city}
                  preview={false}
                  className="image"
                />
                <div className="space-details">
                  <Title level={4} className="space-title">
                    {item.title}
                  </Title>
                  <Image
                    src={item.profileImage}
                    alt={item.profileImage}
                    preview={false}
                    className="profile-image mt-3 mb-1"
                  />
                  <div className="price-content">
                    <Title level={5} className="my-0">
                      Price - {item.pricePerHour}/hour
                    </Title>
                    <Title level={5} className="my-0">
                      Price - {item.pricePerDay}/day
                    </Title>
                    <Paragraph className="mb-1">
                      {item.city}, {item.location}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};
export default SpacesSlider;
