import { FC, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import useResponsive from "../../../hooks/useResponsive";

interface CaptchaProps {
  setCaptchaToken: (value: string | undefined) => void;
  setCaptchaError: (value: string | undefined) => void;
  error: string | undefined;
  captchaToken: string | undefined;
}

export const Captcha: FC<CaptchaProps> = ({
  setCaptchaToken,
  setCaptchaError,
  error,
  captchaToken,
}) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { isTablet } = useResponsive();

  useEffect(() => {
    if (captchaToken === undefined) {
      if (captchaRef.current) {
        captchaRef.current.reset(); // Reset the ReCAPTCHA
      }
    }
  }, [captchaToken]);

  const handleChange = (token: any) => {
    if (setCaptchaError) {
      setCaptchaError(undefined);
      setCaptchaToken(token);
    }
  };

  const handleExpired = () => {
    setCaptchaToken(undefined);
  };

  return (
    <Row justify="center" className="mb-4">
      <Col>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string}
          onChange={handleChange}
          onExpired={handleExpired}
          size={isTablet ? "compact" : "normal"}
        />
        {error && <p className="text-danger text-start mb-0">{error}</p>}
      </Col>
    </Row>
  );
};
