import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Table, TableColumnsType } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import { Link } from "react-router-dom";
import { ProfileRoutesConstant } from "../../../../common/router/components/UserRoutes";
import { QuestionCircleFilled } from "@ant-design/icons";

const Security: FC = () => {
  const columns: TableColumnsType = [
    {
      title: "Browser/Device",
      dataIndex: "name",
    },
    {
      title: (
        <>
          Location <QuestionCircleFilled />
        </>
      ),
      width: "30%",
    },
    {
      title: "Recent Activity",
      width: "36%",
    },
  ];

  const data: any[] = [
    {
      key: "1",
      name: (
        <>
          If you see something unfamiliar,&nbsp;
          <Link
            to={ProfileRoutesConstant.Users.ChangePassword.path}
            className="link"
          >
            change your password
          </Link>
          .
        </>
      ),
    },
  ];
  return (
    <>
      <Helmet>
        <title>Security</title>
      </Helmet>
      <CardLayout title="Login History" containerClassName="p-0">
        <Row>
          <Col xs={24}>
            <Table columns={columns} dataSource={data} pagination={false} />
          </Col>
        </Row>
      </CardLayout>
    </>
  );
};

export default Security;
