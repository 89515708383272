import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Paragraph, Title } = Typography;

const Careers: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("employmentOpportunities")} titleTextColor="red">
      <Row>
        <Col xs={24}>
          <Title level={5}>Summer Internships</Title>
          <Paragraph>Marketing Intern 1 - Fairfax, VA</Paragraph>
          <Paragraph>Marketing Intern 2 - Fairfax, VA</Paragraph>
          <Paragraph>Marketing Intern 1 - New York, NY</Paragraph>
          <Paragraph>Marketing Intern 2 - New York, NY</Paragraph>
          <Title level={5}>Information Technology</Title>
          <Paragraph>PHP Web Developer - Fairfax, VA</Paragraph>
          <Title level={5}>Business Development</Title>
          <Paragraph>Business Development Consultant - Fairfax, VA</Paragraph>
          <Paragraph>Business Development Consultant - New York, NY</Paragraph>
          <Paragraph>Social Media Manager - Fairfax, VA</Paragraph>
          <Paragraph>Sales & Marketing Consultant - Fairfax, VA</Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default Careers;
