import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../../components/profile-layout/ProfileLayout";
import Policies from "../../../pages/user/listings/policies/Policies";
import IncomingReservations from "../../../pages/user/listings/incoming-reservations/IncomingReservations";
import Listings from "../../../pages/user/listings/Listings";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Listing: RouteKey;
}

const ListingBaseRoutesConstant: IRoute = {
  Listing: {
    Base: {
      path: "/listings",
    },
  },
};

export const ListingRoutesConstant: IRoute = {
  Listing: {
    Listing: {
      path: `${ListingBaseRoutesConstant.Listing.Base.path}`,
      element: <Listings />,
    },
    IncomingReservations: {
      path: `${ListingBaseRoutesConstant.Listing.Base.path}/my-reservation`,
      element: <IncomingReservations />,
    },
    Policies: {
      path: `${ListingBaseRoutesConstant.Listing.Base.path}/policies`,
      element: <Policies />,
    },
  },
};

const ListingRoutes = () => {
  return (
    <Routes>
      {Object.keys(ListingRoutesConstant.Listing).map((key) => {
        const route = ListingRoutesConstant.Listing[key];
        return (
          <Route
            key={key}
            path={route.path.replace(
              ListingRoutesConstant.Listing.Listing.path,
              ""
            )}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const ListingFlowRoutes: FC = () => {
  return (
    <ProfileLayout>
      <ListingRoutes />
    </ProfileLayout>
  );
};

export default ListingFlowRoutes;
