import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const HourlyOfficeSpaceRentalPrivateAndShared: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hourly Office Space Rental– Private and Shared</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Hourly Office Space Rental– Private and Shared
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Every meeting and collaboration deserves a great venue dedicated
                to bring out the best in each of the members. A comfortable
                meeting space or meeting room will help bring out the talent and
                creativity of each member making your meeting more collaborative
                and productive. This is key to help your company or your
                business grow. In our hourly office space, we don’t just
                encourage proficiency, but the camaraderie with your staff
                members or co-workers as well.
              </Paragraph>
              <Paragraph>
                As a short-term or hourly office rental provider, it is
                important for us to understand the current economic trends. We
                are fully aware of how companies maximize the use of mobile
                workers to collaborate with each other in the cloud. Therefor,
                modern technology enabled us to work at anytime from anywhere.
                Today’s workforce no longer needs a permanent physical office.
                Conversely, with a company set up virtually, it is imperative
                that some requires once a month meeting. This scheduled meeting
                is crucial to the growth and success of the company.
                Consequently, our Hourly Office Space is well prepared to
                address your need for a meeting space.
              </Paragraph>
              <Paragraph>
                We offer services based on your needs and budget. We cater to
                private offices or shared offices for collaborative work between
                team members. Most of our private or shared offices also have
                conference rooms near by for meetings or team-work. Whether you
                are a small group of five or a large company of more than one
                hundred or more, we will find the place for you. HourlySpaces
                has plenty of offices that have features such as free parking,
                overflowing coffee/tea, wifi, and most importantly a comfortable
                hourly office space to get everyone interacting, learning, and
                making connections.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default HourlyOfficeSpaceRentalPrivateAndShared;
