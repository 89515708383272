import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Checkbox, Col, Divider, Form, Row, Typography } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const { Title, Paragraph } = Typography;

const Notification: FC = () => {
  const { t } = useTranslation();

  const saveEmailSettings = (values: any) => {
    console.log("values>>", values);
  };

  return (
    <>
      <Helmet>
        <title>Edit Account Details</title>
      </Helmet>
      <CardLayout
        title={t("account.notification.emailSettings")}
        containerClassName="p-0"
      >
        <Form layout="inline" onFinish={saveEmailSettings}>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <Paragraph className="mb-0">
                {t("account.notification.sendMeEmailsWhen")}
              </Paragraph>
              <Title level={4} className="m-0">
                {t("account.notification.sendMeEmailsTitle")}
              </Title>

              <Form.Item
                name="periodic_offers"
                valuePropName="checked"
                getValueFromEvent={(e) => (e.target.checked ? 1 : undefined)}
              >
                <Checkbox>{t("account.notification.periodic_offers")}</Checkbox>
              </Form.Item>

              <Form.Item
                name="company_news"
                valuePropName="checked"
                getValueFromEvent={(e) => (e.target.checked ? 1 : undefined)}
              >
                <Checkbox>{t("account.notification.company_news")}</Checkbox>
              </Form.Item>
            </Col>

            <Divider className="my-0" />

            <Col xs={24}>
              <Paragraph className="mb-0">
                {t("account.notification.RemindMeWhen")}
              </Paragraph>
              <Title level={4} className="m-0">
                {t("account.notification.RemindMeTitle")}
              </Title>

              <Form.Item
                name="upcoming_reservation"
                valuePropName="checked"
                getValueFromEvent={(e) => (e.target.checked ? 1 : undefined)}
              >
                <Checkbox>
                  {t("account.notification.upcoming_reservation")}
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="new_review"
                valuePropName="checked"
                getValueFromEvent={(e) => (e.target.checked ? 1 : undefined)}
              >
                <Checkbox>{t("account.notification.new_review")}</Checkbox>
              </Form.Item>

              <Form.Item
                name="leave_review"
                valuePropName="checked"
                getValueFromEvent={(e) => (e.target.checked ? 1 : undefined)}
              >
                <Checkbox>{t("account.notification.leave_review")}</Checkbox>
              </Form.Item>

              <Form.Item
                name="rank_search"
                valuePropName="checked"
                getValueFromEvent={(e) => (e.target.checked ? 1 : undefined)}
              >
                <Checkbox>{t("account.notification.rank_search")}</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Button type="primary" htmlType="submit" size="large">
                {t("account.notification.saveEmailSettings")}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardLayout>
    </>
  );
};
export default Notification;
