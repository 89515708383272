import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import CardLayout from "../../common/components/card-layout/CardLayout";

const { Text } = Typography;

const ContactUs: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("contact_us")} colProps={{ md: 16 }}>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={12} md={8} lg={6}>
              <Row justify="space-between">
                <Col>
                  <strong>{t("phoneSupport")}</strong>
                </Col>
                <Col>:</Col>
              </Row>
            </Col>
            <Col xs={12} md={16} lg={18}>
              <Text>888-3-HOURLY</Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={12} md={8} lg={6}>
              <Row justify="space-between">
                <Col>
                  <strong>{t("emailSupport")}</strong>
                </Col>
                <Col>:</Col>
              </Row>
            </Col>
            <Col xs={12} md={16} lg={18}>
              <Text>support@hourlyspaces.com</Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={12} md={8} lg={6}>
              <Row justify="space-between">
                <Col>
                  <strong>{t("meet_us_at")}</strong>
                </Col>
                <Col>:</Col>
              </Row>
            </Col>
            <Col xs={12} md={16} lg={18}>
              <Row gutter={[14, 14]}>
                <Col xs={24}>
                  <Text>HourlySpaces Headquarters</Text>
                </Col>
                <Col xs={24}>
                  <Text>New York, NY, United States</Text>
                </Col>
                <Col xs={24}>
                  <Text>Washington DC, United States</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default ContactUs;
