import { FC, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Layout,
  MenuProps,
  notification,
  Space,
} from "antd";
import { CaretDownOutlined, MailOutlined } from "@ant-design/icons";
import useResponsive from "../../../hooks/useResponsive";
import { Logo } from "../../../assets/images";
import { PageRoutesConstant } from "../../router/components/PageRoutes";
import { IBrowseItem } from "../../interfaces/IBrowseItem";
import { Language } from "../../enums/Language";
import { useAuth } from "../authContext/AuthContext";
import { ProfileRoutesConstant } from "../../router/components/UserRoutes";
import { SearchRoutesConstant } from "../../router/components/SearchRoutes";
import { AccountRoutesConstant } from "../../router/components/AccountRoute";
import { TravelingRoutesConstant } from "../../router/components/TravelingRoute";
import { ListingRoutesConstant } from "../../router/components/ListingRoute";
import { DashboardRoutesConstant } from "../../router/components/DashboardRoute";
import { MessageRoutesConstant } from "../../router/components/MessageRoute";
import "./MainHeader.style.scss";

const { Header } = Layout;

type MainHeaderProps = {
  fluid?: boolean | string | "sm" | "md" | "lg" | "xl" | "xxl";
};

const MainHeader: FC<MainHeaderProps> = ({ fluid }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuth();
  const { t, i18n } = useTranslation();
  const { isMobile } = useResponsive();

  const BrowseItems: IBrowseItem[] = useMemo(() => {
    return [
      {
        title: "header.browse.hourlyOfficeSpaceDC",
        path: `${SearchRoutesConstant.Search.Space.path}?location=Washington DC`,
      },
      {
        title: "header.browse.hourlyConferenceRoomDC",
        path: `${SearchRoutesConstant.Search.Space.path}?location=Washington DC`,
      },
      {
        title: "header.browse.hourlyOfficeSpaceNYC",
        path: `${SearchRoutesConstant.Search.Space.path}?location=New York`,
      },
      {
        title: "header.browse.hourlyConferenceRoomNYC",
        path: `${SearchRoutesConstant.Search.Space.path}?location=New York`,
      },
      {
        title: "header.browse.hourlyOfficeSpaceBoston",
        path: `${SearchRoutesConstant.Search.Space.path}?location=Boston`,
      },
      {
        title: "header.browse.hourlyConferenceRoomBoston",
        path: `${SearchRoutesConstant.Search.Space.path}?location=Boston`,
      },
      {
        title: "header.browse.hourlyEventSpaceDC",
        path: `${SearchRoutesConstant.Search.Space.path}?location=Washington DC`,
      },
      {
        title: "header.browse.hourlyEventSpaceNY",
        path: `${SearchRoutesConstant.Search.Space.path}?location=New York`,
      },
      {
        title: "header.browse.hourlyEventSpaceBoston",
        path: `${SearchRoutesConstant.Search.Space.path}?location=Boston`,
      },
      {
        title: "header.browse.shortTermRental",
        path: PageRoutesConstant.Page.ShortTermRental.path,
      },
      {
        title: "header.browse.neighborhoods",
        path: PageRoutesConstant.Page.NeighborHoods.path,
        icon: "bi bi-geo-alt-fill",
      },
    ];
  }, []);

  const UserNameItems: IBrowseItem[] = useMemo(() => {
    return [
      {
        title: "header.userName.dashboard",
        path: DashboardRoutesConstant.Home.Dashboard.path,
      },
      {
        title: "header.userName.yourListings",
        path: ListingRoutesConstant.Listing.Listing.path,
      },
      {
        title: "header.userName.incomingReservations",
        path: ListingRoutesConstant.Listing.IncomingReservations.path,
      },
      {
        title: "header.userName.yourTrips",
        path: TravelingRoutesConstant.Traveling.YourTrips.path,
      },
      {
        title: "header.userName.wishLists",
        path: AccountRoutesConstant.Account.MyWishList.path,
      },
      {
        title: "header.userName.editProfile",
        path: ProfileRoutesConstant.Users.EditProfile.path,
      },
      {
        title: "header.userName.account",
        path: AccountRoutesConstant.Account.Notification.path,
      },
      {
        title: "header.userName.logout",
        path: PageRoutesConstant.Page.SignIn.path,
      },
    ];
  }, []);

  const items: MenuProps["items"] = useMemo(() => {
    return BrowseItems.map((item, index) => ({
      label: (
        <Link to={item.path} rel="noreferrer" className="text-decoration-none">
          {item.icon && <i className={`${item.icon} me-1`}></i>}
          {t(item.title)}
        </Link>
      ),
      key: index.toString(),
    }));
  }, [BrowseItems, t]);

  const userItems: MenuProps["items"] = useMemo(() => {
    return UserNameItems.map((item, index) => ({
      label: (
        <Link
          to={item.path}
          onClick={
            item.title === "header.userName.logout"
              ? () => {
                  logout();
                  notification.success({
                    message: "You are logged out successfully.",
                  });
                }
              : undefined
          }
          rel="noreferrer"
          className="text-decoration-none"
        >
          {t(item.title)}
        </Link>
      ),
      key: index.toString(),
    }));
  }, [UserNameItems, logout, t]);

  return (
    <Header className="header">
      <Container fluid={fluid}>
        <Navbar
          expand={i18n.language === Language.German ? "xl" : "lg"}
          className="p-0"
        >
          <Navbar.Brand
            as={Link}
            to={PageRoutesConstant.Page.Home.path}
            className="py-0"
          >
            <img src={Logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
          <Navbar.Collapse>
            <Flex vertical={isMobile} gap="small" className="w-100">
              <Space direction={isMobile ? "vertical" : "horizontal"}>
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  overlayClassName="browse-items"
                >
                  <Button
                    type="link"
                    onClick={(e) => e.preventDefault()}
                    className="text-uppercase header-menu-item"
                  >
                    <Space>
                      {t("header.menu.browse")}
                      <CaretDownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
              <Flex
                justify={isMobile ? "flex-start" : "flex-end"}
                className="w-100"
              >
                <Space
                  direction={isMobile ? "vertical" : "horizontal"}
                  size="small"
                >
                  <Navbar.Text>
                    24/7 {t("header.menu.support")} (888)346-8759
                  </Navbar.Text>
                  {!isAuthenticated ? (
                    <>
                      <Button
                        type="link"
                        onClick={() =>
                          navigate(PageRoutesConstant.Page.SignUp.path)
                        }
                        className="text-uppercase header-menu-item"
                      >
                        {t("header.menu.sign_up")}
                      </Button>
                      <Button
                        type="link"
                        onClick={() =>
                          navigate(PageRoutesConstant.Page.SignIn.path)
                        }
                        className="text-uppercase header-menu-item"
                      >
                        {t("header.menu.sign_in")}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Dropdown
                        menu={{ items: userItems }}
                        trigger={["click"]}
                        overlayClassName="browse-items"
                      >
                        <Button
                          type="link"
                          onClick={(e) => e.preventDefault()}
                          icon={
                            <Avatar
                              size={24}
                              shape="square"
                              src="https://www.hourlyspaces.com/images/no_avatar_thumb.jpg"
                            />
                          }
                          className="text-uppercase header-menu-item"
                        >
                          <Space>
                            {user?.sub}
                            <CaretDownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                      <Link
                        to={MessageRoutesConstant.Message.Inbox.path}
                        className="inbox"
                      >
                        <MailOutlined />
                      </Link>
                    </>
                  )}
                  <Button
                    className="text-uppercase btn-list-your-space"
                    onClick={() => {
                      if (isAuthenticated) {
                        navigate("/");
                      } else {
                        navigate(PageRoutesConstant.Page.SignIn.path);
                      }
                    }}
                  >
                    {t("header.menu.list_your_space")}
                  </Button>
                </Space>
              </Flex>
            </Flex>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Header>
  );
};
export default MainHeader;
