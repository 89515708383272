import { FC, useMemo, useState } from "react";
import { ButtonGroup, Container, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  TimePicker,
} from "antd";
import { CommonSpace } from "../../../common/enums";
import useResponsive from "../../../hooks/useResponsive";
import GMPAutocomplete from "../../../common/components/autocomplete/google-maps-place-autocomplete/GMPAutocomplete";
import {
  FormatDisplayDate,
  FormatDisplayTime,
} from "../../../common/utilities/format";
import "./SearchForm.style.scss";
const SearchForm: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const [form] = Form.useForm();
  const [selectedSpace, setSelectedSpace] = useState<CommonSpace>(
    CommonSpace.AllSpaces
  );

  const handleSelectedSpace = (value: CommonSpace) => {
    form.setFieldsValue({ space_type: value });
    setSelectedSpace(value);
  };

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      form.setFieldsValue({ lat: lat });
      form.setFieldsValue({ lng: lng });
    }
    form.setFieldsValue({ location: place.formatted_address || place.name });
  };

  const onFinish = (values: any) => {
    values = {
      ...values,
      checkin: FormatDisplayDate(values.checkin),
      checkout: FormatDisplayDate(values.checkout),
      cinhours: FormatDisplayTime(values.cinhours),
      couthours: FormatDisplayTime(values.couthours),
    };
    console.log("Form Values:", values);
  };

  const guestsOptions = useMemo(() => {
    return Array.from({ length: 16 }, (_, i) => i + 1).map((guest) => ({
      value: guest,
      label: (
        <Space>
          {guest < 16 ? guest : "16+"}
          {guest === 1
            ? `${t("home.search_form.guest")}`
            : `${t("home.search_form.guests")}`}
        </Space>
      ),
    }));
  }, [t]);

  return (
    <Container>
      <Stack direction="vertical" className="search-form">
        <CommonSpaces
          isMobile={isMobile}
          handleSelectedSpace={handleSelectedSpace}
        />
        <Form form={form} onFinish={onFinish}>
          <Form.Item hidden name="space_type" initialValue={selectedSpace}>
            <Input type="hidden"></Input>
          </Form.Item>
          <Form.Item hidden name="lat">
            <Input type="hidden"></Input>
          </Form.Item>
          <Form.Item hidden name="lng">
            <Input type="hidden"></Input>
          </Form.Item>
          <Row gutter={0}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: t("home.search_form.gmp_required_error"),
                  },
                ]}
                className="mb-0"
              >
                <GMPAutocomplete
                  onPlaceSelected={handlePlaceSelected}
                  placeholder={t("home.search_form.gmp_placeholder")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Row>
                <Col xs={14}>
                  <Form.Item name="checkin" className="mb-0">
                    <DatePicker
                      placeholder="mm/dd/yy"
                      size="middle"
                      className="w-100"
                      format="MM/DD/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col xs={10}>
                  <Form.Item name="cinhours" className="mb-0">
                    <TimePicker
                      placeholder={t("home.search_form.time_placeholder")}
                      format="hh:mm A"
                      use12Hours
                      size="middle"
                      minuteStep={30}
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Row>
                <Col xs={14}>
                  <Form.Item name="checkout" className="mb-0">
                    <DatePicker
                      placeholder="mm/dd/yy"
                      size="middle"
                      className="w-100"
                      format="MM/DD/YYYY"
                      disabledDate={(current) =>
                        current.isBefore(form.getFieldValue("checkin"), "day")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={10}>
                  <Form.Item name="couthours" className="mb-0">
                    <TimePicker
                      placeholder={t("home.search_form.time_placeholder")}
                      format="hh:mm A"
                      use12Hours
                      size="middle"
                      minuteStep={30}
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} lg={4}>
              <Form.Item name="number_of_guests" className="mb-0">
                <Select
                  placeholder={t("home.search_form.selectGuests")}
                  size="middle"
                  options={guestsOptions}
                  suffixIcon={<i className="bi bi-people-fill"></i>}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4} lg={2}>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                className="w-100"
              >
                {t("home.search_form.search")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Stack>
    </Container>
  );
};

type CommonSpacesProps = {
  isMobile: boolean;
  handleSelectedSpace: (value: CommonSpace) => void;
};

const CommonSpaces: FC<CommonSpacesProps> = ({
  isMobile,
  handleSelectedSpace,
}) => {
  const { t } = useTranslation();
  const [activeSpace, setActiveSpace] = useState<CommonSpace>(
    CommonSpace.AllSpaces
  );

  const spacesItems = useMemo(() => {
    return Object.values(CommonSpace)
      .filter((value) => typeof value === "number")
      .map((value) => ({
        key: value as CommonSpace,
        label: t(
          `home.search_form.commonSpace.${CommonSpace[value as CommonSpace]}`
        ),
      }));
  }, [t]);

  const onCommonSpaceClick = (space: CommonSpace) => {
    setActiveSpace(space);
    handleSelectedSpace(space);
  };

  return (
    <ButtonGroup vertical={isMobile}>
      {spacesItems.map((space, index) => (
        <Button
          type="text"
          key={`space_${index}_${space.key}`}
          className={`search-spaces ${activeSpace === space.key && "active"}`}
          onClick={() => onCommonSpaceClick(space.key)}
        >
          {space.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default SearchForm;
