import { FC } from "react";
import { useTranslation } from "react-i18next";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { Col, Row, Typography } from "antd";
import "./HourlyEventSpaceRental.style.scss";

const { Paragraph, Title } = Typography;

const HourlyEventSpaceRental: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyEventSpaceRental")} titleTextColor="red">
      <Row className="hourly-event-space-rental">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            World class event spaces for rent by the hour
          </Title>
          <Title level={5}>The Beginning</Title>
          <Paragraph>
            For decades people have relied on word of mouth when it comes to
            finiding a perfect event space. In today's world who has time for
            that anymore! On top of that we're looking for venues other than the
            ones our parents used! HourlySpaces.com provides easy access to
            everyone looking to book world class event spaces to rent for an
            hour, day, week or month and a space that fits your needs.
          </Paragraph>
          <Title level={5}>What you get</Title>
          <Paragraph>
            Despite what kind of outlook you chose for the event space, there
            are a few essentials you need to get before you spend any money.
            Wifi is essential (of course!) not only for your personal needs of
            the internet, but for guests and web telecasts. Most of our event
            spaces are now designed in a way that is different from the
            traditional appearance. You will be able to get a space with
            comfortable couches, modern furniture and even your very own
            receptionist and lounge area if you are willing to spend a little
            more.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyEventSpaceRental;
