import { FC } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;

const VirtualOfficeSpace: FC = () => {
  return (
    <>
      <Helmet>
        <title>Virtual Office Space</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Virtual Office Space
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph>
                Hourly Spaces has state of the art virtual offices located all
                throughout Washington DC and NYC. Our technology and facilities
                will guarantee stability. Our large and well-recognized virtual
                offices cater to businesses of all types and sizes. We carry a
                track record of helping start-up businesses grow by presenting
                their portfolio professionally to their business partners and
                networks.
              </Paragraph>
              <Paragraph>
                Likewise, large companies rely on us in expanding their
                operations around the globe. With virtual office services,
                bigger companies no longer require a physical office to operate
                their branches or satellite offices around the world. We help in
                providing the tools needed to get ahead so you can lead in the
                stiff competition. Having virtual offices can give your company
                a prestigious address and professional location for meeting
                space that you need.
              </Paragraph>
              <Paragraph>
                Our virtual office space will address your particular needs. You
                can rely on our stress free and user-friendly services. With us,
                you can focus on the more important concerns of your trade while
                we take good care of your virtual office in cyberspace.
                Excitingly, you can have all of the features of our virtual
                office at a very affordable cost. Yes, you heard it right, with
                today’s high technology; there is no more need for tremendous
                expense that is traditionally connected with running a business.
                With us, you can cut high costs while keeping the integrity,
                authority and professional presence of your business.
              </Paragraph>
              <Paragraph>
                Our sophisticated virtual office will help your business give
                the best impression to the world. Certainly, our virtual offices
                have opened doors of possibilities and opportunities to
                everyone. Our top-notch service made us stand out from our own
                competition. The feedback and reviews we get from our clients’
                keep us motivated and strong to continue providing excellent and
                quality service, so come join us out in Washington DC or New
                York to fill all of your short-term rental needs.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default VirtualOfficeSpace;
