import { FC } from "react";
import { useTranslation } from "react-i18next";
import CardLayout from "../../common/components/card-layout/CardLayout";
import { Col, Row, Typography } from "antd";
import "./HourlyMeetingRoom.style.scss";

const { Paragraph, Title } = Typography;

const HourlyMeetingRoom: FC = () => {
  const { t } = useTranslation();

  return (
    <CardLayout title={t("hourlyMeetingSpace")} titleTextColor="red">
      <Row className="hourly-meeting-room">
        <Col xs={24}>
          <Title level={4} className="mb-4 title">
            World class meeting spaces for rent by the hour
          </Title>
          <Title level={5}>The Need</Title>
          <Paragraph>
            Bring a touch of sophistication to your small business with a rented
            meeting space. Let it be a meeting with your employees, board of
            directors, managers or with your important clients, meetings are
            extremely important despite the size of your company. Corporate
            giants usually have more than one meeting space in their office
            premises dedicated purely for this purpose. However, if you are a
            small business running in a not so sophisticated office, or a big
            company with small regional branches without a proper place for
            meetings, you can rent a temporary meeting space for your important
            meetings.
          </Paragraph>
          <Title level={5}>Makes Sense</Title>
          <Paragraph>
            First of all, the commercial provider of a meeting space has already
            designed their space tastefully and professionally, therefore you do
            not have to go through the trouble of spending a large amount of
            money for designing a space of your own. Especially if you are a
            small business or don't need to have frequent meetings, it doesn't
            always make sense to have your own meeting rooms. Renting other's
            spaces gives you the flexibility to just host a meeting and leave.
          </Paragraph>
          <Paragraph>
            Meetings allow you to exchange ideas with each other in a clearer
            and more detailed way since you are physically present with the
            person who you are communicating with. If you are used to mostly
            communicating in virtual ways such as emails and other tech related
            ways, it has been proven that proper meetings where you actually
            meet the person are more effective. This is mainly due to the
            limitations in ways of tech assisted communication that does not
            allow you to recognize body language. Clear differences in tone when
            you present a certain idea gives you more insight into the person
            you are having a meeting with, rather than just hearing the opinions
            in the form of an email or an audio call.
          </Paragraph>
          <Title level={5}>Options</Title>
          <Paragraph>
            Currently meeting spaces are not the traditional conference-like
            rooms that they used to be. The providers of temporary meeting
            spaces offer the options of casual meeting spaces that give the feel
            of a coffee lounge while actually including coffee in their serve,
            allowing you to have a relaxed and casual meeting with your
            colleagues or clients. Secondly, they offer the option of a fully
            equipped professional meeting space, which is equipped with video
            conferencing devices if you want to include members at a separate
            location in the meeting.
          </Paragraph>
          <Paragraph>
            Finally, with the use of rented meeting spaces, you can save money
            irrespective of whether you are an entrepreneur, small business or a
            bigger company, you will also get a sophisticated space to make a
            good impression of your company without going through the trouble of
            owning a meeting space. You can enjoy a relaxing space that fits
            your specific needs while getting access to the tech assisted tools.
          </Paragraph>
        </Col>
      </Row>
    </CardLayout>
  );
};
export default HourlyMeetingRoom;
