import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import { useAuth } from "../../../../common/components/authContext/AuthContext";
import "./MyWishList.style.scss";

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const MyWishList: FC = () => {
  const [createListing, setCreateListing] = useState(false);
  const { user } = useAuth();

  const wishList = [
    {
      title: "test 1",
      listing: 0,
      image:
        "https://sensyst.com/wp-content/uploads/2019/08/IMG_2342-600x403.jpg",
    },
    {
      title: "test 2",
      listing: 3,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdMQhMbzs3EUJrVrjyQDqxmRC86kvmdvK_7A&s",
    },
  ];

  return (
    <>
      <Helmet>
        <title>My Wishlist</title>
      </Helmet>
      <Row gutter={[12, 24]}>
        <Col xs={24}>
          <Row justify="space-between" gutter={[24, 24]}>
            <Col>
              <Space size={10}>
                <Avatar
                  src="https://www.hourlyspaces.com/images/no_avatar_thumb.jpg"
                  size={50}
                />
                <div>
                  <Title
                    level={4}
                    className="my-0"
                  >{`${user.sub}'s Wish Lists`}</Title>
                  <Paragraph className="mb-0">
                    WishLists: <Text strong>1</Text>
                  </Paragraph>
                </div>
              </Space>
            </Col>
            <Col>
              <Button
                type="primary"
                size="large"
                className="test-uppercase"
                onClick={() => setCreateListing(true)}
              >
                Create New Wish List
              </Button>
              {createListing && (
                <ListingModal open={createListing} close={setCreateListing} />
              )}
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row gutter={[12, 12]}>
            {wishList.map((item) => (
              <Col xs={24} md={8} lg={6}>
                <CardLayout
                  containerClassName="p-0"
                  cardProps={{
                    classNames: { body: "px-0 py-0", actions: "px-0" },
                    cover: (
                      <Image
                        src={item.image}
                        alt={item.image}
                        preview={false}
                      />
                    ),
                    actions: [
                      <Row>
                        <Col xs={16}>
                          <Title level={5} className="text-start px-3">
                            {item.title}
                          </Title>
                        </Col>
                        <Col xs={8} className="text-center">
                          <Button type="link" className="link">
                            {item.listing} Listing
                          </Button>
                        </Col>
                      </Row>,
                    ],
                  }}
                  children={undefined}
                ></CardLayout>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};
type ListingModalProps = {
  open: boolean;
  close: (value: boolean) => void;
};

const ListingModal: FC<ListingModalProps> = ({ open, close }) => {
  const sendWishList = (value: any) => {
    console.log(value);
  };

  const content = (
    <Row gutter={[8, 8]}>
      <Col xs={24}>
        <Title level={5} className="mb-0">
          Everyone
        </Title>
        <Text>
          Visible to everyone and included on your public Hourly Spaces on
          Demand profile.
        </Text>
      </Col>
      <Divider className="my-1" />
      <Col xs={24}>
        <Title level={5} className="mb-0">
          Only Me
        </Title>
        <Text>Visible only to you and not shared anywhere. </Text>
      </Col>
    </Row>
  );
  return (
    <Modal
      open={open}
      onCancel={() => close(false)}
      closable={false}
      footer={false}
    >
      <CardLayout title="Create New Wish List" containerClassName="p-0">
        <Form layout="inline" onFinish={sendWishList}>
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24}>
              <Text>Wish List Name</Text>
            </Col>
            <Col xs={22}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please add your wish list",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Text>Who can see this?</Text>
            </Col>
            <Col xs={22}>
              <Form.Item name="private" initialValue={"0"}>
                <Select size="large">
                  <Option value="0">Everyone</Option>
                  <Option value="1">Only Me</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={2}>
              <Popover content={content}>
                <QuestionCircleFilled />
              </Popover>
            </Col>
            <Divider className="my-1" />
            <Col xs={24} className="text-end">
              <Space>
                <Button type="primary" htmlType="submit" size="large">
                  Save
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => close(false)}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </CardLayout>
    </Modal>
  );
};
export default MyWishList;
