import { HowItWork1, HowItWork2, HowItWork3 } from "../../assets/images";

export const howItWorksData = [
  {
    imageSrc: HowItWork1,
    title: "home.howItWorks.perfect_working_space.title",
    description: "home.howItWorks.perfect_working_space.description",
  },
  {
    imageSrc: HowItWork2,
    title: "home.howItWorks.earn_away.title",
    description: "home.howItWorks.earn_away.description",
  },
  {
    imageSrc: HowItWork3,
    title: "home.howItWorks.save_money.title",
    description: "home.howItWorks.save_money.description",
  },
];
