import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Image, Row, Typography } from "antd";
import { AddYourPlace, FindPlace, WhyHost } from "../../assets/images";
import { useAuth } from "../../common/components/authContext/AuthContext";
import { PageRoutesConstant } from "../../common/router/components/PageRoutes";
import { SearchRoutesConstant } from "../../common/router/components/SearchRoutes";
import "./HowItWork.style.scss";

const { Title } = Typography;

const HowItWork: FC = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Set autoplay, loop, and controls attributes
      videoElement.autoplay = true;
      videoElement.loop = true;
      videoElement.controls = true;

      // Ensure video plays muted
      videoElement.muted = false;

      // Handle potential errors during playback
      videoElement.addEventListener("error", (error) => {
        console.error("Error playing video:", error);
      });

      return () => {
        // Clean up event listener
        videoElement.removeEventListener("error", (error) =>
          console.error(error)
        );
      };
    }
  }, []);

  const HowItWorkItemData = [
    {
      title: "howItWorks.findPlace",
      image: FindPlace,
      button: "howItWorks.search",
      path: `${SearchRoutesConstant.Search.Space.path}?location=`,
    },
    {
      title: "howItWorks.addYourPlace",
      image: AddYourPlace,
      button: "howItWorks.listYourSpace",
      path: isAuthenticated
        ? PageRoutesConstant.Page.Home.path
        : PageRoutesConstant.Page.SignIn.path,
    },
    {
      title: "howItWorks.whyHost",
      image: WhyHost,
      button: "howItWorks.learnMore",
      path: PageRoutesConstant.Page.Faq.path,
    },
  ];

  return (
    <div className="how-it-work">
      <Container>
        <Row gutter={[24, 24]} justify="center">
          <Col xs={24} lg={20}>
            <div className="video-content">
              <video ref={videoRef}>
                <source
                  src="https://www.hourlyspaces.com/uploads/howit/Howitworks.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
          <Col xs={24} xl={22}>
            <Row
              gutter={[24, 24]}
              justify="center"
              className="pb-4"
              align="middle"
            >
              {HowItWorkItemData.map((item, i) => (
                <Col xs={24} md={8} lg={6} key={`how_it_work${i}`}>
                  <Card>
                    <Row gutter={[12, 12]} justify="center" align="middle">
                      <Col xs={24}>
                        <Title level={4} className="mb-0">
                          {t(item.title)}
                        </Title>
                      </Col>
                      <Col xs={24}>
                        <Image
                          src={item.image}
                          alt={item.image}
                          preview={false}
                        />
                      </Col>
                      <Col xs={24} className="px-4 mb-2">
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => {
                            navigate(item.path);
                          }}
                          className="w-100"
                        >
                          {t(item.button)}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default HowItWork;
