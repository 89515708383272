import { FC } from "react";
import { Helmet } from "react-helmet";
import { List } from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";

const Policies: FC = () => {
  const data = [
    "If a guest is transferred to a different location without his or her approval, an appropriate refund will be issued for the reservation.",
    "In the event that a property lacks certain utilities for any portion of a stay,  Hourly Spaces on Demand has the authority to withhold payment to the host and will dictate the amount to be refunded for the reservation. The refund will directly correspond to the severity of the malfunction, its duration, and how it was addressed.",
    "The requisite utilities are electricity, heating, and plumbing (including hot and cold water). Sinks, toilets, & showers must all be fully functioning, as should the oven & stove.",
    "Hourly Spaces on Demand will also issue a partial refund if any appliances that are guaranteed in the listing description (examples: air conditioning, wireless internet, cable television, washer/dryer) are malfunctioning; if any windows, doors, or locks are broken; or if any bedding and towels are not freshly cleaned.",
    "Any construction in a building, especially of a significant nature, needs to be communicated to a guest prior to the guest's arrival.",
    "Please keep in mind that as a host on  Hourly Spaces on Demand,  your listing must be a transparent representation of the property provided. Pictures on a listing must be kept up-to-date and should reflect any changes to the furnishings. The Description and Amenities sections should accurately reflect exactly what is provided at the accommodations.",
    "Finally, if there are any vermin in the apartment, a refund is warranted!",
    "Thank you for helping us maintain a proper level of standard.",
  ];

  return (
    <CardLayout
      title="Hourly Spaces on Demand Refund Policies"
      titleTextColor="red"
      containerClassName="p-0"
      cardProps={{ classNames: { body: "px-0" } }}
    >
      <Helmet>
        <title>Policies</title>
      </Helmet>
      <List
        size="small"
        dataSource={data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </CardLayout>
  );
};
export default Policies;
