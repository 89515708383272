import { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row, Typography } from "antd";
import { ProfileRoutesConstant } from "../../../common/router/components/UserRoutes";
import { SearchRoutesConstant } from "../../../common/router/components/SearchRoutes";

const { Title, Paragraph } = Typography;

const BookMeetingSpaceInstantly: FC = () => {
  return (
    <>
      <Helmet>
        <title>Book Meeting Space Instantly</title>
      </Helmet>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Title level={4} className="my-0">
                Book Meeting Space Instantly
              </Title>
            </Col>
            <Col xs={24}>
              <Paragraph className="text-decoration-underline">
                Short Term Rental
              </Paragraph>
              <Paragraph>
                A short term rental is a cost efficient and suitable solution
                and can be booked on short notice. Whether you’re a business
                organization looking to house your staff, or a person looking
                for temporary rental space, the Hourly Spaces short term rental
                team can facilitate.
              </Paragraph>
              <Paragraph>
                We have a wide variety of completely furnished and high class
                short term rental properties located across the US. Our short
                term rentals can range from hourly apartments to hourly house
                rentals—whatever you require! Rental agreements can range from
                one to six months—and we can help you locate the ideal short
                stay property.Hourly house rentals are beneficial for the
                traveler looking to stay in a fully-furnished house. On the
                other hand, an hourly apartment offers a smaller space to work
                from or reside in. Our facility registers locations across the
                country to help make your selection stress-free. Our short term
                rentals are listed from major companies that strive to provide
                you with their top spaces while letting you stay within your
                budget. You can even rent penthouses with HourlySpaces. We list
                the best penthouses for rent on our website that offer
                spectacular views in the destination of your choice!
              </Paragraph>
              <Paragraph>
                Our website is easy to navigate and has hundreds of keywords
                registered to search for the right short term rental that you
                need. We list properties with photos, descriptions, and a full
                list of amenities that are provided. We believe that this makes
                your selection easier because you can choose exactly what kind
                of services you want for your property. The next time you’re
                looking for a short term rental, look no further that Hourly
                Spaces for the best rentals in top locations throughout America.
              </Paragraph>
              <Paragraph>
                NGIN offers great locations in the heart of Boston with the best
                deals on meeting room rentals. Search them on our website using
                the link today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "NGIN_Workplace"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/NGIN_Workplace
                </Link>
              </Paragraph>
              <Paragraph>
                Check out Bengal Studio Inc., for available short term rentals
                by following the link to search all rentals in the NYC area
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "bengalstudioinc"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/bengalstudioinc.
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Conference Room Rental
              </Paragraph>
              <Paragraph>
                Conference room rentals and meeting services for hire! Our
                luxurious spaces are ideal for meetings whether big or small.
                Our meeting centers offer a series of conference rooms for rent
                to hire from the contemporary to the customary to suit your
                desires.The golden rule to help you select the correct
                conference rooms for rent is: make sure the room is the accurate
                size for your conference! It’s easy to find a room that has a
                high capability around 40% higher than your group size. Booking
                a conference room that is far too huge can make delegates very
                uncomfortable!
              </Paragraph>
              <Paragraph>
                At HourlySpaces we will provide you with a description for how
                many people the conference room accurately fits—so you won’t
                have to worry about any unnecessary space that will cost you
                more money. We understand the importance of conferences, that’s
                why we want to help ensure business success by listing spaces
                for all kinds of meetings. Our website is easy to navigate and
                features hundreds of search criteria to help you find the right
                conference room rentals in your area.
              </Paragraph>
              <Paragraph>
                Whether you’re traveling on business or looking to host a
                meeting outside your normal space—HourlySpaces is the best
                search engine for your businesses success. Our facilities range
                from classic to modern and can provide you with the amenities
                you need for your conference i.e. WIFI, projectors, white boards
                etc. We list locations across the US so that you can find the
                perfect space no matter where you’re located.
              </Paragraph>
              <Paragraph>
                Metuchen Offices is one of our best office providers. Metuchen
                Offices offers a wide variety of office rentals near NYC. Visit
                the link and search for the best possible office rental for your
                business
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "metuchenoffices"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/metuchenoffices.
                </Link>
              </Paragraph>
              <Paragraph>
                Thrive Coworking offers the best conference rooms located in the
                heart of Peach city. To search available listings, visit the
                link today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "workatthrive"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/workatthrive
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Meeting Space Rental
              </Paragraph>
              <Paragraph>
                If each meeting is a chance to develop your trade, every Hourly
                Spaces meeting room is a chance to enhance your reputation. Our
                rental properties feature modern room rentals that will boost
                your professional label. Impress your colleagues, investors, and
                clients with our luxurious property rentals available by the
                hour, day, week or month. Let us set you up for success with our
                chic hourly meeting rooms while remaining within your budget.
              </Paragraph>
              <Paragraph>
                Whether a one-on-one meeting, group workshop, or game-changing
                presentation—your meeting room sets the professional tone. From
                comfortable meeting rooms to grand boardrooms, we have the ideal
                venue listed to get your meeting started with the best style
                forward.
              </Paragraph>
              <Paragraph>
                Our meeting rooms for rent offer the perfect design and market
                for video conferencing with added audio-visual tools within our
                venues. We’ve designed our meeting rooms to impress whoever
                walks through your door. If you’re a growing business looking to
                generate interest and spark conversation about your company—our
                meeting rooms are here to offer you the best outlook possible.
              </Paragraph>
              <Paragraph>
                Whether you’re conducting face-to-face interviews or developing
                a business presentation, Hourly Spaces meeting rooms for rent in
                Atlanta offer flexible, cost-effective solutions for every type
                of trade from start-ups to large corporations.
              </Paragraph>
              <Paragraph>
                Work Mt. Airy is one of the hundreds of companies listing their
                premiere meeting rooms on Hourly Spaces. To search for a space
                in Philly, follow the link and explore today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "workmtairy"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/workmtairy
                </Link>
              </Paragraph>
              <Paragraph>
                Inscape Publico is one of our premiere conference rental
                locations in DC. To view all available rooms follow the link
                today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "Inscape_Publico"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/Inscape_Publico
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Virtual Office Rentals
              </Paragraph>
              <Paragraph>
                Hourly Spaces is a primary contributor of premium-rated serviced
                offices across the United States offering proficient and
                professional virtual office rental services for any type of
                business.A virtual office is an office without the physical
                attendance of desks and chairs. When you rent with us you will
                have an important address for your trade with mail forwarding, a
                phone number answered by a receptionist or routinely forwarded
                to a landline or cell phone.
              </Paragraph>
              <Paragraph>
                Virtual office rentals make sense for all types of businesses.
                HourlySpaces allows you to access the best virtual office
                rentals with our easy to use website. When using a virtual
                office rental, it’s easy to connect with colleagues from all
                over the world. We want to ensure your rental is right for you
                which is why we provide excellent customer service when you’re
                in need of assistance navigating our website. Book your next
                virtual office rental with Hourly Spaces to enhance your
                professional appearance and provide your clients with excellent
                virtual office services for your future business success.
              </Paragraph>
              <Paragraph>
                In the NYC area, CollaborationCore offers cost-effective virtual
                office spaces by the hour, day, or month. Visit the link to
                start searching today
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "collaborationcore"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/collaborationcore.
                </Link>
              </Paragraph>
              <Paragraph>
                Visit our available rentals in San Francisco page for more
                information on finding the perfect virtual office space in San
                Francisco here
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=san francisco`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/search?location=san%20francisco.
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Office Space Rentals
              </Paragraph>
              <Paragraph>
                Hourly office rentals are essential for any serious business in
                America. Whether you require a location for an office to rent on
                the limits of small towns or in prime city locations—the office
                you select depends on a number of factors including budget,
                customer relations, and level of service. HourlySpaces can help
                you find your ideal office for rent in Chicago no matter what
                you require. There are thousands of offices to lease all
                throughout the United States—the challenge is to find the best
                one suited to your needs. At HourlySpaces we have the necessary
                resources that are essential to finding your ideal office. Our
                search criteria will help guide you into finding the office that
                provides the best size, services, and amenities that you require
                to conduct business. Check out our variety of offices that we
                list after working with office building landlords to find the
                best hourly office rental contracts. Hourly Spaces is a good
                option for businesses because features such as location,
                electricity, council tax, and rates are completely taken care of
                by the serviced office agreement and are included as part of the
                Hourly Spaces deal and monthly office rental cost. This can
                often mean that the reserves an owner may have for their office
                space units can be cut by economies of scale to offer you a
                lower office rental cost for monthly rentals.
              </Paragraph>
              <Paragraph>
                Admiral’s Hill Offices is one of the many companies listed on
                HourlySpaces and offers fairly priced office spaces in the
                Boston area. Search them today using the link provided!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "Admirals_Hill_Offices"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/Admirals_Hill_Offices
                </Link>
              </Paragraph>
              <Paragraph>
                The Bureau Chicago offers premiere office space rentals in the
                heart of the city. Follow the link to search all rentals offered
                by the Bureau Chicago.
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "The_Bureau_Chicago"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/The_Bureau_Chicago.
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Office Space Rentals
              </Paragraph>
              <Paragraph>
                At Hourly Spaces, your event space rental possibilities are
                endless! Whether you’re looking for the perfect birthday,
                welcome back, or office party venue, we have them all! Our
                website lists exactly what you search for no matter what kind of
                hourly event venue. Finding the perfect event rental for any
                kind of celebration shouldn’t be difficult. We understand the
                large number of event spaces that are available across America.
                That’s why we list the best ones with all of their features to
                ensure that you’re getting the best deal on your event rental.
                No matter what you’re celebrating, make your venue rental search
                easy with HourlySpaces.
              </Paragraph>
              <Paragraph>
                The Event Space DC offers prime venues for your next event in
                DC! Follow the link to search all available DC rentals today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "TheEventSpaceDC"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/TheEventSpaceDC
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Wedding Venue Rentals
              </Paragraph>
              <Paragraph>
                Hourly Spaces offers the perfect wedding venue rentals across
                the US. Our locations spread across various cities to give you
                the perfect wedding venue. We have numerous wedding venues for
                rent available to you on our website that feature the best
                locations. Our website partners with venue owners to ensure that
                you get the best deal for your next wedding rental. There are
                thousands of wedding possibilities and we know that every detail
                must be planned carefully. That’s why we offer the best venues
                directly to you on our website so that you can finish your
                wedding to-do list with ease! Weddings are said to be the best
                day of your life. Make it memorable with the best wedding venues
                with Hourly Spaces.
              </Paragraph>
              <Paragraph>
                The Hudson Terrace offers numerous wedding venue rentals in the
                heart of NYC. Follow the link to search
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "Hudsonterrace"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/Hudsonterrace
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Rent Penthouses
              </Paragraph>
              <Paragraph>
                Hourly Spaces wants you to experience your city in the best way
                when you decide to rent penthouses. Our penthouse rentals are
                available scattered across various cities offering prime
                locations for the best views. There are numerous tourist
                destinations throughout cities across America, so why not travel
                in luxury with the penthouse suite? There’s nothing quite like
                the penthouse, that’s why we search the best ones readily
                available for you to rent! Rent penthouses by the hour, day,
                week or month and experience the wonders of the city like never
                before. The skylines of the American Frontier await!
              </Paragraph>
              <Paragraph>
                Rent your next penthouse with The Lofts located in NYC! The
                Manhattan skyline awaits! Follow the link!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "theloftsatprince"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/theloftsatprince
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Party Venue Rental
              </Paragraph>
              <Paragraph>
                Party rentals are offered by the hour, day or however longer
                you’d like to stay! Use our website in order to search for the
                best deals on some of the hottest party venue rentals in the
                city. Nightlife is always booming with opportunity, and the best
                way to be a part of the scene is to host your next party in one
                of the best locations. We offer party venues within a wide range
                of characteristics so that you always get the best space for the
                best price! No matter where in the US you’re looking to rent for
                your next party—make sure you get the best venue with Hourly
                Spaces.
              </Paragraph>
              <Paragraph>
                Looking to rent a party venue in DC? Start with The Blue Bugule
                and search this listing today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "BlueBugleDC"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/BlueBugleDC
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Hourly Apartments
              </Paragraph>
              <Paragraph>
                Renting hourly apartments is easy with Hourly Spaces. Our
                website features top spaces catered to your budget in the area
                you choose. But renting with a lease can cost you thousands.
                Don’t rent an apartment by the month, simplify your spending and
                choose to rent one of our apartments. All apartments by the hour
                come fully furnished with a list of amenities provided by the
                landlord. There are numerous apartments available for rent in
                cities across America and we make sure to list the best possible
                properties in the area. With an easy to navigate website and
                instant search criteria, why not rent by the hour?Hourly Spaces
                encourages you to relax and step into your next hourly apartment
                rental the fast and simple way.
              </Paragraph>
              <Paragraph>
                Billy’s Crib is your premiere hourly apartment rental in San
                Francisco. Search this listing today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "BillysCrib"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/BillysCrib
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Hourly Hotels
              </Paragraph>
              <Paragraph>
                We offer a large number of hourly hotels available directly for
                your needs. Why pay for the whole day if you only need a few
                hours to unwind? That’s why choosing to rent an hourly hotel is
                best for you and your wallet! Hourly hotel rooms list directly
                on our website to give you a wide variety of every kind of hotel
                that you need. All hotels by the hour are designated to give you
                prime tourist locations. We work with the best hotels in the
                city of your choice to provide you with prime tourist and
                business locations.
              </Paragraph>
              <Paragraph>
                Search all hourly hotels in prime destinations like Chicago
                using this link today!
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Chicago`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/search?location=Chicago.
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Rent Theatres
              </Paragraph>
              <Paragraph>
                What if your next presentation was made possible on the big
                screen? Hourly Spaces invites you to search for your next
                theater rental. When you rent theaters on our website you’re
                able to easily find the best theater rental no matter where you
                are. We understand that there are plenty of theaters for rent.
                That’s why we list the best ones with all of the amenities and
                services that the venue offers. Project your next presentation
                on the big screen and capture the attention of your audience
                instantly. No matter what the occasion, do it BIG! Rent theaters
                with Hourly Spaces and project your ideas like never before!
              </Paragraph>
              <Paragraph>
                To search all available rentals in Philadelphia follow the link
                and explore the possibilities!
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=philadelphia`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/search?location=philadelphia
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Hourly House Rentals
              </Paragraph>
              <Paragraph>
                Hourly Spaces lists hourly house rentals across the country
                dedicated to helping you feel like you’re in a home away from
                home while staying in a different city. Hourly houses for rent
                come full furnished and ready for your stay. Homeowners list
                their properties on our website in order to allow you to rent by
                the hour, day, week or month! Signing a lease (or a mortgage)
                can cost you heavily. That’s why with Hourly Spaces you can get
                the best of both worlds and experience your very own house
                rental by the hour no matter where you are!
              </Paragraph>
              <Paragraph>
                If you’re ready to search for the best rental location in
                Atlanta, click on the link to view all available rentals today!
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=atlanta`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/search?location=atlanta.
                </Link>
              </Paragraph>
              <Paragraph className="text-decoration-underline">
                Practice Room Rentals
              </Paragraph>
              <Paragraph>
                Practice room rentals are available directly to you on Hourly
                Spaces. We understand that practice rooms for rent can be
                difficult to find while traveling. That’s why our website lists
                all available practice rooms so that you can continue to
                practice by the hour, day, week or month without the stress of
                wondering where your next practice session will be. No matter
                what you practice, rent with HourlySpaces and get the perfect
                space right on budget!
              </Paragraph>
              <Paragraph>
                Book your next practice room with Fairway Studios in the heart
                of Chelsea, NYC! Follow the link today!
                <Link
                  to={`${ProfileRoutesConstant.Users.Profile.path.replace(
                    ":name",
                    "studiofairway"
                  )}`}
                  className="link mx-1"
                >
                  https://www.hourlyspaces.com/users/profile/studiofairway
                </Link>
              </Paragraph>

              <Paragraph>
                Your perfect space is just a click away. To search for available
                rentals near you, follow the link to the destination of your
                choice! Martha’s Vineyard,
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Boston`}
                  className="link mx-1"
                >
                  Boston,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Washington DC`}
                  className="link mx-1"
                >
                  Washington DC,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=New York, NY, United States`}
                  className="link mx-1"
                >
                  New York City (NYC),
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Philadelphia`}
                  className="link mx-1"
                >
                  Philadelphia,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Chicago, IL, United States`}
                  className="link mx-1"
                >
                  Chicago,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Atlanta, GA, United States`}
                  className="link mx-1"
                >
                  Atlanta,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=Miami, FL, United States`}
                  className="link mx-1"
                >
                  Miami,
                </Link>
                <Link
                  to={`${SearchRoutesConstant.Search.Space.path}?location=San Francisco, CA, United States`}
                  className="link mx-1"
                >
                  San Francisco (SF),
                </Link>
                Martha’s Vineyard, Hawaii, Los Angeles.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default BookMeetingSpaceInstantly;
