import { FC } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import CardLayout from "../../../../common/components/card-layout/CardLayout";
import { validateEmails } from "../../../../common/utilities/validator";

const { Text } = Typography;
const { TextArea } = Input;

const References: FC = () => {
  const items: TabsProps["items"] = [
    {
      key: "request",
      label: "Request References",
      children: <RequestReferences />,
    },
    {
      key: "about-you",
      label: "References About You",
      children: <ReferenceAboutYou />,
    },
    {
      key: "you",
      label: "Reference By You",
      children: <ReferenceByYou />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>References details</title>
      </Helmet>
      <Tabs items={items} />
    </>
  );
};

const RequestReferences: FC = () => {
  const [emailForm] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("Submitted Values:", values);
    emailForm.resetFields();
    notification.success({ message: "Email Sent successfully" });
  };
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <CardLayout containerClassName="p-0">
          <Text>
            Hourly Spaces on Demand is built on trust and reputation. You can
            request references from your personal network, and the references
            will appear publicly on your Hourly Spaces on Demand profile to help
            other members get to know you. You should only request references
            from people who know you well.
          </Text>
        </CardLayout>
      </Col>
      <Col xs={24}>
        <CardLayout title="Email your friends" containerClassName="p-0">
          <Row gutter={[12, 12]}>
            <Col xs={24} lg={14}>
              <Text>
                Hourly Spaces on Demand is built on trust and reputation. You
                can request references from your personal network, and the
                references will appear publicly on your Hourly Spaces on Demand
                profile to help other members get to know you. You should only
                request references from people who know you well.
              </Text>
            </Col>
            <Col xs={24} lg={12}>
              <Form form={emailForm} onFinish={onFinish}>
                <Form.Item
                  name="email_to_friend"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email address",
                    },
                    {
                      validator: validateEmails,
                      validateTrigger: ["onBlur", "onClick"],
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    placeholder="Enter email addresses by comma separated (',')"
                    size="large"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="text-uppercase"
                >
                  Send Request Emails
                </Button>
              </Form>
            </Col>
          </Row>
        </CardLayout>
      </Col>
    </Row>
  );
};

const ReferenceAboutYou: FC = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <CardLayout title="Pending Approval" containerClassName="p-0">
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Text>
                When you accept a reference it will appear on your public
                profile. If you ignore a reference it will not appear in your
                profile, and the other person will not be notified.
              </Text>
            </Col>
            <Col xs={24}>
              <Text>No pending references</Text>
            </Col>
          </Row>
        </CardLayout>
      </Col>
      <Col xs={24}>
        <CardLayout
          title="Past References Written About You"
          containerClassName="p-0"
        >
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Text>
                References require profile photos. A reference will only display
                in your public profile if the member who wrote it has a profile
                picture.
              </Text>
            </Col>
            <Col xs={24}>
              <Text>There is no reference.</Text>
            </Col>
          </Row>
        </CardLayout>
      </Col>
    </Row>
  );
};

const ReferenceByYou: FC = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <CardLayout title="Reference Requests" containerClassName="p-0">
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Text>
                Write references only for people you know well enough to
                recommend to the Hourly Spaces on Demand Community. If you
                ignore a request the other person will not be notified.
              </Text>
            </Col>
            <Col xs={24}>
              <Text>No reference requests.</Text>
            </Col>
          </Row>
        </CardLayout>
      </Col>
      <Col xs={24}>
        <CardLayout
          title="Past References You've Written"
          containerClassName="p-0"
        >
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Text>You have not written a reference for anyone yet</Text>
            </Col>
          </Row>
        </CardLayout>
      </Col>
    </Row>
  );
};
export default References;
